import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

import Carousel from '../components/Carousel'

const Section = styled.section(
  ({ theme }) => css`
    margin: 0 auto;
    max-width: ${theme.spacing.containerMaxWidth}px;
    padding: ${theme.spacing.x5}px 0;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x10}px ${theme.spacing.x3}px;
    }
  `,
)

interface Props extends ComponentProps<typeof Section> {
  slides: ReactNode[]
}

const CarouselSection = ({ slides }: Props) => (
  <Section>
    <Carousel slides={slides} />
  </Section>
)

export default CarouselSection
