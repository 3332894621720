import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, MouseEvent, useState } from 'react'

import { usePageContext } from '../utils/PageContext'
import { resolveLinkWithText } from '../graphql/linkWithTextFragment.utils'
import CloseIcon from '../icons/Cross.svg'
import SearchIcon from '../icons/Search.svg'
import Down from '../icons/DropdownDown.svg'
import Link from './Link'
import MultiLevelMenu from './MultiLevelMenu'
import GridMenu from './GridMenu'
import ButtonLink from './ButtonLink'
import { isModalOpenVar } from './QuickHelpModal'
import { useReactiveVar } from '../utils/reactiveVar'
import { isSearchOpenVar } from '../sections/NavigationBar'

const MenuLink = styled(Link)(
  ({ theme }) => css`
    color: inherit;
    display: block;
    padding: ${theme.spacing.x2}px ${theme.spacing.x2}px;
    margin-right: ${theme.spacing.x1}px;
    transition: all 100ms ease-in;

    :hover {
      background: ${theme.colors.neutral1};
      color: ${theme.colors.primaryPurple};
      text-decoration: none;
    }
  `,
)
const Navigation = styled.menu(
  ({ theme }) => css`
    list-style: none;
    margin: 0;
    padding: 0;

    display: none;
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: flex;
    }
  `,
)
const StyledCloseIcon = styled(CloseIcon)(
  ({ theme }) => css`
    height: 24px;
    vertical-align: middle;
    place-self: center;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      min-width: auto;
    }
  `,
)
const StyledSearchIcon = styled(SearchIcon)(
  ({ theme }) => css`
    height: 24px;
    vertical-align: middle;
    place-self: center;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      min-width: auto;
    }
  `,
)
const Section = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.neutral0};
    left: 0;
    padding: 0 ${theme.spacing.x2}px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: ${theme.zIndex.relative2};
  `,
)
const Button = styled.button(
  ({ theme }) => css`
    border: none;
    cursor: pointer;
    padding: ${theme.spacing.x2}px ${theme.spacing.x2}px;
    transition: all 100ms ease-in;
  `,
)
const Container = styled.div(
  ({ theme }) => css`
    max-width: ${theme.spacing.containerMaxWidth}px;
    margin: 0 auto;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      overflow: auto;
    }
  `,
)
const Dropdown = styled.div(
  ({ theme }) => css`
    margin-right: ${theme.spacing.x1}px;
    ::before {
      background: rgba(0, 0, 0, 0.5);
      height: calc(100vh - ${theme.spacing.navigationHeight.desktop}px);
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: ${theme.zIndex.relative1};
    }
    ${Section} {
      display: none;
    }
  `,
)
const MenuItem = styled.li<{ open?: boolean }>(({ open, theme }) => [
  css`
    cursor: pointer;
    padding: ${theme.spacing.x3}px ${theme.spacing.x2}px
      ${theme.spacing.x3 + theme.spacing.x0}px;
    margin: 0 -${theme.spacing.x2}px -${theme.spacing.x0}px;

    :last-of-type {
      padding: ${theme.spacing.x3}px 0;
      margin: 0;

      > ${Dropdown}, > ${MenuLink} {
        margin-right: 0;
      }
    }

    :focus-within {
      ${Dropdown} {
        ::before {
          content: '';
        }
        > ${Button} {
          background: ${theme.colors.neutral1};
          color: ${theme.colors.primaryPurple};
        }
      }
      ${Section} {
        display: block;
      }
    }
  `,
  open &&
    css`
      :hover {
        ${Dropdown} {
          ::before {
            content: '';
          }
          > ${Button} {
            background: ${theme.colors.neutral1};
            color: ${theme.colors.primaryPurple};
          }
        }
        ${Section} {
          display: block;
        }
      }
    `,
])
const Footer = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.neutral1};
    margin: 0 -${theme.spacing.x2}px;
    padding: 0 ${theme.spacing.x2}px;
  `,
)
const FooterLink = styled(ButtonLink)(
  ({ theme }) => css`
    color: ${theme.colors.primaryPurple};
    display: inline-block;
    padding: ${theme.spacing.x3}px 0;
    font-weight: bold;
  `,
)

interface Props {
  handleSearchClick: (e: MouseEvent) => void
  links: Array<ComponentProps<typeof MultiLevelMenu>['menu']>
}

const Menu = ({ handleSearchClick, links }: Props) => {
  const { siteConfig } = usePageContext()
  const isModalOpen = useReactiveVar(isModalOpenVar)
  const isSearchOpen = useReactiveVar(isSearchOpenVar)
  const [open, setOpen] = useState(false)

  return (
    <Navigation
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      {links.map((menu) => {
        const footerLink = menu.link?.length
          ? {
              ...resolveLinkWithText(siteConfig, menu.link[0]),
              text: menu.link[0].text,
            }
          : undefined
        if (menu.subMenu) {
          return (
            <MenuItem
              key={menu.id}
              onMouseEnter={() => {
                if (isModalOpen) isModalOpenVar(false)
                if (isSearchOpen) isSearchOpenVar(false)
              }}
              onFocus={() => {
                if (isModalOpen) isModalOpenVar(false)
                if (isSearchOpen) isSearchOpenVar(false)
              }}
              open={open}
            >
              <Dropdown>
                <Button
                  type="button"
                  aria-haspopup="menu"
                  onClick={() => {
                    if (document.activeElement instanceof HTMLElement) {
                      document.activeElement.blur()
                    }
                  }}
                >
                  {menu.text} <Down />
                </Button>
                <Section>
                  <Container>
                    {menu.image && menu.description ? (
                      <GridMenu close={() => setOpen(false)} menu={menu} />
                    ) : (
                      <MultiLevelMenu
                        close={() => setOpen(false)}
                        menu={menu}
                      />
                    )}
                  </Container>
                  {footerLink && (
                    <Footer>
                      <Container>
                        <FooterLink
                          href={footerLink.href}
                          target={footerLink.target}
                          onClick={() => {
                            if (document.activeElement instanceof HTMLElement) {
                              document.activeElement.blur()
                              setOpen(false)
                            }
                          }}
                          variant="textual"
                        >
                          {footerLink.text}
                        </FooterLink>
                      </Container>
                    </Footer>
                  )}
                </Section>
              </Dropdown>
            </MenuItem>
          )
        }
        return (
          <MenuItem key={menu.id}>
            <MenuLink href={menu.href} target={menu.target}>
              {menu.text}
            </MenuLink>
          </MenuItem>
        )
      })}
      <MenuItem>
        <MenuLink
          href="/search"
          onClick={(evt) => {
            if (isModalOpen) isModalOpenVar(false)
            handleSearchClick(evt)
          }}
        >
          {isSearchOpen ? (
            <StyledCloseIcon aria-label="Search close" />
          ) : (
            <StyledSearchIcon aria-label="Search" />
          )}
        </MenuLink>
      </MenuItem>
    </Navigation>
  )
}

export default Menu
