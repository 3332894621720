import { createContext, useContext } from 'react'

import { SiteConfig } from '../graphql/siteConfig'

export interface Breadcrumb {
  key: string
  href: string
  title: string
}

export interface PageContext {
  breadcrumbs: Breadcrumb[] | undefined
  siteConfig: SiteConfig
  hideSearchMobile?: boolean
}
const PageContext = createContext<PageContext | undefined>(undefined)
export const usePageContext = () => {
  const pageContext = useContext(PageContext)
  if (!pageContext) {
    throw new Error('Missing PageContext')
  }

  return pageContext
}
export const PageContextProvider = PageContext.Provider
