import { BaseArticleFragment } from '../graphql/articleList'
import { ArticleType } from '../graphql/articleTypeList'
import { NewsListingSectionFragment } from '../graphql/newsListingSectionFragment'
import NewsListingSection from '../sections/NewsListingSection'
import convertDatoImage from '../utils/convertDatoImage'
import notEmpty from '../utils/notEmpty'
import { usePageContext } from '../utils/PageContext'

interface Props {
  content: NewsListingSectionFragment
  data: {
    articles: BaseArticleFragment[]
    articleTypes: ArticleType[]
    activeFilter?: string
    numTotalArticles: number
    currentPage: number
  }
}

const NewsListingSectionBlock = ({
  content: { all, previousPage, nextPage, goToPage },
  data: { articles, articleTypes, numTotalArticles, activeFilter, currentPage },
}: Props) => {
  const { siteConfig } = usePageContext()
  if (
    !all ||
    !previousPage ||
    !nextPage ||
    !goToPage ||
    !siteConfig.newsIndex?.slug
  ) {
    return null
  }

  return (
    <NewsListingSection
      articles={articles
        .map(({ slug, title, articleType, image, brief }) => {
          if (
            !slug ||
            !title ||
            !articleType?.title ||
            !articleType.color ||
            !image ||
            !brief
          ) {
            return undefined
          }

          return {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            href: `/${siteConfig.newsIndex!.slug}/${slug}`,
            title,
            type: articleType.title,
            typeColor: articleType.color,
            image: convertDatoImage(image),
            brief,
          }
        })
        .filter(notEmpty)}
      types={articleTypes
        .map(({ title, color }) => {
          if (!title || !color) {
            return undefined
          }

          return { title, color }
        })
        .filter(notEmpty)}
      numTotalArticles={numTotalArticles}
      all={all}
      activeFilter={activeFilter}
      currentPage={currentPage}
      previousPageLabel={previousPage}
      nextPageLabel={nextPage}
      goToPageLabel={goToPage}
      pathname={`/${siteConfig.newsIndex.slug}`}
    />
  )
}

export default NewsListingSectionBlock
