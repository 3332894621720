import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'

import {
  primaryButtonCss,
  primaryButtonInvertedCss,
  secondaryButtonCss,
} from './Button'
import ArrowLeftSvg from '../icons/ArrowLeft.svg'
import ChevronRightSvg from '../icons/ChevronRight.svg'
import Link from './Link'

export const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: 'textual' | 'primary' | 'secondary' | 'inverted' }>(
  ({ theme, variant }) => [
    css`
      display: inline-block;
      font-weight: bold;
      color: inherit;
    `,
    variant === 'primary' && primaryButtonCss(theme),
    variant === 'secondary' && secondaryButtonCss(theme),
    variant === 'inverted' && primaryButtonInvertedCss(theme),
  ],
)
const ChevronRight = styled(ChevronRightSvg)(
  ({ theme }) => css`
    margin-left: ${theme.spacing.x1}px;
  `,
)
const ArrowRight = styled(ArrowLeftSvg)(
  ({ theme }) => css`
    transform: rotate(180deg);
    margin-left: ${theme.spacing.x1}px;
    height: 24px;
    margin-bottom: -3px;
    transition: transform 400ms ease-out;

    ${StyledLink}:hover &, ${StyledLink}:focus & {
      transform: rotate(180deg) translateX(-4px);
    }
  `,
)

interface Props extends ComponentProps<typeof Link> {
  variant: 'textual' | 'primary' | 'secondary' | 'inverted'
}

const ButtonLink = ({ children, variant, ...others }: Props) => (
  <StyledLink variant={variant} {...others}>
    {children}
    {variant === 'textual' && <ArrowRight />}
    {variant !== 'textual' && <ChevronRight />}
  </StyledLink>
)

export default ButtonLink
