import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { LinkWithTextFragment } from '../graphql/linkWithTextFragment'
import { resolveLinkWithText } from '../graphql/linkWithTextFragment.utils'

import { usePageContext } from '../utils/PageContext'
import Link from './Link'
import SubMenu from './SubMenu'
import ArrowLeft from '../icons/ArrowLeft.svg'

const ListItem = styled.li()
const Button = styled.button(
  ({ theme }) => css`
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: ${theme.spacing.x2}px ${theme.spacing.x2}px;
    padding-right: ${theme.spacing.x4}px;
    overflow: hidden;
    position: relative;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    &:hover {
      color: ${theme.colors.primaryPurple};
    }
  `,
)
const MenuLink = styled(Link)(
  ({ theme }) => css`
    display: block;
    color: inherit;
    font-size: 16px;
    padding: ${theme.spacing.x2}px ${theme.spacing.x2}px;
    padding-right: ${theme.spacing.x4}px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      color: ${theme.colors.primaryPurple};
      text-decoration: none;
    }
  `,
)
const Arrow = styled(ArrowLeft)(
  ({ theme }) => css`
    position: absolute;
    right: ${theme.spacing.x2}px;
    top: ${theme.spacing.x2}px;
    transform: rotate(180deg);
    transition: transform 400ms ease-out;

    ${Button}:hover &, ${MenuLink}:hover & {
      transform: rotate(180deg) translateX(-4px);
    }
  `,
)

type MenuItemType = {
  id: string
  link?: ({ text: string } & LinkWithTextFragment)[]
  subMenu?: MenuItemType[]
  text: string
} & LinkWithTextFragment

interface Props {
  active: string[]
  depth: number
  item: MenuItemType
  onClick: (depth: number, id: string, name: string) => void
}

const MenuItem = ({ active, depth, item, onClick }: Props) => {
  const { siteConfig } = usePageContext()
  if (item.subMenu && item.subMenu.length > 0) {
    return (
      <ListItem>
        <Button
          aria-haspopup="menu"
          onClick={() => onClick(depth + 1, item.id, item.text)}
        >
          {item.text} <Arrow />
        </Button>
        {active.includes(item.id) && (
          <SubMenu
            active={active}
            depth={depth + 1}
            items={item.subMenu}
            onClick={onClick}
            rootLink={
              depth === 0
                ? {
                    ...resolveLinkWithText(
                      siteConfig,
                      item.link ? item.link[0] : item,
                    ),
                    text: item.link ? item.link[0].text : '',
                  }
                : undefined
            }
          />
        )}
      </ListItem>
    )
  }
  const link = resolveLinkWithText(
    siteConfig,
    item.link && item.link.length > 0 ? item.link[0] : item,
  )
  return (
    <ListItem>
      <MenuLink href={link.href} target={link.target}>
        {item.text}
      </MenuLink>
    </ListItem>
  )
}

export default MenuItem
