import { resolveLinkWithTextAndImage } from '../graphql/linkWithTextAndImageFragment.utils'
import { resolveLinkWithText } from '../graphql/linkWithTextFragment.utils'
import Footer from '../sections/Footer'
import convertDatoImage from '../utils/convertDatoImage'
import { usePageContext } from '../utils/PageContext'
import { FooterFragment } from '../graphql/footerFragment'
import RichText from '../components/RichText'
import notEmpty from '../utils/notEmpty'

interface Props {
  content: FooterFragment
}

const FooterBlock = ({
  content: { columns, logo, copyright, legalLinks },
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()

  if (!logo || !copyright) {
    return null
  }

  return (
    <Footer
      columns={columns
        .map((column) => {
          if (column.__typename === 'FooterLinksColumnRecord') {
            return {
              key: column.id,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              title: column.title!,
              links: column.links.map((link) => ({
                key: link.id,
                ...resolveLinkWithText(siteConfig, link),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                text: link.text!,
              })),
            }
          }
          if (column.__typename === 'FooterCallToActionColumnRecord') {
            return {
              key: column.id,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              title: column.title!,
              text: column.text && <RichText text={column.text} />,
              link:
                column.link[0].__typename === 'LinkWithTextExternalRecord' ||
                column.link[0].__typename === 'LinkWithTextInternalRecord'
                  ? {
                      variant: 'button' as const,
                      ...resolveLinkWithText(siteConfig, column.link[0]),
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      text: column.link[0].text!,
                    }
                  : {
                      variant: 'quick-help' as const,
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      text: column.link[0].title!,
                    },
            }
          }

          return undefined
        })
        .filter(notEmpty)}
      logo={convertDatoImage(logo)}
      copyright={copyright}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      socialLabel={siteConfig.followUs!}
      socialLinks={siteConfig.socialLinks.map((social) => ({
        key: social.id,
        ...resolveLinkWithTextAndImage(siteConfig, social),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        image: convertDatoImage(social.image!),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        text: social.text!,
      }))}
      legalLinks={legalLinks.map((link) => ({
        key: link.id,
        ...resolveLinkWithText(siteConfig, link),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        text: link.text!,
      }))}
      {...others}
    />
  )
}

export default FooterBlock
