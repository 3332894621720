import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'

import Link from '../components/Link'
import stringifyQueryString from '../utils/stringifyQueryString'
import Pagination from '../components/Pagination'
import NewsCard from '../components/NewsCard'

export const articlesPerPage = 12

const Section = styled.section(
  ({ theme }) => css`
    background: ${theme.colors.neutral1};
  `,
)
const Container = styled.div(
  ({ theme }) => css`
    margin: 0 auto;
    max-width: ${theme.spacing.gridMaxWidth}px;
    padding: ${theme.spacing.x5}px 0;
  `,
)
const Filters = styled.ul(
  ({ theme }) => css`
    list-style: none;
    margin: 0 ${theme.spacing.x2}px ${theme.spacing.x2}px;
    padding: 0;
    display: flex;
    gap: ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      justify-content: center;
      margin: 0 0 ${theme.spacing.x5}px;
    }
  `,
)
const FilterLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => [
  css`
    font-weight: bold;
    color: inherit;
    :hover {
      color: ${theme.colors.primaryPurple};
      text-decoration: none;
    }
  `,
  active &&
    css`
      color: ${theme.colors.primaryPurple};
      padding-bottom: 6px;
      border-bottom: 3px solid ${theme.colors.primaryPurple};
    `,
])
const ArticlesContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    padding: ${theme.spacing.x2}px;
    gap: ${theme.spacing.x2}px;
    grid-template-columns: 1fr;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      gap: ${theme.spacing.x3}px;
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      grid-template-columns: repeat(3, 1fr);
    }
  `,
)
const StyledPagination = styled(Pagination)(
  ({ theme }) => css`
    margin-top: ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-top: ${theme.spacing.x10}px;
    }
  `,
)

interface Props {
  articles: Array<ComponentProps<typeof NewsCard>>
  types: Array<{
    title: string
    color: string
  }>
  all: string
  activeFilter: string | undefined
  numTotalArticles: number
  currentPage: number
  previousPageLabel: string
  nextPageLabel: string
  goToPageLabel: string
  pathname: string
}

const NewsListingSection = ({
  articles,
  all,
  types,
  activeFilter,
  numTotalArticles,
  currentPage,
  previousPageLabel,
  nextPageLabel,
  goToPageLabel,
  pathname,
}: Props) => (
  <Section>
    <Container>
      <div>
        <Filters>
          {[all, ...types.map(({ title }) => title)].map((title) => (
            <li key={title}>
              <FilterLink
                href={
                  title === all
                    ? pathname
                    : `${pathname}?${stringifyQueryString({
                        filter: encodeURIComponent(title),
                      })}`
                }
                active={
                  activeFilter === title || (!activeFilter && title === all)
                }
              >
                {title}
              </FilterLink>
            </li>
          ))}
        </Filters>
      </div>
      <ArticlesContainer>
        {articles.map((article) => (
          <NewsCard key={article.href} {...article} />
        ))}
      </ArticlesContainer>
      <StyledPagination
        numPages={Math.ceil(numTotalArticles / articlesPerPage)}
        currentPage={currentPage}
        makeUrl={(page) =>
          `${pathname}?${stringifyQueryString(
            activeFilter
              ? {
                  filter: encodeURIComponent(activeFilter),
                  page,
                }
              : {
                  page,
                },
          )}`
        }
        previousPageLabel={previousPageLabel}
        nextPageLabel={nextPageLabel}
        goToPageLabel={goToPageLabel}
      />
    </Container>
  </Section>
)

export default NewsListingSection
