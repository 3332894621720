import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode, useRef, useState } from 'react'

import {
  invisibleButtonCss,
  mediumButtonCss,
  primaryButtonCss,
  primaryButtonInvertedCss,
} from '../components/Button'
import Image from '../components/Image'
import Link from '../components/Link'
import ChevronRight from '../icons/ChevronRight.svg'
import { CarouselContainer } from '../components/Carousel'
import Breadcrumbs from '../components/Breadcrumbs'
import PlayIcon from '../icons/Play.svg'
import { richTextClassName } from '../components/RichText'
import getPaddingTopFromAspectRatio from '../utils/getPaddingTopFromAspectRatio'

const Title = styled('h1', {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'as',
})<{ variant?: 'default' | 'pageTitle' }>(({ theme, variant }) => [
  css`
    ${theme.text.heading2(theme)}
    margin: 0;
    margin-bottom: ${theme.spacing.x2}px;
    position: relative;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x3}px;
    }
  `,
  variant === 'pageTitle' &&
    css`
      ${theme.text.heading1(theme)}
      ${theme.text.titleLine(theme)}
      padding-left: ${theme.spacing.x2}px;
      margin-bottom: ${theme.spacing.x3}px;
    `,
])
const Text = styled.div`
  .${richTextClassName} > p:last-child {
    margin-bottom: 0;
  }
`
const Section = styled('section', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{
  variant: 'header' | 'light' | 'dark'
}>(({ theme, variant }) => [
  css`
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;

    ${CarouselContainer} & {
      background: transparent;
      padding: ${theme.spacing.x2}px;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x10}px ${theme.spacing.x2}px;
    }
  `,
  variant === 'light' &&
    css`
      background: ${theme.colors.neutral1};
    `,
  variant === 'dark' &&
    css`
      background: ${theme.colors.primaryPurpleDark};
      color: ${theme.colors.neutral0};
      ${Title} {
        color: ${theme.colors.neutral0};
      }
    `,
])
const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'mediaLocation',
})<{
  mediaLocation: 'left' | 'right'
}>(({ theme, mediaLocation }) => [
  css`
    display: flex;
    gap: ${theme.spacing.x3}px;
    margin: 0 auto;
    flex-flow: column;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-flow: row;
      max-width: ${theme.spacing.gridMaxWidth}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      gap: ${theme.spacing.x2}px ${theme.spacing.x15}px;
    }

    ${CarouselContainer} & {
      margin: 0;
      max-width: none;
    }
  `,
  mediaLocation === 'left' &&
    css`
      flex-flow: column-reverse;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        flex-flow: row-reverse;
      }
    `,
])
const Content = styled.div(
  ({ theme }) => css`
    flex: 1 1 50%;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      place-self: center;
      max-width: 50%;
    }
  `,
)
// The link has display:inline-block which doesn't allow for margin collapsing
const CallToActionContainer = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-top: ${theme.spacing.x5}px;
    }
  `,
)
const CallToAction = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{
  variant: 'header' | 'light' | 'dark'
}>(({ theme, variant }) => [
  variant === 'dark'
    ? primaryButtonInvertedCss(theme)
    : primaryButtonCss(theme),
  css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      ${mediumButtonCss(theme)}

      svg {
        height: 24px;
        margin-bottom: -2px;
      }
    }
  `,
])
const MediaContainer = styled.div(
  ({ theme }) => css`
    flex: 1 1 50%;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-top: 0;
      max-width: 456px;
    }
  `,
)
const MediaAspectRatio = styled('div', {
  shouldForwardProp: (prop) => prop !== 'imageSize',
})<{ imageSize: 'landscape' | 'portrait' | 'video' }>(({ imageSize }) => [
  css`
    width: 100%;
    // Normalized since the design was only a few pixels off
    padding-top: ${getPaddingTopFromAspectRatio(16, 10) * 100}%;
    position: relative;
  `,
  imageSize === 'portrait' &&
    css`
      // Normalized since the design was only a few pixels off
      padding-top: ${getPaddingTopFromAspectRatio(7, 8) * 100}%;
    `,
  imageSize === 'video' &&
    css`
      // Videos are generally always this aspect ratio, so we have to deviate a
      // little from the standard
      padding-top: ${getPaddingTopFromAspectRatio(16, 9) * 100}%;
    `,
])
const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`
const InitialPlayButton = styled.button`
  ${invisibleButtonCss()}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`
// Not actually a button, just has the appearance of one. The entire container
// is a button instead.
const PlayButton = styled.div(
  ({ theme }) => css`
    ${primaryButtonCss(theme)}
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
)
const StyledPlayIcon = styled(PlayIcon)(
  ({ theme }) => css`
    width: ${theme.spacing.x3}px;
    height: ${theme.spacing.x3}px;
  `,
)
const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export interface VideoInfo {
  src: string
  thumbnailSrc: string
  width: number
  height: number
}

interface Props
  extends Omit<ComponentProps<typeof Section>, 'title' | 'variant'> {
  title: ReactNode
  titleVariant?: 'default' | 'pageTitle'
  text?: ReactNode
  image?: ImageInfo
  video?: VideoInfo
  imageSize?: 'landscape' | 'portrait'
  mediaLocation?: 'right' | 'left'
  callToAction?: {
    href: string
    target?: string
    text: string
  }
  variant?: 'header' | 'light' | 'dark'
}

const TextWithImageSection = ({
  title,
  titleVariant,
  text,
  image,
  video,
  imageSize = 'landscape',
  mediaLocation = 'right',
  callToAction,
  variant = 'light',
  ...others
}: Props) => {
  const priority = variant === 'header'
  const [isVideoActive, setIsVideoActive] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  return (
    <Section variant={variant} {...others}>
      <Container mediaLocation={mediaLocation}>
        <Content>
          {titleVariant === 'pageTitle' && <Breadcrumbs />}
          <Title
            variant={titleVariant}
            as={titleVariant === 'pageTitle' ? 'h1' : 'h2'}
          >
            {title}
          </Title>
          <Text>{text}</Text>

          {callToAction && (
            <CallToActionContainer>
              <CallToAction
                href={callToAction.href}
                target={callToAction.target}
                variant={variant}
              >
                {callToAction.text} <ChevronRight />
              </CallToAction>
            </CallToActionContainer>
          )}
        </Content>
        {image && (
          <MediaContainer>
            <MediaAspectRatio imageSize={imageSize}>
              <StyledImage
                src={image}
                alt=""
                sizes="(min-width: 768px) 456px, 100vw"
                fill
                aspectRatio={imageSize === 'portrait' ? '0.87:1' : '1.61:1'}
                priority={priority}
              />
            </MediaAspectRatio>
          </MediaContainer>
        )}
        {video && (
          <MediaContainer>
            <MediaAspectRatio imageSize="video">
              {!isVideoActive && (
                <InitialPlayButton
                  onClick={() => {
                    setIsVideoActive(true)
                    videoRef.current?.play()
                  }}
                  aria-label="Play video"
                >
                  <StyledImage
                    src={video.thumbnailSrc}
                    alt=""
                    sizes="(min-width: 768px) 456px, 100vw"
                    fill
                    aspectRatio="16:9"
                    priority={priority}
                  />
                  <PlayButton>
                    <StyledPlayIcon />
                  </PlayButton>
                </InitialPlayButton>
              )}
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <Video
                preload={priority ? 'auto' : 'metadata'}
                controls
                ref={videoRef}
              >
                <source src={video.src} type="video/mp4" />
              </Video>
            </MediaAspectRatio>
          </MediaContainer>
        )}
      </Container>
    </Section>
  )
}

export default TextWithImageSection
