import { resolveLinkWithTextAndImage } from '../graphql/linkWithTextAndImageFragment.utils'
import QuickImageLinksSection from '../sections/QuickImageLinksSection'
import convertDatoImage from '../utils/convertDatoImage'
import notEmpty from '../utils/notEmpty'
import { QuickImageLinksSectionFragment } from '../graphql/quickImageLinksSectionFragment'
import { usePageContext } from '../utils/PageContext'

interface Props {
  content: QuickImageLinksSectionFragment
}

const QuickImageLinksSectionBlock = ({
  content: { title, links, anchor },
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()

  return (
    <QuickImageLinksSection
      title={title}
      links={links
        .map((link) => {
          const image = convertDatoImage(link.image)
          if (!link.text || !image) {
            return undefined
          }

          return {
            key: link.id,
            ...resolveLinkWithTextAndImage(siteConfig, link),
            text: link.text,
            image,
          }
        })
        .filter(notEmpty)}
      id={anchor || undefined}
      {...others}
    />
  )
}

export default QuickImageLinksSectionBlock
