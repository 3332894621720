import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

import ButtonLink from '../components/ButtonLink'
import Link from '../components/Link'

const Section = styled.section(
  ({ theme }) => css`
    position: relative;
    z-index: ${theme.zIndex.base};
    background: ${theme.colors.neutral1};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      background: none;
      padding: 0 ${theme.spacing.x2}px;
      ::after {
        content: '';
        position: absolute;
        z-index: ${theme.zIndex.behindSiblings1};
        left: 0;
        right: 0;
        bottom: 0;
        top: 51%;
        background: ${theme.colors.neutral1};
      }
    }
  `,
)
const Container = styled.div(
  ({ theme }) => css`
    max-width: 1128px;
    margin: 0 auto;
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x10}px 0;
    }
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    text-align: center;
    margin-bottom: ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x5}px;
    }
  `,
)
const List = styled.ul(
  ({ theme }) => css`
    padding: 0;
    margin: 0;
    list-style: none;
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: row;
    gap: 1px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      grid-auto-flow: column;
    }
  `,
)
const Item = styled.li(
  ({ theme }) => css`
    ${theme.text.headings(theme)}
    background: ${theme.colors.primaryPurple};
    color: ${theme.colors.neutral0};

    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: normal;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 24px;
    }
  `,
)
const ViewAllLinkContainer = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-top: ${theme.spacing.x5}px;
      text-align: right;
    }
  `,
)
const StyledLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.colors.neutral0};
    display: block;
    padding: ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x3}px;
    }
    :hover {
      text-decoration: none;
    }
  `,
)

interface Props extends Omit<ComponentProps<typeof Section>, 'title'> {
  title: ReactNode
  expertises: Array<{
    key: string
    name: string
    link: {
      href: string
      target?: string
    }
  }>
  viewAll?: {
    href: string
    target?: string
    text: string
  }
}

const ExpertisesOverviewSection = ({
  title,
  expertises,
  viewAll,
  ...others
}: Props) => (
  <Section {...others}>
    <Container>
      <Title>{title}</Title>
      <List>
        {expertises.map((expertise) => (
          <Item key={expertise.key}>
            <StyledLink
              href={expertise.link.href}
              target={expertise.link.target}
            >
              {expertise.name}
            </StyledLink>
          </Item>
        ))}
      </List>

      {viewAll && (
        <ViewAllLinkContainer>
          <ButtonLink
            href={viewAll.href}
            target={viewAll.target}
            variant="textual"
          >
            {viewAll.text}
          </ButtonLink>
        </ViewAllLinkContainer>
      )}
    </Container>
  </Section>
)

export default ExpertisesOverviewSection
