import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'

import Image from '../components/Image'
import Link from '../components/Link'
import ArrowLeft from '../icons/ArrowLeft.svg'

const Section = styled.section(
  ({ theme }) => css`
    background: ${theme.colors.neutral1};
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x10}px ${theme.spacing.x2}px;
    }
  `,
)
const Container = styled.div(
  ({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin: 0 auto;
      max-width: ${theme.spacing.gridMaxWidth}px;
    }
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    margin-bottom: ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-bottom: ${theme.spacing.x3}px;
      max-width: ${theme.spacing.containerWidth}px;
    }
  `,
)
const Text = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-bottom: ${theme.spacing.x5}px;
      max-width: ${theme.spacing.containerWidth}px;
    }
  `,
)
const Content = styled.div<{ imageLocation: boolean }>(
  ({ imageLocation, theme }) =>
    css`
      background: ${theme.colors.neutral0};

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        display: flex;
        flex-direction: ${imageLocation ? 'row' : 'row-reverse'};
      }
    `,
)
const StyledImage = styled.div(
  ({ theme }) => css`
    height: 343px;
    position: relative;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      height: auto;
      min-height: 482px;
      width: 376px;
    }
  `,
)
const List = styled.ul(
  ({ theme }) => css`
    list-style: none;
    margin: 0;
    padding: ${theme.spacing.x1}px ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex: 1;
      padding: ${theme.spacing.x3}px;
    }
  `,
)
const ListItem = styled.li(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.neutral2};

    :last-of-type {
      border: none;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: inline-block;
      width: calc(50% - ${theme.spacing.x3}px);

      :nth-of-type(odd) {
        margin-right: ${theme.spacing.x6}px;
      }

      :nth-last-of-type(2) {
        border: none;
      }
    }
  `,
)
const StyledLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.colors.neutral4};
    display: block;
    padding: ${theme.spacing.x2}px ${theme.spacing.x3}px ${theme.spacing.x2}px 0;
    position: relative;

    :hover {
      color: ${theme.colors.primaryPurple};
      text-decoration: none;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x3}px ${theme.spacing.x3}px ${theme.spacing.x3}px
        0;
    }
  `,
)
const Arrow = styled(ArrowLeft)(
  ({ theme }) => css`
    position: absolute;
    right: 0;
    top: ${theme.spacing.x2 + theme.spacing.x0}px;
    transform: rotate(180deg);
    transition: transform 400ms ease-out;

    ${StyledLink}:hover & {
      transform: rotate(180deg) translateX(-4px);
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      top: ${theme.spacing.x3 + theme.spacing.x0}px;
    }
  `,
)

interface Props {
  image?: ImageInfo
  imageLocation?: string | null
  links: {
    id: string
    href: string
    target?: string
    text?: string | null
  }[]
  text: ReactNode
  title?: string | null
}

const LinksGridSection = ({
  image,
  imageLocation,
  links,
  text,
  title,
}: Props) => {
  if (!title || !image) return null
  return (
    <Section>
      <Container>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <Content imageLocation={imageLocation === 'left'}>
          <StyledImage>
            <Image
              alt={image.alt || ''}
              fill
              sizes="(min-width: 992px) 482px, 100vw"
              src={image}
            />
          </StyledImage>
          <List>
            {links.map(({ href, id, target, text }) => (
              <ListItem key={id}>
                <StyledLink href={href} target={target}>
                  {text} <Arrow />
                </StyledLink>
              </ListItem>
            ))}
          </List>
        </Content>
      </Container>
    </Section>
  )
}

export default LinksGridSection
