import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'

import Down from '../icons/DropdownDown.svg'
import Up from '../icons/DropdownUp.svg'
import Public from '../icons/Public.svg'
import Link from '../components/Link'
import { useReactiveVar } from '../utils/reactiveVar'
import { isModalOpenVar } from '../components/QuickHelpModal'
import { isSearchOpenVar } from './NavigationBar'

const Container = styled.div(
  ({ theme }) => css`
    cursor: pointer;
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      position: relative;
    }
  `,
)
const Backdrop = styled.div(
  ({ theme }) => css`
    background: rgba(0, 0, 0, 0.5);
    height: calc(100vh - 66px);
    left: 0;
    position: absolute;
    right: 0;
    top: calc(100% + 1px);
    z-index: ${theme.zIndex.relative1};
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      bottom: 0;
      height: auto;
      position: fixed;
      top: 54px;
    }
  `,
)
const List = styled.ul(
  ({ theme }) => css`
    background: ${theme.colors.neutral0};
    cursor: default;
    left: 0;
    list-style: none;
    margin: 0;
    padding: ${theme.spacing.x1}px 0;
    position: absolute;
    right: 0;
    top: calc(100% + 1px);
    z-index: ${theme.zIndex.relative2};
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      height: calc(100vh - 66px);
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      height: auto;
      left: auto;
      padding: ${theme.spacing.x2}px 0;
      position: absolute;
      right: ${theme.spacing.x2}px;
      text-align: left;
      top: 54px;
    }
  `,
)
const StyledLink = styled(Link)(
  ({ theme }) => css`
    :hover {
      text-decoration: none;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: 16px;
    }
  `,
)
const ListItem = styled.li(
  ({ theme }) => css`
    font-size: 16px;
    line-height: 1.57;

    ${StyledLink} {
      color: ${theme.colors.neutral4};
      display: block;
      padding: ${theme.spacing.x2}px;

      :hover {
        color: ${theme.colors.primaryPurple};
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: ${theme.spacing.x1}px ${theme.spacing.x3}px;

        :hover {
          color: ${theme.colors.neutral4};
          text-decoration: underline;
        }
      }
    }
  `,
)
const PublicIcon = styled(Public)(
  ({ theme }) => css`
    display: none;
    font-size: 16px;
    margin-right: ${theme.spacing.x1}px;
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: inline-block;
    }
  `,
)
const Selected = styled.button<{ isOpen: boolean }>(
  ({ isOpen, theme }) => css`
    background: ${theme.colors.neutral0};
    border: 0;
    color: ${isOpen ? theme.colors.primaryPurple : theme.colors.neutral4};
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.57;
    min-width: 100px;
    padding: ${theme.spacing.x2 + theme.spacing.x0}px ${theme.spacing.x2}px;
    position: relative;
    text-align: left;
    text-transform: uppercase;
    z-index: 2;

    :hover {
      color: ${theme.colors.primaryPurple};
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      background: transparent;
      color: ${theme.colors.neutral0};
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      min-width: auto;
      padding: ${theme.spacing.x2}px;
      text-align: left;
      text-decoration: ${isOpen ? 'underline' : 'none'};

      :hover {
        color: ${theme.colors.neutral0};
        text-decoration: underline;
      }
    }
  `,
)
const Value = styled.span(
  ({ theme }) => css`
    margin-right: ${theme.spacing.x1}px;
  `,
)

type Option = {
  displayName?: string | null
  isoCode?: string | null
}

interface Props {
  isOpen: boolean
  options: Option[]
  toggle: (item: boolean) => void
}

const LanguageSelector = ({ isOpen, options, toggle }: Props) => {
  const { asPath, locale } = useRouter()
  const isModalOpen = useReactiveVar(isModalOpenVar)
  const isSearchOpen = useReactiveVar(isSearchOpenVar)
  const dropDownArrow = isOpen ? (
    <Up aria-label="Expanded" />
  ) : (
    <Down aria-label="Collapse" />
  )

  if (options.length === 1) {
    return null
  }

  const handleSwitchLanguage = (alternativeLocale: string) => {
    document.cookie = `NEXT_LOCALE=${alternativeLocale}; max-age=31536000; path=/`
  }

  return (
    <Container>
      <Selected
        isOpen={isOpen}
        onClick={() => {
          if (isModalOpen) isModalOpenVar(false)
          if (isSearchOpen) isSearchOpenVar(false)
          toggle(!isOpen)
        }}
      >
        <PublicIcon />
        <Value>{locale}</Value>
        {dropDownArrow}
      </Selected>
      {isOpen && (
        <>
          <List role="listbox">
            {options.map(
              (item) =>
                item.isoCode && (
                  <ListItem
                    key={item.isoCode}
                    role="option"
                    onClick={() => {
                      toggle(false)
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      handleSwitchLanguage(item.isoCode!)
                    }}
                  >
                    <StyledLink href={asPath} locale={item.isoCode}>
                      {item.displayName}
                    </StyledLink>
                  </ListItem>
                ),
            )}
          </List>
          <Backdrop onClick={() => toggle(false)} />
        </>
      )}
    </Container>
  )
}

export default LanguageSelector
