import Head from 'next/head'
import { useRouter } from 'next/router'

import { SiteLocale } from '../graphql/datoSchema.generated'
import absolutify from '../utils/absolutify'
import { getDefaultLocale } from '../utils/env'

const defaultLocale = getDefaultLocale()

interface Props {
  title?: string
  description?: string
  /**
   * A custom string to override the value provided in paths (e.g. to append
   * the query string). Pagination/filtering/anything that affects what is shown
   * on the page must be reflected in the canonical.
   * Using the path from the URL/router is bad practice, as this often leads to
   * issues (e.g. it doesn't handle page rewrites correctly).
   * The custom string must be absolute.
   *
   * Set to false to disable the canonical (e.g. on error pages).
   */
  canonical?: string | false
  /**
   * This must include the current slug!
   * Do not include the locale part for each path.
   */
  paths: Record<SiteLocale, string>
  alternates?: boolean
  image?: {
    src: string
    alt?: string
  }
  twitterCard?: string
}

const Seo = ({
  title,
  description,
  canonical,
  paths,
  alternates = true,
  image,
  twitterCard,
}: Props) => {
  const { locale, locales } = useRouter()
  if (!locale) {
    throw new Error('locale is missing')
  }

  const canonicalHref =
    canonical !== false && (canonical || paths[locale as SiteLocale])
      ? canonical ||
        absolutify(paths[locale as SiteLocale], locale as SiteLocale)
      : undefined

  return (
    <Head>
      {title && <title key="title">{title}</title>}
      {description && <meta name="description" content={description} />}
      {canonicalHref && <link rel="canonical" href={canonicalHref} />}
      {alternates && paths[defaultLocale] && (
        <link
          rel="alternate"
          hrefLang="x-default"
          href={absolutify(paths[defaultLocale], defaultLocale)}
        />
      )}
      {alternates &&
        Object.entries(paths)
          // It's possible alternate languages are defined in the CMS that we do
          // not support yet. They're not available, so we mustn't list them as
          // alternates either.
          ?.filter(([locale]) => locales?.includes(locale))
          .map(([locale, path]) => {
            // This includes a link to the current locale. This is good practice
            // https://ahrefs.com/blog/hreflang-tags/#how-to-implement-hreflang

            return (
              <link
                key={locale}
                rel="alternate"
                hrefLang={locale}
                href={absolutify(path, locale as SiteLocale)}
              />
            )
          })}

      <meta name="og:type" content="website" />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:url" content={canonicalHref} />
      {image && (
        <meta property="og:image" itemProp="image" content={image.src} />
      )}
      {image?.alt && <meta property="og:image:alt" content={image.alt} />}

      <meta name="twitter:card" content={twitterCard || 'summary'} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={image.src} />}
      {image?.alt && <meta name="twitter:image:alt" content={image.alt} />}
    </Head>
  )
}

export default Seo
