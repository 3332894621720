import { ReactNode } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

import Phone from '../icons/Phone.svg'
import Mail from '../icons/Mail.svg'
import Location from '../icons/Location.svg'
import Image from './Image'
import ImageAspectRatio from './ImageAspectRatio'
import Link from './Link'

const Container = styled.div()

const StyledLink = styled(Link)`
  color: inherit;
`
const StyledImageContainer = styled(ImageAspectRatio)(
  ({ theme }) => css`
    height: 203px;
    margin: -${theme.spacing.x2}px -${theme.spacing.x2}px ${theme.spacing.x3}px;
    width: calc(100% + ${theme.spacing.x2}px * 2);

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: -${theme.spacing.x3}px -${theme.spacing.x3}px
        ${theme.spacing.x3}px;
      width: calc(100% + ${theme.spacing.x3}px * 2);
    }
  `,
)
const Text = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x3}px;
  `,
)
const ContactInfo = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spacing.x1 + theme.spacing.x0}px;
    align-items: center;
    margin: ${theme.spacing.x2}px 0;

    svg {
      height: 24px;
      place-self: flex-start;
    }

    :last-of-type {
      margin-bottom: 0;
    }
  `,
)

const Title = styled('h3', {
  shouldForwardProp: (prop) => prop !== 'highlight',
})<{ highlight?: boolean }>(({ theme, highlight }) => [
  css`
    ${theme.text.heading3(theme)}
  `,
  highlight &&
    css`
      color: inherit;
    `,
])

interface Props {
  title: string
  text?: ReactNode
  phone?: string
  email?: string
  address?: ReactNode
  image?: ImageInfo
  highlight?: boolean
}

const ContactCard = ({
  address,
  email,
  highlight,
  image,
  phone,
  text,
  title,
}: Props) => (
  <Container>
    {image && (
      <StyledImageContainer aspectRatio={203 / 360}>
        <Image
          src={image}
          alt={image?.alt || ''}
          sizes="(min-width: 768px) 50vw, 100vw"
          fill
          aspectRatio="360:203"
        />
      </StyledImageContainer>
    )}
    <Title as={title ? 'h3' : undefined} highlight={highlight}>
      {title}
    </Title>
    {text && <Text>{text}</Text>}
    {email && (
      <ContactInfo>
        <Mail aria-label="Email" />{' '}
        <StyledLink href={`mailto:${email}`}>{email}</StyledLink>
      </ContactInfo>
    )}
    {phone && (
      <ContactInfo>
        <Phone aria-label="Phone" />{' '}
        <StyledLink href={`tel:${phone}`}>{phone}</StyledLink>
      </ContactInfo>
    )}
    {address && (
      <ContactInfo>
        <Location aria-label="Address" /> {address}
      </ContactInfo>
    )}
  </Container>
)

export default ContactCard
