// Note: you shouldn't use the value in any code as it may be unsafe. e.g. Only
// do equals comparisons
export const getCookie = <T extends string | undefined>(
  name: string,
  initialValue: T,
): T => {
  if (typeof document === 'undefined') {
    return initialValue
  }

  const value = document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=')
    return parts[0] === name ? decodeURIComponent(parts[1]) : r
  }, '') as T
  if (value) {
    return value
  }
  return initialValue
}
