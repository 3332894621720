import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

import ArticleType from '../components/ArticleType'
import ButtonLink from '../components/ButtonLink'
import Link from '../components/Link'
import ArrowLeft from '../icons/ArrowLeft.svg'

const Section = styled.section(
  ({ theme }) => css`
    background: ${theme.colors.neutral1};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x2}px;
    }
  `,
)
const Container = styled.div(
  ({ theme }) => css`
    max-width: ${theme.spacing.gridMaxWidth}px;
    margin: 0 auto;
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x10}px 0;
    }
  `,
)
const StyledArticleType = styled(ArticleType)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x2}px;
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    margin-bottom: ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x5}px;
    }
  `,
)
const StyledLink = styled(Link)(
  ({ theme }) => css`
    color: inherit;
    margin-bottom: ${theme.spacing.x3}px;

    :hover {
      text-decoration: none;
    }
  `,
)
const ArticlesContainer = styled.div(
  ({ theme }) =>
    // All carousel code in one block
    css`
      @media screen and (max-width: ${theme.breakpoints.mobileMax}px) {
        display: grid;
        gap: ${theme.spacing.x2}px;
        grid-template-columns: 300px;
        grid-auto-flow: column;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        overflow-x: auto;
        margin: 0 -${theme.spacing.x2}px;
        scroll-padding-left: ${theme.spacing.x2}px;
        padding: 0 ${theme.spacing.x2}px;

        > * {
          width: 300px;
          scroll-snap-align: start;
        }
      }
    `,
)
const Article = styled.article(
  ({ theme }) => css`
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
    background-color: ${theme.colors.neutral0};
    padding: ${theme.spacing.x2}px;
    height: 100%;
    position: relative;

    :hover {
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x3}px;
      padding: ${theme.spacing.x3}px;
    }
  `,
)
const ArticleTitle = styled.h3(
  ({ theme }) => css`
    ${theme.text.heading3(theme)}
    margin-bottom: ${theme.spacing.x2}px;
  `,
)
const Brief = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x9}px;
    max-height: 84px;
    overflow: hidden;
    position: relative;
    word-break: break-all;
    ::before {
      background: ${theme.colors.neutral0};
      bottom: 0;
      content: '...';
      padding-left: ${theme.spacing.x0 / 2};
      position: absolute;
      right: 0;
    }
    ::after {
      background: ${theme.colors.neutral0};
      content: '';
      height: ${theme.spacing.x3}px;
      right: 0;
      position: absolute;
      width: 1rem;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x6}px;
      max-height: 97px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-height: 49px;
    }
  `,
)
const ReadMore = styled.div(
  ({ theme }) => css`
    bottom: ${theme.spacing.x2}px;
    font-weight: bold;
    left: ${theme.spacing.x2}px;
    position: absolute;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: inline-block;
      position: static;
    }
  `,
)
const ArrowRight = styled(ArrowLeft)(
  ({ theme }) => css`
    transform: rotate(180deg);
    margin-left: ${theme.spacing.x1}px;
    height: 24px;
    margin-bottom: -3px;
    transition: transform 400ms ease-out;

    ${StyledLink}:hover & {
      transform: rotate(180deg) translateX(-4px);
    }
  `,
)
const ViewAllLinkContainer = styled.div(
  ({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      text-align: right;
      margin-top: ${theme.spacing.x5}px;
    }
  `,
)

interface Props extends Omit<ComponentProps<typeof Section>, 'title'> {
  title: ReactNode
  viewAll?: {
    href: string
    target?: string
    text: string
  }
  articles: Array<{
    title: string
    brief: ReactNode
    type: string
    typeColor: string
    href: string
  }>
  readMore: string
}

const NewsFeedSection = ({
  title,
  viewAll,
  articles,
  readMore,
  ...others
}: Props) => (
  <Section {...others}>
    <Container>
      {title && <Title>{title}</Title>}
      <ArticlesContainer>
        {articles.map(({ title, brief, type, typeColor, href }) => (
          <StyledLink key={href} href={href}>
            <Article>
              <StyledArticleType color={typeColor}>{type}</StyledArticleType>
              <ArticleTitle>{title}</ArticleTitle>
              <Brief>{brief}</Brief>

              <ReadMore>
                {readMore} <ArrowRight />
              </ReadMore>
            </Article>
          </StyledLink>
        ))}
      </ArticlesContainer>
      {viewAll && (
        <ViewAllLinkContainer>
          <ButtonLink
            href={viewAll.href}
            target={viewAll.target}
            variant="textual"
          >
            {viewAll.text}
          </ButtonLink>
        </ViewAllLinkContainer>
      )}
    </Container>
  </Section>
)

export default NewsFeedSection
