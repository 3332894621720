import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'

import { StyledLink } from '../components/ButtonLink'
import Image from '../components/Image'
import { darkBackgroundVariantCss } from '../components/RichText'

const Section = styled.section<{ coloredBackground?: boolean }>(
  ({ coloredBackground, theme }) => css`
    padding: ${theme.spacing.x2 + theme.spacing.x0}px ${theme.spacing.x2}px;
    background: ${coloredBackground
      ? `linear-gradient(
      to bottom,
      ${theme.colors.neutral0} 34%,
      ${theme.colors.neutral1} 34%,
      ${theme.colors.neutral1} 100%
    )`
      : theme.colors.neutral1};

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x10}px ${theme.spacing.x2}px;
      position: relative;
      ::after {
        content: '';
        position: absolute;
        z-index: ${theme.zIndex.behindSiblings1};
        left: 0;
        right: 0;
        bottom: 0;
        top: 30%;
        background: ${theme.colors.neutral1};
      }
    }
  `,
)
const Container = styled.div(
  ({ theme }) =>
    css`
      max-width: ${theme.spacing.gridMaxWidth}px;
      margin: 0 auto;
      display: flex;
      flex-flow: column;
      gap: ${theme.spacing.x2 + theme.spacing.x0}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        flex-flow: row;
        gap: ${theme.spacing.x3}px;
      }
    `,
)
const MainColumn = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.primaryPurpleDark};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
    color: ${theme.colors.neutral0};
    padding: ${theme.spacing.x2}px;

    ${darkBackgroundVariantCss(theme)}

    a[href^='tel:'] {
      color: ${theme.colors.buttonPrimaryBackground};
    }

    ${StyledLink}:last-of-type {
      font-weight: normal;
    }
    ${StyledLink} {
      margin-right: ${theme.spacing.x2}px;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex: 1 0 50%;
      padding: ${theme.spacing.x3}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex: 1 0 65.96%;
      padding: ${theme.spacing.x3}px;
    }
  `,
)
const SideColumn = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing.x2}px;
    background: ${theme.colors.neutral0};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-flow: column;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex: 1 1 50%;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex: 0 1 auto;
      padding: ${theme.spacing.x3}px;
    }
  `,
)
const SideColumnContent = styled.div`
  flex: 1 1 auto;
`
const SideColumnImage = styled(Image)(
  ({ theme }) => css`
    height: auto;
    margin-top: ${theme.spacing.x5}px;
    width: 100%;
  `,
)
const Desktop = styled.div(
  ({ theme }) => css`
    display: none;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: block;
    }
  `,
)
const Mobile = styled.div(
  ({ theme }) => css`
    display: block;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: none;
    }
  `,
)

interface Props {
  coloredBackground: boolean
  mainColumnDesktop: ReactNode
  mainColumnMobile: ReactNode
  sideColumnDesktop: ReactNode
  sideColumnMobile: ReactNode
  sideColumnImage?: ImageInfo
}

const SosIntroductionSection = ({
  coloredBackground,
  mainColumnDesktop,
  mainColumnMobile,
  sideColumnDesktop,
  sideColumnMobile,
  sideColumnImage,
}: Props) => (
  <Section coloredBackground={coloredBackground}>
    <Container>
      <MainColumn>
        <Desktop>{mainColumnDesktop}</Desktop>
        <Mobile>{mainColumnMobile}</Mobile>
      </MainColumn>
      <SideColumn>
        <SideColumnContent>
          <Desktop>{sideColumnDesktop}</Desktop>
          <Mobile>{sideColumnMobile}</Mobile>
        </SideColumnContent>

        {sideColumnImage && (
          <SideColumnImage
            src={sideColumnImage}
            alt={sideColumnImage?.alt || ''}
            aspectRatio="312:176"
            sizes="(min-width: 768px) 312px, 100vw"
          />
        )}
      </SideColumn>
    </Container>
  </Section>
)

export default SosIntroductionSection
