import { ReactNode } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

import Link from './Link'
import ArrowRight from '../icons/Outline/Icon/Outline/Arrow/Right.svg'
import OpenNew from '../icons/Outline/Icon/Outline/OpenNew.svg'

const Title = styled('h3', {
  shouldForwardProp: (prop) => prop !== 'highlight',
})<{ highlight?: boolean }>(({ theme, highlight }) => [
  css`
    ${theme.text.heading3(theme)}
  `,
  highlight &&
    css`
      color: ${theme.colors.neutral0};
    `,
])
const Description = styled('div', {
  shouldForwardProp: (prop) => prop !== 'highlight',
})<{ highlight?: boolean }>(
  ({ theme, highlight }) => css`
    color: ${highlight ? theme.colors.neutral0 : theme.colors.neutral4};
    line-height: 1.57;
  `,
)
const StyledLink = styled(Link)(
  css`
    display: flex;
    flex-direction: column;
    height: 100%;

    :hover {
      text-decoration: none;
    }
  `,
)
const Content = styled.div(css`
  flex: 1;
`)
const Footer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'highlight',
})<{ highlight?: boolean }>(
  ({ theme, highlight }) => css`
    color: ${highlight ? theme.colors.neutral0 : theme.colors.neutral4};
    font-weight: bold;
    line-height: 1.57;
  `,
)
const FooterText = styled.span(
  ({ theme }) => css`
    margin-right: ${theme.spacing.x1}px;
    ${StyledLink}:hover & {
      transition: margin-right 400ms ease-out;
      margin-right: 12px;
    }
  `,
)

interface Props {
  highlight?: boolean
  link: {
    text?: string
    type: 'LinkWithTextExternalRecord' | 'LinkWithTextInternalRecord'
    href: string
    target?: string
  }
  text?: ReactNode
  title: string
}

const LinkCard = ({ highlight, link, text, title }: Props) => (
  <StyledLink href={link.href} target={link.target}>
    <Content>
      <Title highlight={highlight}>{title}</Title>
      <Description highlight={highlight}>{text}</Description>
    </Content>
    <Footer highlight={highlight}>
      <FooterText>{link.text}</FooterText>
      {link.type === 'LinkWithTextInternalRecord' ? (
        <ArrowRight />
      ) : (
        <OpenNew />
      )}
    </Footer>
  </StyledLink>
)

export default LinkCard
