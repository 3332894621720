import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ChangeEvent, useEffect, useRef, useState } from 'react'

import Checkbox from './Checkbox'
import DropdownDownSvg from '../icons/DropdownDown.svg'

const Container = styled.div`
  position: relative;
  display: inline-block;
  font-size: 14px;
  min-width: 188px;
`
const Button = styled.button<{ isOpen?: boolean }>(({ theme, isOpen }) => [
  css`
    background: ${theme.colors.neutral0};
    padding: ${theme.spacing.x2}px ${theme.spacing.x3}px;
    border: 0;
    font-weight: bold;
    width: 100%;
    text-align: left;
    display: flex;
    cursor: pointer;

    div {
      flex: 1 1 auto;
    }

    :hover {
      background: ${theme.colors.primaryPurpleExtraLight};
    }
  `,
  isOpen &&
    css`
      background: ${theme.colors.primaryPurple};
      color: ${theme.colors.neutral0};

      :hover {
        background: ${theme.colors.primaryPurple};
      }
    `,
])
const DropdownIcon = styled(DropdownDownSvg)(
  ({ theme }) => css`
    width: ${theme.spacing.x3}px;
    height: ${theme.spacing.x3}px;
  `,
)
const Popover = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: calc(100% + 0px);
    left: 0;
    z-index: ${theme.zIndex.relative1};
    background: ${theme.colors.neutral0};
    padding: ${theme.spacing.x3}px;
    width: 100%;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    overflow-y: auto;
    max-height: 400px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      width: auto;
      min-width: 340px;
    }
  `,
)
const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
const Item = styled.li(
  ({ theme }) => css`
    :not(:last-of-type) {
      margin-bottom: ${theme.spacing.x2}px;
    }
  `,
)
const Label = styled.label`
  display: block;
  cursor: pointer;
`
const StyledCheckbox = styled(Checkbox)(
  ({ theme }) =>
    css`
      margin-right: ${theme.spacing.x1}px;
      vertical-align: bottom;
    `,
)
const Select = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
`

const isTouchScreenDevice = () =>
  typeof window !== 'undefined' &&
  document &&
  'ontouchstart' in document.documentElement

interface Props {
  label: string
  children: string[]
  active: string[]
  onSelect: (items: string[]) => void
}

const MultiSelect = ({
  label,
  children,
  onSelect,
  active,
  ...others
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isTouch, setIsTouch] = useState(false)
  // We need to set isTouch like this to avoid hydration errors
  useEffect(() => {
    setIsTouch(isTouchScreenDevice)
  }, [])

  const open = () => {
    setIsOpen(true)
  }
  const close = () => {
    setIsOpen(false)
  }
  const handleButtonClick = () => {
    setIsOpen(!isOpen)
  }
  // Close on click outside
  const containerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!isOpen) {
      return
    }

    const handleClick = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        close()
      }
    }

    document.addEventListener('click', handleClick)
    // eslint-disable-next-line consistent-return
    return () => document.removeEventListener('click', handleClick)
  }, [isOpen])
  const handleSelectOption = (e: ChangeEvent<HTMLSelectElement>) => {
    const newSelectedValues = Array.from(e.target.options)
      .filter((option) => option.selected)
      .map((option) => option.value)

    onSelect(newSelectedValues)
  }
  const handleCheckboxSelect = (item: string) => () => {
    onSelect(
      active.includes(item)
        ? active.filter((v) => v !== item)
        : [...active, item],
    )
  }

  return (
    <Container {...others} ref={containerRef}>
      <Button onClick={handleButtonClick} isOpen={isOpen}>
        <div>{label}</div>
        <DropdownIcon />
      </Button>
      {isTouch && (
        <Select
          multiple
          onChange={handleSelectOption}
          onFocus={open}
          onBlur={close}
        >
          {children.map((child) => (
            <option key={child} value={child} selected={active.includes(child)}>
              {child}
            </option>
          ))}
        </Select>
      )}

      {!isTouch && isOpen && (
        <Popover>
          <List>
            {children.map((item) => (
              <Item key={item}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <Label>
                  <StyledCheckbox
                    as="div"
                    onChange={handleCheckboxSelect(item)}
                    checked={active.includes(item)}
                  />
                  {item}
                </Label>
              </Item>
            ))}
          </List>
        </Popover>
      )}
    </Container>
  )
}

export default MultiSelect
