import { css } from '@emotion/react'
import styled from '@emotion/styled'

// height / width
const ImageAspectRatio = styled.div<{ aspectRatio: number }>(
  ({ aspectRatio }) => [
    css`
      width: 100%;
      padding-top: ${Math.floor(aspectRatio * 100)}%;
      position: relative;
    `,
  ],
)

export default ImageAspectRatio
