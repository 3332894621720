import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { usePageContext } from '../utils/PageContext'
import ArrowLeftSvg from '../icons/ArrowLeft.svg'
import { LinkWithTextFragment } from '../graphql/linkWithTextFragment'
import { resolveLinkWithText } from '../graphql/linkWithTextFragment.utils'

import convertDatoImage, { ImageField } from '../utils/convertDatoImage'
import Image from './Image'
import Link from './Link'

const Heading = styled.li(
  ({ theme }) => css`
    ${theme.text.heading3(theme)}
  `,
)
const StyledImage = styled(Image)(css`
  height: 222px;
  width: 100%;
`)
const Dialog = styled.div(
  ({ theme }) => css`
    display: none;
    left: 100%;
    padding-left: ${theme.spacing.x3}px;
    position: absolute;
    top: 0;
    width: 100%;
  `,
)
const SubList = styled.ul(
  ({ theme }) => css`
    border-right: 1px solid ${theme.colors.neutral1};
    display: none;
    left: 100%;
    height: 100%;
    list-style: none;
    padding: 0;
    padding-left: ${theme.spacing.x3}px;
    position: absolute;
    top: 0;
    width: 100%;
  `,
)
const MenuLink = styled(Link)(
  ({ theme }) => css`
    display: block;
    color: inherit;
    margin: -${theme.spacing.x1}px -${theme.spacing.x2}px;
    padding: ${theme.spacing.x2}px ${theme.spacing.x2}px;
  `,
)
const Button = styled.button(
  ({ theme }) => css`
    border: none;
    cursor: pointer;
    display: flex;
    margin: -${theme.spacing.x1}px -${theme.spacing.x2}px;
    padding: ${theme.spacing.x2}px ${theme.spacing.x2}px;
    text-align: left;
    width: 100%;
  `,
)
const ButtonText = styled.div`
  flex: 1;
`
const ArrowRight = styled(ArrowLeftSvg)(
  ({ theme }) => css`
    transform: rotate(180deg);
    margin-left: ${theme.spacing.x1}px;
    height: 24px;
    transition: transform 400ms ease-out;
  `,
)
const ListItem = styled.li(
  ({ theme }) => css`
    :hover,
    :focus-within {
      > ${Button}, > ${MenuLink} {
        color: ${theme.colors.primaryPurple};
        text-decoration: underline;

        ${ArrowRight} {
          transform: rotate(180deg) translateX(-4px);
        }
      }

      ${SubList} {
        display: block;
      }

      ${SubList} & {
        ${Dialog} {
          display: block;
        }
      }
    }
    :nth-of-type(2) {
      > ${Button}, > ${MenuLink} {
        margin-top: -${theme.spacing.x1}px;
        padding-top: ${theme.spacing.x4}px;
      }
    }
  `,
)
const Description = styled.p(
  ({ theme }) => css`
    margin: ${theme.spacing.x3}px 0 0;
  `,
)
const List = styled.ul(
  ({ theme }) => css`
    border-right: 1px solid ${theme.colors.neutral1};
    height: 380px;
    list-style: none;
    margin: ${theme.spacing.x5}px 0;
    padding: 0;
    position: relative;
    width: 33.333%;

    ${ListItem} {
      padding-right: ${theme.spacing.x3}px;
    }
  `,
)

type MenuItemType = {
  description?: string
  href: string
  id: string
  image?: ImageField
  link?: ({ text: string } & LinkWithTextFragment)[]
  subMenu?: MenuItemType[]
  target?: string
  text: string
} & LinkWithTextFragment

interface Props {
  close: () => void
  menu: MenuItemType
}

const MultiLevelMenu = ({ close, menu: { subMenu, text } }: Props) => {
  const { siteConfig } = usePageContext()
  return (
    <List>
      <Heading>{text}</Heading>
      {subMenu?.map(({ subMenu, id, link, text }) => {
        if (subMenu && subMenu.length > 0)
          return (
            <ListItem key={id}>
              <Button
                type="button"
                aria-haspopup="menu"
                onClick={() => {
                  if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur()
                  }
                }}
              >
                <ButtonText>{text}</ButtonText> <ArrowRight />
              </Button>
              <SubList>
                <Heading>{text}</Heading>
                {subMenu.map(({ description, id, image, link, text }) => {
                  if (!link || link.length === 0) return null
                  const { href, target } = resolveLinkWithText(
                    siteConfig,
                    link[0],
                  )
                  return (
                    <ListItem key={id}>
                      <MenuLink
                        href={href}
                        target={target}
                        aria-haspopup="dialog"
                        onClick={() => {
                          if (document.activeElement instanceof HTMLElement) {
                            document.activeElement.blur()
                            close()
                          }
                        }}
                      >
                        {text}
                      </MenuLink>
                      <Dialog>
                        {image && (
                          <StyledImage
                            alt={image?.alt || ''}
                            height="222"
                            src={convertDatoImage(image)}
                            width="394"
                          />
                        )}
                        <Description>{description}</Description>
                      </Dialog>
                    </ListItem>
                  )
                })}
              </SubList>
            </ListItem>
          )
        if (!link || link.length === 0) return null
        const { href, target } = resolveLinkWithText(siteConfig, link[0])
        return (
          <ListItem key={id}>
            <MenuLink
              href={href}
              target={target}
              onClick={() => {
                if (document.activeElement instanceof HTMLElement) {
                  document.activeElement.blur()
                  close()
                }
              }}
            >
              {text}
            </MenuLink>
          </ListItem>
        )
      })}
    </List>
  )
}

export default MultiLevelMenu
