import { FunctionComponent, useMemo } from 'react'

import MissingBlockComponent from './MissingBlockComponent'

export const normalizeTypename = (typename: string) =>
  typename.replace(/Record$/, '')

export interface BlockInterface<TComponent extends string> {
  id: string
  __typename: TComponent
}

interface BlockProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  components: Record<string, FunctionComponent<any>>
  content: BlockInterface<string>
  data?: Record<string, unknown>
}

export const Block = ({ components, content, data }: BlockProps) => {
  const Component = components[normalizeTypename(content.__typename)]
  if (!Component) {
    return <MissingBlockComponent name={content.__typename} />
  }

  return <Component content={content} data={data} />
}

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  components: Record<string, FunctionComponent<any>>
  content: BlockInterface<string>[]
  // [typename]: { [propName]: data }
  data?: Record<string, Record<string, unknown>>
  [prop: string]: unknown
}

const BlockZone = ({ components, content, data }: Props) => {
  const renderedBloks = useMemo(
    () =>
      components &&
      content.map((block) => (
        <Block
          key={block.id}
          components={components}
          content={block}
          data={data?.[normalizeTypename(block.__typename)]}
        />
      )),
    [content, components, data],
  )

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{renderedBloks}</>
}

export default BlockZone
