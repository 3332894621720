import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'

import blocks from '../blocks'
import { Layout as LayoutType } from '../graphql/layout'
import { SiteConfig } from '../graphql/siteConfig'
import { Breadcrumb, PageContextProvider } from '../utils/PageContext'
import BlockZone from './BlockZone'
import OrganizationRichSnippet from './OrganizationRichSnippet'
import WebsiteRichSnippet from './WebsiteRichSnippet'
import BreadcrumbsRichSnippet from './BreadcrumbsRichSnippet'

const Sticky = styled.div(
  ({ theme }) => css`
    position: sticky;
    top: 0;
    z-index: ${theme.zIndex.header};
  `,
)
const Main = styled.main(
  ({ theme }) => css`
    background: ${theme.colors.neutral0};
  `,
)

interface Props {
  layout: LayoutType
  siteConfig: SiteConfig
  breadcrumbs?: Breadcrumb[]
  children: ReactNode
  hideSearchMobile?: boolean
}

const Layout = ({
  layout,
  siteConfig,
  breadcrumbs,
  children,
  hideSearchMobile,
}: Props) => {
  const contentBlockIndex = layout.content.findIndex(
    (item) => item.__typename === 'LayoutPageContentRecord',
  )

  if (contentBlockIndex === -1) {
    throw new Error('Layout is missing PageContent section')
  }

  const before = layout.content.slice(0, contentBlockIndex)
  const after = layout.content.slice(contentBlockIndex + 1)

  return (
    <PageContextProvider
      value={{
        breadcrumbs,
        siteConfig,
        hideSearchMobile,
      }}
    >
      <OrganizationRichSnippet {...siteConfig} />
      <WebsiteRichSnippet {...siteConfig} />
      <BreadcrumbsRichSnippet breadcrumbs={breadcrumbs} />
      <Sticky>
        <BlockZone components={blocks} content={layout.stickyContent} />
      </Sticky>
      <BlockZone components={blocks} content={before} />
      <Main>{children}</Main>
      <BlockZone components={blocks} content={after} />
    </PageContextProvider>
  )
}

export default Layout
