import RichText from '../components/RichText'
import convertDatoImage from '../utils/convertDatoImage'
import LinksGridSection from '../sections/LinksGridSection'
import { LinksGridSectionFragment } from '../graphql/linksGridSectionFragment'
import { resolveLinkWithText } from '../graphql/linkWithTextFragment.utils'
import { usePageContext } from '../utils/PageContext'

interface Props {
  content: LinksGridSectionFragment
}

const LinksGridSectionBlock = ({
  content: { image, imageLocation, links, text, title },
}: Props) => {
  const { siteConfig } = usePageContext()
  return (
    <LinksGridSection
      image={convertDatoImage(image)}
      imageLocation={imageLocation}
      links={links.map((link) => {
        return {
          id: link.id,
          ...resolveLinkWithText(siteConfig, link),
          text: link.text,
        }
      })}
      text={<RichText text={text} />}
      title={title}
    />
  )
}

export default LinksGridSectionBlock
