import resolveUrlForRecord from '../utils/resolveUrlForRecord'

import { LinkWithTextFragment } from './linkWithTextFragment.generated'
import { SiteConfig } from './siteConfig'

const fallback = {
  href: '',
  target: '_self',
}

export const resolveLinkWithText = (
  siteConfig: SiteConfig,
  link: LinkWithTextFragment,
) => {
  switch (link.__typename) {
    case 'LinkWithTextExternalRecord':
      return {
        href: link.url || '',
        target: link.openInNewTab ? ('_blank' as const) : undefined,
      }
    case 'LinkWithTextInternalRecord': {
      const path = link.page
        ? resolveUrlForRecord(siteConfig, link.page)
        : undefined

      return path
        ? {
            href: link.sectionAnchor ? `${path}#${link.sectionAnchor}` : path,
          }
        : fallback
    }
    default:
      return fallback
  }
}
