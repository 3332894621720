import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'

import ButtonLink from '../components/ButtonLink'
import { resolveLinkWithText } from '../graphql/linkWithTextFragment.utils'
import AlertBar from '../sections/AlertBar'
import { usePageContext } from '../utils/PageContext'
import { AlertBarFragment } from '../graphql/alertBarFragment'
import notEmpty from '../utils/notEmpty'

const StyledButtonLink = styled(ButtonLink)(
  ({ theme }) => css`
    color: #fff;
    margin-top: ${theme.spacing.x1}px;
  `,
)

interface Props {
  content: AlertBarFragment
}

const AlertBarBlock = ({
  content: { text, lastUpdate, link, countries },
  ...others
}: Props) => {
  const { locale } = useRouter()
  const { siteConfig } = usePageContext()

  return (
    <AlertBar
      {...others}
      details={
        lastUpdate &&
        link[0] && (
          <>
            <div suppressHydrationWarning>
              {siteConfig.lastUpdate}:{' '}
              {new Date(lastUpdate).toLocaleDateString(locale, {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
              })}
            </div>
            <StyledButtonLink
              {...resolveLinkWithText(siteConfig, link[0])}
              variant="textual"
            >
              {link[0].text}
            </StyledButtonLink>
          </>
        )
      }
      countries={countries
        ?.map(({ countryCode }) => countryCode)
        .filter(notEmpty)}
    >
      {text}
    </AlertBar>
  )
}

export default AlertBarBlock
