import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode, useState } from 'react'
import AnimateHeight from 'react-animate-height'

import ChevronRight from '../icons/ChevronRight.svg'
import { invisibleButtonCss } from './Button'

const Group = styled.div``
const Title = styled('button', {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<{ expanded?: boolean }>(({ theme, expanded }) => [
  invisibleButtonCss,
  css`
    ${theme.text.heading4(theme)}
    color: inherit;
    cursor: pointer;
    display: flex;
    padding: ${theme.spacing.x1}px 0;
    position: relative;
    text-align: left;
    width: 100%;

    ::after {
      content: '';
      position: absolute;
      bottom: 0;
      display: block;
      width: 100%;
      height: 1px;
      opacity: 0.5;
      background: ${theme.colors.neutral2};
    }

    > div {
      flex: 1 1 auto;
      padding: ${theme.spacing.x0}px 0;
    }
    > svg {
      flex: 0 0 auto;
      height: 32px;
      align-self: center;
      transform: rotate(90deg);
      transition: transform 400ms ease-in-out;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x2}px 0;

      > div {
        padding: 0;
      }
    }
  `,
  expanded &&
    css`
      > svg {
        transform: rotate(270deg);
      }
    `,
])
const Text = styled.div`
  text-align: left;
`

interface Props {
  title: string
  children: ReactNode
}

const AccordeonGroup = ({ title, children }: Props) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <Group>
      <Title onClick={() => setExpanded(!expanded)} expanded={expanded}>
        <div>{title}</div>
        <ChevronRight aria-label={expanded ? 'Collapse' : 'Expand'} />
      </Title>
      <AnimateHeight height={expanded ? 'auto' : 0}>
        <Text>{children}</Text>
      </AnimateHeight>
    </Group>
  )
}

export default AccordeonGroup
