import styled from '@emotion/styled'
import { ReactNode } from 'react'

import Plus from '../icons/Outline/Icon/Outline/Plus.svg'
import Button, { primaryButtonCss } from './Button'
import { useReactiveVar } from '../utils/reactiveVar'
import { isModalOpenVar } from './QuickHelpModal'
import { isSearchOpenVar } from '../sections/NavigationBar'

const StyledButton = styled(Button)(({ theme }) => primaryButtonCss(theme))

interface Props {
  children: ReactNode
}

const QuickHelpButton = ({ children }: Props) => {
  const isModalOpen = useReactiveVar(isModalOpenVar)
  const isSearchOpen = useReactiveVar(isSearchOpenVar)

  return (
    <StyledButton
      type="button"
      onClick={() => {
        isModalOpenVar(!isModalOpen)
        if (isSearchOpen) isSearchOpenVar(false)
      }}
    >
      {children} <Plus />
    </StyledButton>
  )
}

export default QuickHelpButton
