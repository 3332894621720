import RichText from '../components/RichText'
import { resolveLinkWithText } from '../graphql/linkWithTextFragment.utils'
import TextWithImageSection from '../sections/TextWithImageSection'
import convertDatoImage from '../utils/convertDatoImage'
import { TextWithImageHeaderFragment } from '../graphql/textWithImageHeaderFragment'
import { usePageContext } from '../utils/PageContext'

interface Props {
  content: TextWithImageHeaderFragment
}

const TextWithImageHeaderBlock = ({
  content: { title, text, image, imageSize, callToAction },
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()

  if (!title) {
    return null
  }

  return (
    <TextWithImageSection
      title={title}
      titleVariant="pageTitle"
      text={text && <RichText text={text} />}
      image={convertDatoImage(image)}
      imageSize={
        (imageSize || undefined) as 'landscape' | 'portrait' | undefined
      }
      callToAction={
        callToAction[0]?.text
          ? {
              ...resolveLinkWithText(siteConfig, callToAction[0]),
              text: callToAction[0].text,
            }
          : undefined
      }
      variant="header"
      {...others}
    />
  )
}

export default TextWithImageHeaderBlock
