import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'

const Container = styled.div(
  ({ theme }) => css`
    max-width: ${theme.spacing.containerWidth}px;
    margin: 0 auto;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      text-align: center;
    }
  `,
)
const Title = styled.h1(
  ({ theme }) => css`
    ${theme.text.heading1(theme)}
    margin-bottom: 24px;
  `,
)
const Explanation = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.neutral4};
    font-size: 14px;
    line-height: 1.57;
  `,
)

interface Props {
  query: string
  title: string
  explanation: ReactNode
}

const NoSearchResults = ({ query, title, explanation }: Props) => (
  <Container>
    <Title>{title.replace('{query}', query)}</Title>
    <Explanation>{explanation}</Explanation>
  </Container>
)

export default NoSearchResults
