import { css } from '@emotion/react'
import styled from '@emotion/styled'

import Image from '../components/Image'
import Link from '../components/Link'
import RichText from '../components/RichText'
import { resolveLinkWithText } from '../graphql/linkWithTextFragment.utils'
import TopBar from '../sections/TopBar'
import convertDatoImage from '../utils/convertDatoImage'
import { TopBarFragment } from '../graphql/topBarFragment'
import { usePageContext } from '../utils/PageContext'

const Icon = styled(Image)(
  ({ theme }) => css`
    margin-right: ${theme.spacing.x1}px;
  `,
)

interface Props {
  content: TopBarFragment
}

const TopBarBlock = ({ content: { text, content }, ...others }: Props) => {
  const { siteConfig } = usePageContext()

  if (!text || !content) {
    return null
  }

  return (
    <TopBar
      text={text && <RichText text={text} />}
      blocks={content.map((block) => {
        const image = convertDatoImage(block.icon)

        return (
          <Link
            key={block.id}
            {...resolveLinkWithText(siteConfig, block.link[0])}
          >
            {image && (
              <Icon src={image} alt="" priority width={20} height={20} />
            )}
            {block.link[0].text}
          </Link>
        )
      })}
      {...others}
    />
  )
}

export default TopBarBlock
