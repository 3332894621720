import { resolveLinkWithText } from '../graphql/linkWithTextFragment.utils'
import CallToActionBannerSection from '../sections/CallToActionBannerSection'
import { CallToActionBannerSectionFragment } from '../graphql/callToActionBannerSectionFragment'
import { usePageContext } from '../utils/PageContext'

interface Props {
  content: CallToActionBannerSectionFragment
}

const CallToActionBannerSectionBlock = ({
  content: { title, callToAction, variant, anchor },
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()
  if (!title || !callToAction[0]?.text || !variant) {
    return null
  }

  return (
    <CallToActionBannerSection
      title={title}
      callToAction={{
        ...resolveLinkWithText(siteConfig, callToAction[0]),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        text: callToAction[0].text!,
      }}
      variant={
        variant as
          | 'whiteOnGray'
          | 'whiteOnDark'
          | 'grayOnWhite'
          | 'grayOnDark'
          | 'darkOnWhite'
          | 'darkOnGray'
      }
      id={anchor || undefined}
      {...others}
    />
  )
}

export default CallToActionBannerSectionBlock
