import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'

const Container = styled.div(
  ({ theme }) => css`
    border: 1px solid ${theme.colors.error};
    padding: ${theme.spacing.x2}px;
  `,
)

interface Props extends Omit<ComponentProps<typeof Container>, 'children'> {
  name: string
}

const MissingBlockComponent = ({ name, ...others }: Props) => {
  // eslint-disable-next-line no-console
  console.warn('Missing component for blok:', name)

  if (process.env.NODE_ENV !== 'development') {
    return null
  }

  return <Container {...others}>Missing component for blok: {name}</Container>
}

export default MissingBlockComponent
