import Head from 'next/head'
import { useRouter } from 'next/router'
import { jsonLdScriptProps } from 'react-schemaorg'

import { BreadcrumbList } from 'schema-dts'
import { SiteLocale } from '../graphql/datoSchema.generated'
import absolutify from '../utils/absolutify'
import { Breadcrumb } from '../utils/PageContext'

interface Props {
  breadcrumbs?: Breadcrumb[]
}

const BreadcrumbsRichSnippet = ({ breadcrumbs = [] }: Props) => {
  const { locale } = useRouter()

  if (!locale) {
    return null
  }

  return (
    <Head>
      <script
        {...jsonLdScriptProps<BreadcrumbList>({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          '@id': absolutify('#breadcrumblist', locale as SiteLocale),
          itemListElement: breadcrumbs.map(({ title, href }, index) => ({
            '@type': 'ListItem',
            '@id': absolutify('#listItem', locale as SiteLocale),
            position: index + 1,
            name: title,
            item: {
              '@type': 'WebPage',
              '@id': absolutify(href, locale as SiteLocale),
            },
            ...(index > 0
              ? { previousItem: absolutify('#listItem', locale as SiteLocale) }
              : {}),
            ...(index < breadcrumbs.length - 1
              ? { nextItem: absolutify('#listItem', locale as SiteLocale) }
              : {}),
          })),
        })}
      />
    </Head>
  )
}

export default BreadcrumbsRichSnippet
