// This is the only place we want to use next/image as is correctly wraps next/image to handle/force the user to use relative cloudinary image urls but still support additional transforms via the loader infrastructure
// eslint-disable-next-line no-restricted-imports
import NextImage, { ImageLoader, ImageProps } from 'next/image'

import remakeUrl from '../utils/remakeUrl'

const imgixLoader =
  (aspectRatio: string | undefined): ImageLoader =>
  ({ src, width, quality }) => {
    if (src.endsWith('.svg')) {
      return src
    }

    const queryString: Record<string, string | number | undefined> = {
      auto: 'compress,format',
      w: width,
    }
    if (quality) {
      queryString.q = quality
    }
    if (aspectRatio) {
      queryString.fit = 'crop'
      // Note: convertDatoImage sets the focalpoint coordinates
      queryString.crop = 'focalpoint'
      queryString.ar = aspectRatio
    }

    return remakeUrl(src, queryString)
  }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rawLoader: ImageLoader = ({ src }) => src

interface Props extends Omit<ImageProps, 'loader'> {
  aspectRatio?: string // must be W:H (can be absurd values like 526:426)
}

const Image = ({ aspectRatio, ...others }: Props) => {
  const loader = imgixLoader(aspectRatio)

  // eslint-disable-next-line jsx-a11y/alt-text
  return <NextImage {...others} loader={loader} />
}

export default Image
