import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import { useState } from 'react'

import AgendaIcon from '../icons/Agenda.svg'
import AuthorIcon from '../icons/Author.svg'
import LinkedInIcon from '../icons/LinkedIn.svg'
import ExternalLinkIcon from '../icons/Link.svg'
import Link from '../components/Link'
import { ArticleDetailFragment } from '../graphql/article.generated'
import { usePageContext } from '../utils/PageContext'
import resolveUrlForRecord from '../utils/resolveUrlForRecord'
import { getHostname } from '../utils/env'
import { SiteLocale } from '../graphql/datoSchema.generated'

export const Section = styled.section(
  ({ theme }) => css`
    background: ${theme.colors.neutral1};
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x2}px;
    }
  `,
)
const Container = styled.div(
  ({ theme }) => css`
    margin: 0 auto;
    max-width: ${theme.spacing.gridMaxWidth}px;
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;
    display: flex;
    flex-flow: column;
    gap: ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      align-items: center;
      flex-flow: row;
      padding: ${theme.spacing.x5}px 0;
    }
  `,
)
const Metadata = styled.ul(
  ({ theme }) => css`
    flex: 1 1 auto;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
    }
  `,
)
const StyledAgenda = styled(AgendaIcon)`
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-top: -2px;
`
const AuthorLink = styled(Link)`
  color: inherit;
`
const StyledAuthor = styled(AuthorIcon)`
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-top: -2px;
`
const Share = styled.div`
  margin-top: -8px;
`
const Tooltip = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.neutral0};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
    right: 50%;
    margin-top: ${theme.spacing.x1}px;
    opacity: 0;
    padding: ${theme.spacing.x1}px;
    position: absolute;
    pointer-events: none;
    top: 100%;
    transition: opacity 100ms ease-in;
    transform: translateX(50%);
    white-space: nowrap;

    ::before {
      border-bottom: ${theme.spacing.x1}px solid ${theme.colors.neutral0};
      border-left: ${theme.spacing.x1}px solid transparent;
      border-right: ${theme.spacing.x1}px solid transparent;
      bottom: 100%;
      content: '';
      height: 0;
      right: 50%;
      margin-right: -${theme.spacing.x1}px;
      position: absolute;
      width: 0;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      right: 0;
      transform: translateX(0);

      ::before {
        right: 0;
        margin-right: ${theme.spacing.x1 + theme.spacing.x0}px;
      }
    }
  `,
)
const SocialLink = styled(Link)(
  ({ theme }) => css`
    display: inline-block;
    background: ${theme.colors.neutral0};
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: ${theme.spacing.x1}px;
    transform: translateY(4px);
    color: ${theme.colors.neutral4};
    position: relative;

    :hover {
      color: ${theme.colors.primaryPurple};

      ${Tooltip} {
        z-index: ${theme.zIndex.base};
        opacity: 1;
      }
    }
  `,
)

interface Props {
  article: ArticleDetailFragment
}

const ArticleMetadataSection = ({ article }: Props) => {
  const { siteConfig } = usePageContext()
  const { locale } = useRouter()
  const url = `${getHostname()}/${
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    siteConfig.newsIndex!.slug
  }/${article.slug}`

  const [copied, setCopied] = useState(false)

  return (
    <Section>
      <Container>
        <Metadata>
          <li>
            <StyledAgenda aria-label="Published at" />{' '}
            {new Date(article.publishedAt).toLocaleDateString(locale)}
          </li>
          {article.author &&
            // Experts are only available on the EMN site, but the site must not
            // crash if a content editor selects an expert on another site. This
            // check allows expert selections anywhere, and will simply fall-
            // back to a read-only variant on sites without expertsIndex set.
            (article.author.__typename === 'ExpertRecord' &&
            siteConfig.expertsIndex?.slug ? (
              <li>
                <AuthorLink
                  href={resolveUrlForRecord(
                    siteConfig,
                    article.author,
                    locale as SiteLocale,
                  )}
                >
                  <StyledAuthor /> {article.author.name}
                </AuthorLink>
              </li>
            ) : (
              <li>
                <StyledAuthor /> {article.author.name}
              </li>
            ))}
        </Metadata>
        <Share>
          {siteConfig.share}:{' '}
          <SocialLink
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
              url,
            )}`}
            target="_blank"
            rel="noopener noreferer"
          >
            <LinkedInIcon aria-label="LinkedIn" />
          </SocialLink>
          <SocialLink
            href={url}
            target="_blank"
            rel="noopener noreferer"
            onClick={(evt) => {
              evt.preventDefault()
              setCopied(true)
              navigator.clipboard.writeText(url)
            }}
          >
            <Tooltip>
              {copied ? siteConfig.afterCopy : siteConfig.beforeCopy}
            </Tooltip>
            <ExternalLinkIcon aria-label="Link" />
          </SocialLink>
        </Share>
      </Container>
    </Section>
  )
}

export default ArticleMetadataSection
