import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode, useEffect, useRef, useState } from 'react'

import ArrowLeft from '../icons/ArrowLeft.svg'
import Button, { primaryButtonCss } from './Button'
import CarouselPager from './CarouselPager'

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spacing.x4}px;
  `,
)
export { Container as CarouselContainer }
const DesktopControl = styled.div(
  ({ theme }) => css`
    flex: 0 0 auto;
    align-items: center;

    display: none;
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: flex;
    }
  `,
)
const NavigationButton = styled(Button)(({ theme }) => [
  primaryButtonCss(theme),
  css`
    margin: 0 ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0;
    }
  `,
])
const Slides = styled.div`
  flex: 1 1 auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  overflow-x: auto;
  display: flex;
  gap: 80px;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  > * {
    flex: 0 0 100%;
    max-width: 100%;
    overflow: hidden;
    scroll-snap-align: start;
    margin: 0;
  }
`
const ArrowRight = styled(ArrowLeft)`
  transform: rotate(180deg);
`
const StyledCarouselPager = styled(CarouselPager)(
  ({ theme }) => css`
    place-content: center;
    margin-top: ${theme.spacing.x5}px;

    display: none;
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: flex;
    }
  `,
)
const MobileCarouselPager = styled.div(
  ({ theme }) => css`
    text-align: center;
    margin-top: ${theme.spacing.x1}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: none;
    }
  `,
)

const decimalRound = (value: number, decimals: number) =>
  Math.round(value * 10 ** decimals) / 10 ** decimals

interface Props {
  slides: ReactNode[]
}

const CarouselSection = ({ slides }: Props) => {
  const [scrollX, setScrollX] = useState(0)
  const [scrollWidth, setScrollWidth] = useState(0)
  const [slideWidth, setSlideWidth] = useState(0)
  const slideContainerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const slideContainer = slideContainerRef.current
    if (!slideContainer) {
      return
    }

    const handleScroll = () => {
      setScrollX(slideContainer.scrollLeft)
      setScrollWidth(slideContainer.scrollWidth)
      setSlideWidth(slideContainer.offsetWidth)
    }
    handleScroll() // initialize

    slideContainer.addEventListener('scroll', handleScroll, {
      passive: true,
    })
    // eslint-disable-next-line consistent-return
    return () => slideContainer.removeEventListener('scroll', handleScroll)
  }, [])

  // Some maths just to get an accurate slide percentage while accounting for slide margins
  const slideMargin =
    (scrollWidth - slideWidth * slides.length) / (slides.length - 1)
  const lastSlideX =
    slideMargin * (slides.length - 1) + slideWidth * (slides.length - 1)

  const slidePercentage = decimalRound(
    (scrollX / lastSlideX) * (slides.length - 1),
    2,
  )
  const slide = Math.round(slidePercentage)

  const goToPage = (page: number) =>
    slideContainerRef.current?.scrollTo(page * slideWidth, 0)
  const handlePrevious = () => goToPage(slide - 1)
  const handleNext = () => goToPage(slide + 1)

  return (
    <>
      <Container>
        <DesktopControl>
          <NavigationButton disabled={slide === 0} onClick={handlePrevious}>
            <ArrowLeft aria-label="Previous" />
          </NavigationButton>
        </DesktopControl>
        <Slides ref={slideContainerRef}>{slides}</Slides>
        <DesktopControl>
          <NavigationButton
            disabled={slide === slides.length - 1}
            onClick={handleNext}
          >
            <ArrowRight aria-label="Next" />
          </NavigationButton>
        </DesktopControl>
      </Container>
      <StyledCarouselPager
        pages={slides.length}
        slidePercentage={slidePercentage}
        onClickPage={goToPage}
      />
      <MobileCarouselPager>
        <NavigationButton disabled={slide === 0} onClick={handlePrevious}>
          <ArrowLeft aria-label="Previous" />
        </NavigationButton>
        {slide + 1}/{slides.length}
        <NavigationButton
          disabled={slide === slides.length - 1}
          onClick={handleNext}
        >
          <ArrowRight aria-label="Next" />
        </NavigationButton>
      </MobileCarouselPager>
    </>
  )
}

export default CarouselSection
