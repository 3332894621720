import { FileField, FocalPoint, Maybe } from '../graphql/datoSchema.generated'
import remakeUrl from './remakeUrl'
import stripUndefined from './stripUndefined'

export type ImageField = Pick<FileField, 'url' | 'width' | 'height' | 'alt'> & {
  focalPoint?: Maybe<Pick<FocalPoint, 'x' | 'y'>>
}
function convertDatoImage(image: ImageField): ImageInfo
function convertDatoImage(
  image: Maybe<ImageField> | undefined,
): ImageInfo | undefined
function convertDatoImage(
  image: Maybe<ImageField> | undefined,
): ImageInfo | undefined {
  if (!image?.url) {
    return undefined
  }

  return {
    src: image.url.endsWith('.svg')
      ? image.url
      : remakeUrl(
          image.url,
          stripUndefined({
            'fp-x': image.focalPoint?.x,
            'fp-y': image.focalPoint?.y,
          }),
        ),
    alt:
      image.alt !== undefined && image.alt !== null && image.alt !== 'N/A'
        ? image.alt
        : undefined,
    width: image.width,
    height: image.height,
  }
}

export default convertDatoImage
