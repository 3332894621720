import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import Button, { primaryButtonCss } from '../components/Button'

import Image from '../components/Image'
import Link from '../components/Link'
import QuickHelpButton from '../components/QuickHelpButton'

const Section = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.primaryPurpleDark};
    color: ${theme.colors.neutral0};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x4}px;
    }
  `,
)
const Container = styled.div(
  ({ theme }) => css`
    margin: 0 auto;
    max-width: ${theme.spacing.gridMaxWidth}px;
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x10}px 0;
    }
  `,
)
const Main = styled.div(
  ({ theme }) => css`
    display: flex;
    border-bottom: 0.5px solid ${theme.colors.neutral0};
    padding-bottom: ${theme.spacing.x1}px;
    margin-bottom: ${theme.spacing.x5}px;
    flex-flow: column-reverse;
    gap: ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-flow: row;
    }
  `,
)
const Columns = styled.div(
  ({ theme }) => css`
    flex: 1 1 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  `,
)
const Column = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x4}px;
  `,
)
const ColumnTitle = styled.div(
  ({ theme }) => css`
    ${theme.text.headings(theme)}
    color: inherit;
    font-size: 16px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 20px;
    }
  `,
)
const ColumnLinks = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
const ColumnLink = styled(Link)(
  ({ theme }) => css`
    display: block;
    color: inherit;
    margin: ${theme.spacing.x3}px 0;
  `,
)
const CallToActionButton = styled(Button)(({ theme }) =>
  primaryButtonCss(theme),
)
const LogoImage = styled(Image)(
  ({ theme }) => css`
    margin-top: 4px;
    width: 90px;
    height: auto;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      width: 120px;
    }
  `,
)
const CopyrightRow = styled.div(
  ({ theme }) => css`
    margin: ${theme.spacing.x5}px 0;
    display: flex;
  `,
)
const CopyrightDesktop = styled.div(
  ({ theme }) => css`
    display: none;
    flex: 1 1 auto;
    place-self: center;
    font-weight: bold;
    font-size: 12px;
    color: ${theme.colors.neutral1};
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
    }
  `,
)
const CopyrightMobile = styled.div(
  ({ theme }) => css`
    font-size: 12px;
    color: ${theme.colors.neutral2};
    margin-top: ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: none;
    }
  `,
)
const SocialLinks = styled.div`
  flex: 0 0 auto;
`
const SocialLink = styled(Link)(
  ({ theme }) => css`
    display: inline-block;
    background: ${theme.colors.neutral0};
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: ${theme.spacing.x2}px;
    transform: translateY(4px);
  `,
)
const LegalLink = styled(Link)(
  ({ theme }) => css`
    font-size: 12px;
    color: inherit;
    display: block;
    margin-bottom: ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 13px;
      display: inline-block;
      margin-bottom: 0;

      :not(:last-of-type) {
        margin-right: ${theme.spacing.x4}px;
      }
    }
  `,
)

interface Props {
  columns: Array<
    | {
        key: string
        title: string
        links: Array<{
          key: string
          href: string
          target?: string
          text: string
        }>
      }
    | {
        key: string
        title: string
        text: ReactNode
        link:
          | {
              variant: 'button'
              href: string
              target?: string
              text: string
            }
          | {
              variant: 'quick-help'
              text: string
            }
      }
  >
  logo: ImageInfo
  copyright: string
  socialLabel: string
  socialLinks: Array<{
    key: string
    href: string
    target?: string
    text: string
    image: ImageInfo
  }>
  legalLinks: Array<{
    key: string
    href: string
    target?: string
    text: string
  }>
}

const Footer = ({
  columns,
  logo,
  copyright,
  socialLabel,
  socialLinks,
  legalLinks,
}: Props) => (
  <Section data-datocms-noindex>
    <Container>
      <Main>
        <Columns>
          {columns.map((column) => {
            if ('links' in column) {
              return (
                <Column key={column.key}>
                  <ColumnTitle>{column.title}</ColumnTitle>
                  <ColumnLinks>
                    {column.links.map(({ key, href, target, text }) => (
                      <li key={key}>
                        <ColumnLink href={href} target={target}>
                          {text}
                        </ColumnLink>
                      </li>
                    ))}
                  </ColumnLinks>
                </Column>
              )
            }

            return (
              <Column key={column.key}>
                <ColumnTitle>{column.title}</ColumnTitle>
                {column.text}
                {column.link.variant === 'button' && (
                  <CallToActionButton>{column.link.text}</CallToActionButton>
                )}
                {column.link.variant === 'quick-help' && (
                  <QuickHelpButton>{column.link.text}</QuickHelpButton>
                )}
              </Column>
            )
          })}
        </Columns>
        <div>
          <LogoImage
            src={logo}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            alt={logo.alt!}
          />
        </div>
      </Main>
      <CopyrightRow>
        <CopyrightDesktop>{copyright}</CopyrightDesktop>
        <SocialLinks>
          {socialLabel}:
          {socialLinks.map((social) => (
            <SocialLink
              key={social.key}
              href={social.href}
              target={social.target}
            >
              <Image src={social.image} alt={social.image.alt || social.text} />
            </SocialLink>
          ))}
        </SocialLinks>
      </CopyrightRow>
      {legalLinks.map(({ key, href, target, text }) => (
        <LegalLink key={key} href={href} target={target}>
          {text}
        </LegalLink>
      ))}

      <CopyrightMobile>{copyright}</CopyrightMobile>
    </Container>
  </Section>
)

export default Footer
