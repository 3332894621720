import { css } from '@emotion/react'
import styled from '@emotion/styled'

import ChevronRight from '../icons/ChevronRight.svg'
import Phone from '../icons/Phone.svg'
import Mail from '../icons/Mail.svg'
import LinkedIn from '../icons/LinkedIn.svg'
import { mediumButtonCss, outlineButtonCss } from './Button'
import Image from './Image'
import ImageAspectRatio from './ImageAspectRatio'
import Link from './Link'
import CommaSeparatedList from './CommaSeparatedList'
import { Site, currentSite } from '../sites'

const Container = styled.article(
  ({ theme }) => css`
    background: ${theme.colors.neutral0};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
    height: 100%;
    display: flex;
    flex-flow: column;
  `,
)
const Aside = styled.div(
  () => css`
    display: flex;
    flex: 0 0 32%;
    flex-direction: column;
  `,
)
const ContactInformation = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing.x2}px;
  `,
)
const ContactInfo = styled(Link)(
  ({ theme }) =>
    css`
      display: block;
      color: inherit;
      :not(:last-of-type) {
        margin-bottom: ${theme.spacing.x1 + theme.spacing.x0}px;
      }

      svg {
        height: 24px;
        margin-bottom: -2px;
        margin-right: ${theme.spacing.x1}px;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        :not(:last-of-type) {
          margin-bottom: ${theme.spacing.x1}px;
        }
      }
    `,
)
const Main = styled.div(
  ({ theme }) => css`
    flex: 1 1 auto;
    padding: ${theme.spacing.x2}px;
    display: flex;
    flex-flow: column;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x3}px;
    }
  `,
)
const Contents = styled.div(
  () => css`
    flex: 1 1 auto;
  `,
)
const Name = styled.h3(
  ({ theme }) => css`
    ${theme.text.heading3(theme)}
    margin-bottom: ${theme.spacing.x2}px;
  `,
)
const DataLabel = styled.strong(
  ({ theme }) => css`
    display: block;
    margin-bottom: ${theme.spacing.x1}px;
  `,
)
const TagsGrid = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing.x2}px;
    display: grid;
    gap: ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: ${theme.spacing.x3}px 0;
    }
  `,
)
const ReadMore = styled(Link)(
  ({ theme }) => css`
    ${outlineButtonCss(theme)}
    ${mediumButtonCss(theme)}
    align-self: flex-start;
    margin-top: ${theme.spacing.x5}px;
  `,
)
const StyledChevronRight = styled(ChevronRight)(
  ({ theme }) => css`
    margin-left: ${theme.spacing.x1}px;
    height: 24px;
    margin-bottom: -2px;
  `,
)

const ExpertImage = ({ photo, name }: Pick<Props, 'photo' | 'name'>) => {
  const { alt } = photo
  return (
    <ImageAspectRatio aspectRatio={398 / 360}>
      <Image
        src={photo}
        alt={alt || name}
        fill
        aspectRatio="360:398"
        sizes="100vw"
      />
    </ImageAspectRatio>
  )
}

interface Props {
  href: string
  name: string
  photo: ImageInfo
  phone?: string
  email?: string
  linkedIn?: string
  disciplines?: string[]
  specialisms?: string[]
  qualifications?: string
  linkedInProfileLabel: string
  qualificationsLabel?: string
  disciplinesLabel?: string
  specialismsLabel?: string
  countriesLabel?: string
  expertisesLabel?: string
  moreInformation: string
  expertise?: string[]
  countries?: string[]
}

const ExpertCard = ({
  href,
  name,
  photo,
  phone,
  email,
  linkedIn,
  disciplines,
  specialisms,
  qualifications,
  linkedInProfileLabel,
  qualificationsLabel,
  disciplinesLabel,
  specialismsLabel,
  countriesLabel,
  expertisesLabel,
  expertise,
  countries,
  moreInformation,
}: Props) => {
  return (
    <Container>
      <Aside>
        {currentSite === Site.EMN ? (
          <Link href={href}>
            <ExpertImage photo={photo} name={name} />
          </Link>
        ) : (
          <ExpertImage photo={photo} name={name} />
        )}
      </Aside>
      <Main>
        <Contents>
          {currentSite === Site.EMN ? (
            <Link href={href}>
              <Name>{name}</Name>
            </Link>
          ) : (
            <Name>{name}</Name>
          )}

          <TagsGrid>
            {qualifications && (
              <div>
                <DataLabel>{qualificationsLabel}</DataLabel>
                {qualifications}
              </div>
            )}
            {currentSite === Site.EMN && (
              <>
                {disciplines && disciplines?.length > 0 && (
                  <div>
                    <DataLabel>{disciplinesLabel}</DataLabel>
                    <CommaSeparatedList>{disciplines}</CommaSeparatedList>
                  </div>
                )}
                {specialisms && specialisms?.length > 0 && (
                  <div>
                    <DataLabel>{specialismsLabel}</DataLabel>
                    <CommaSeparatedList>{specialisms}</CommaSeparatedList>
                  </div>
                )}
              </>
            )}
            {currentSite === Site.CED && (
              <>
                {expertise && expertise?.length > 0 && (
                  <div>
                    <DataLabel>{expertisesLabel}</DataLabel>
                    <CommaSeparatedList>{expertise}</CommaSeparatedList>
                  </div>
                )}
                {countries && countries?.length > 0 && (
                  <div>
                    <DataLabel>{countriesLabel}</DataLabel>
                    <CommaSeparatedList>{countries}</CommaSeparatedList>
                  </div>
                )}
              </>
            )}
          </TagsGrid>
        </Contents>

        {(phone || email || linkedIn) && (
          <ContactInformation>
            {email && (
              <ContactInfo href={`mailto:${email}`}>
                <Mail aria-label="Email" />
                {email}
              </ContactInfo>
            )}
            {phone && (
              <ContactInfo href={`tel:${phone}`}>
                <Phone aria-label="Phone" />
                {phone}
              </ContactInfo>
            )}
            {linkedIn && (
              <ContactInfo href={linkedIn}>
                <LinkedIn aria-label="LinkedIn" />
                {linkedInProfileLabel}
              </ContactInfo>
            )}
          </ContactInformation>
        )}

        {currentSite === Site.EMN && (
          <ReadMore href={href}>
            {moreInformation}
            <StyledChevronRight />
          </ReadMore>
        )}
      </Main>
    </Container>
  )
}

export default ExpertCard
