import { ReactNode } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import SearchBar from '../components/SearchBar'
import { SearchResult } from '../pages/api/search'
import NoSearchResults from './NoSearchResults'
import reactStringReplace from '../utils/reactStringReplace'
import { usePageContext } from '../utils/PageContext'
import SearchResultCard from '../components/SearchResultCard'
import ButtonLink from '../components/ButtonLink'

const StyledSearchBar = styled(SearchBar)(
  ({ theme }) => css`
    padding: 0 ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0 auto;
      max-width: ${theme.spacing.gridMaxWidth + theme.spacing.x4}px;
      padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;
    }
  `,
)
const Section = styled.section(
  ({ theme }) =>
    css`
      background-color: ${theme.colors.neutral1};
      padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x10}px ${theme.spacing.x2}px;
      }
    `,
)
const Container = styled.div(
  ({ theme }) => css`
    max-width: ${theme.spacing.gridMaxWidth}px;
    margin: 0 auto;
  `,
)
const Title = styled.h1(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    margin-bottom: ${theme.spacing.x2}px;
  `,
)
const ResultsCount = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x5}px;
  `,
)
const ResultsList = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x5}px;
  `,
)
const SubTitle = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading3(theme)}
    margin-bottom: ${theme.spacing.x2}px;
  `,
)
const ViewAllExpertsLink = styled.div`
  display: flex;
  justify-content: flex-end;
`

interface Props {
  query: string
  results: SearchResult[]
  count: number
  noResultsTitle: string
  noResultsDescription: ReactNode
  titleLabel: string
  resultsCountLabel: string
  expertsTitleLabel?: string
  viewAllExpertsLabel?: string
  allResultsTitleLabel: string
  newsLabel: string
  pageLabel: string
}

const SearchSection = ({
  query,
  titleLabel,
  resultsCountLabel,
  results,
  count,
  expertsTitleLabel,
  viewAllExpertsLabel,
  allResultsTitleLabel,
  newsLabel,
  pageLabel,
  noResultsTitle,
  noResultsDescription,
}: Props) => {
  const { siteConfig } = usePageContext()
  const hasExperts = Boolean(siteConfig.expertsIndex)
  // We can't use SearchResultCard's getPageType here as that also marks the /experts index page as an experts page, while this shouldn't.
  const isExpertResult = (result: SearchResult) =>
    hasExperts && result.url.startsWith(`/${siteConfig.expertsIndex?.slug}/`)
  const experts = results.filter(isExpertResult).slice(0, 3)
  const otherResults = results.filter((result) => !isExpertResult(result))

  return (
    <>
      <StyledSearchBar initialValue={query} />
      <Section>
        {results.length > 0 ? (
          <Container>
            <Title>{titleLabel.replace('{query}', query)}</Title>
            <ResultsCount>
              {reactStringReplace(resultsCountLabel, {
                '{count}': <strong>{count}</strong>,
              })}
            </ResultsCount>
            {hasExperts && experts.length > 0 && (
              <ResultsList>
                {expertsTitleLabel && <SubTitle>{expertsTitleLabel}</SubTitle>}
                {experts.map(({ id, title, text, url }) => (
                  <SearchResultCard
                    key={id}
                    title={title}
                    text={text}
                    href={url}
                    expertsLabel={expertsTitleLabel}
                    newsLabel={newsLabel}
                    pageLabel={pageLabel}
                  />
                ))}
                <ViewAllExpertsLink>
                  <ButtonLink
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    href={siteConfig.expertsIndex!.slug!}
                    variant="textual"
                  >
                    {viewAllExpertsLabel}
                  </ButtonLink>
                </ViewAllExpertsLink>
              </ResultsList>
            )}
            <ResultsList>
              {hasExperts && experts.length > 0 && allResultsTitleLabel && (
                <SubTitle>{allResultsTitleLabel}</SubTitle>
              )}
              {otherResults.map(({ id, title, text, url }) => (
                <SearchResultCard
                  key={id}
                  title={title}
                  text={text}
                  href={url}
                  expertsLabel={expertsTitleLabel}
                  newsLabel={newsLabel}
                  pageLabel={pageLabel}
                />
              ))}
            </ResultsList>
          </Container>
        ) : (
          <NoSearchResults
            query={query}
            title={noResultsTitle}
            explanation={noResultsDescription}
          />
        )}
      </Section>
    </>
  )
}

export default SearchSection
