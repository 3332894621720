import { css } from '@emotion/react'
import styled from '@emotion/styled'

import ArrowLeft from '../icons/ArrowLeft.svg'
import { usePageContext } from '../utils/PageContext'
import ArticleType from './ArticleType'
import Image from './Image'
import ImageAspectRatio from './ImageAspectRatio'
import Link from './Link'

const StyledLink = styled(Link)`
  color: inherit;

  :hover {
    text-decoration: none;
  }
`
const Article = styled.article(
  ({ theme }) => css`
    background: ${theme.colors.neutral0};
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-flow: column;
    height: 100%;

    :hover {
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    }
  `,
)
const ArticleTitle = styled.h3(
  ({ theme }) => css`
    ${theme.text.heading3(theme)}
    margin: ${theme.spacing.x2}px 0;
  `,
)
const Contents = styled.div(
  ({ theme }) => css`
    flex: 1 1 auto;
    padding: ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x3}px;
    }
  `,
)
const ReadMore = styled.div(
  ({ theme }) => css`
    display: inline-block;
    font-weight: bold;
    padding: 0 ${theme.spacing.x2}px ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x3}px ${theme.spacing.x3}px
        ${theme.spacing.x3}px;
    }
  `,
)
const ArrowRight = styled(ArrowLeft)(
  ({ theme }) => css`
    transform: rotate(180deg);
    margin-left: ${theme.spacing.x1}px;
    height: 24px;
    margin-bottom: -3px;
    transition: transform 400ms ease-out;

    ${StyledLink}:hover & {
      transform: rotate(180deg) translateX(-4px);
    }
  `,
)

interface Props {
  href: string
  title: string
  type: string
  typeColor: string
  image?: ImageInfo
  brief: string
}

const NewsCard = ({ href, title, image, type, typeColor, brief }: Props) => {
  const { siteConfig } = usePageContext()

  return (
    <StyledLink href={href}>
      <Article>
        {image && (
          <ImageAspectRatio aspectRatio={202 / 360}>
            <Image
              src={image}
              alt={image.alt || title}
              sizes="(min-width: 768px) 33vw, 100vw"
              fill
              aspectRatio="360:202"
            />
          </ImageAspectRatio>
        )}
        <Contents>
          <ArticleType color={typeColor}>{type}</ArticleType>
          <ArticleTitle>{title}</ArticleTitle>
          <div>{brief}</div>
        </Contents>
        <ReadMore>
          {siteConfig.articleReadMore} <ArrowRight />
        </ReadMore>
      </Article>
    </StyledLink>
  )
}

export default NewsCard
