import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'
import Link from '../components/Link'
import ArrowRight from '../icons/Outline/Icon/Outline/Arrow/Right.svg'
import Image from '../components/Image'

const Title = styled.h2<{ showVisualLine: boolean }>(
  ({ theme, showVisualLine }) => [
    css`
      ${theme.text.heading2(theme)}
      font-size: 30px;
      margin-bottom: ${theme.spacing.x2}px;
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x3}px;
      }
    `,
    showVisualLine &&
      css`
        border-left: 5px solid;
        border-image: linear-gradient(
            to bottom,
            ${theme.colors.primaryPurple} 33.3%,
            ${theme.colors.blue} 33.3%,
            ${theme.colors.blue} 66.6%,
            ${theme.colors.green} 66.6%
          )
          1;
        padding-left: ${theme.spacing.x2}px;
        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          padding-left: ${theme.spacing.x3}px;
        }
      `,
  ],
)
const Text = styled.div(
  ({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x3}px;
    }
  `,
)
const Section = styled.section<{ fullWidth: boolean }>(
  ({ fullWidth, theme }) => css`
    background: ${theme.colors.neutral1};
    padding: 0 ${fullWidth ? 0 : theme.spacing.x2}px;
  `,
)
const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullWidth' && prop !== 'imageLocation',
})<{ fullWidth: boolean; imageLocation: string }>(
  ({ imageLocation, fullWidth, theme }) =>
    css`
      display: flex;
      flex-direction: ${imageLocation === 'left' ? 'column' : 'column-reverse'};
      max-width: ${fullWidth ? 'none' : `${theme.spacing.gridMaxWidth}px`};
      margin: 0 auto;
      padding: ${theme.spacing.x5}px 0;
      position: relative;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x10}px 0;
        flex-direction: ${imageLocation === 'left' ? 'row' : ' row-reverse'};
      }
    `,
)
const Content = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'variant' && prop !== 'fullWidth' && prop !== 'imageLocation',
})<{ fullWidth: boolean; imageLocation: string; variant: string }>(
  ({ fullWidth, imageLocation, theme, variant }) => [
    css`
      background: ${theme.colors.neutral0};
      padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        width: 50%;
        padding: ${theme.spacing.x10}px ${theme.spacing.x5}px;
      }
    `,
    variant === 'dark' &&
      css`
        background: ${theme.colors.primaryPurpleDark};
        color: ${theme.colors.neutral0};

        ${Title} {
          color: ${theme.colors.neutral0};
        }
      `,
    fullWidth &&
      css`
        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          align-items: center;
          display: flex;
          justify-content: ${imageLocation};
          padding: ${theme.spacing.x10}px ${theme.spacing.x5}px;
        }
      `,
  ],
)
const ImageWrapper = styled.div(
  ({ theme }) => css`
    height: 343px;
    position: relative;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      height: auto;
      width: 50%;
    }
  `,
)
const StyledImage = styled(Image)(
  () => css`
    height: 100%;
    width: 100%;
  `,
)
const StyledArrow = styled(ArrowRight)(
  ({ theme }) => css`
    margin-left: ${theme.spacing.x1}px;
    transition: margin-left 400ms ease-out;
  `,
)
const StyledLink = styled(Link)(
  ({ theme }) => css`
    color: inherit;
    display: block;
    font-weight: bold;
    line-height: 1.57;
    width: 100%;

    :hover {
      text-decoration: none;

      ${StyledArrow} {
        margin-left: ${theme.spacing.x1 + theme.spacing.x0}px;
      }
    }
  `,
)
const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<{ fullWidth: boolean }>(
  ({ fullWidth, theme }) =>
    fullWidth &&
    css`
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        width: 484px;
      }
    `,
)

interface Props extends Omit<ComponentProps<typeof Section>, 'title'> {
  fullWidth: boolean
  image?: ImageInfo
  imageLocation?: string
  showVisualLine?: boolean
  link?: {
    text?: string
    href: string
    target?: string
  }
  text: ReactNode
  title?: string
  variant?: string
}

const SplitImageSection = ({
  fullWidth,
  image,
  imageLocation = 'left',
  showVisualLine = false,
  link,
  text,
  title,
  variant = 'light',
  ...others
}: Props) => {
  if (!title || !image) {
    return null
  }

  return (
    <Section fullWidth={fullWidth} {...others}>
      <Container fullWidth={fullWidth} imageLocation={imageLocation}>
        <ImageWrapper>
          <StyledImage
            alt={image.alt || ''}
            aspectRatio="1:1"
            src={image}
            sizes="100vw"
            fill
          />
        </ImageWrapper>
        <Content
          fullWidth={fullWidth}
          imageLocation={imageLocation}
          variant={variant}
        >
          <Wrapper fullWidth={fullWidth}>
            <Title showVisualLine={showVisualLine}>{title}</Title>
            <Text>{text}</Text>
            {link && (
              <StyledLink href={link.href} target={link.target}>
                {link.text} <StyledArrow />
              </StyledLink>
            )}
          </Wrapper>
        </Content>
      </Container>
    </Section>
  )
}

export default SplitImageSection
