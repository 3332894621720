import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, useEffect, useRef } from 'react'

import Search from '../icons/Search.svg'
import { usePageContext } from '../utils/PageContext'
import Button, { invisibleButtonCss } from './Button'

const Bar = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.neutral0};
  `,
)
const Container = styled.div(
  ({ theme }) => css`
    max-width: ${theme.spacing.containerMaxWidth}px;
    margin: 0 auto;
    padding: ${theme.spacing.x2 + theme.spacing.x0}px 0;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x5}px 0;
    }
  `,
)
const Input = styled.input(
  ({ theme }) => css`
    border: 0;
    font-size: 16px;
    padding: ${theme.spacing.x1}px ${theme.spacing.x2}px;
    width: 100%;

    :focus {
      outline-color: ${theme.colors.primaryPurple};
      outline-offset: 0;
      outline-style: solid;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x2}px;
    }
  `,
)
const Submit = styled(Button)(({ theme }) => [
  invisibleButtonCss,
  css`
    cursor: pointer;
    padding: ${theme.spacing.x1 + theme.spacing.x0}px;

    :focus {
      outline-color: ${theme.colors.primaryPurple};
      outline-offset: 0;
      outline-style: solid;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x2}px;
    }
  `,
])
const SearchInputContainer = styled.div(
  ({ theme }) => css`
    border: 1px solid ${theme.colors.neutral2};
    display: flex;

    :hover {
      border-color: ${theme.colors.neutral3};
    }
  `,
)
const StyledSearch = styled(Search)(
  ({ theme }) =>
    css`
      width: 20px;
      height: 20px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        width: 24px;
        height: 24px;
      }
    `,
)

interface Props extends Omit<ComponentProps<typeof Bar>, 'children'> {
  close?: () => void
  initialValue?: string
}

const SearchBar = ({ close, initialValue, ...others }: Props) => {
  const { siteConfig } = usePageContext()
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    const input = inputRef.current
    if (!input) {
      return
    }

    input.focus()
  }, [])
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && close) {
        close()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
  }, [close])

  // TODO: onSubmit use next router instead of browser redirect (form submit probably doesn't pushState)

  return (
    <Bar {...others}>
      <Container>
        <form method="get" action="/search">
          <SearchInputContainer>
            <Input
              type="search"
              name="q"
              placeholder={siteConfig.searchPlaceholder || undefined}
              ref={inputRef}
              defaultValue={initialValue}
            />
            <Submit type="submit">
              <StyledSearch />
            </Submit>
          </SearchInputContainer>
        </form>
      </Container>
    </Bar>
  )
}

export default SearchBar
