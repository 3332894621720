import { PartnersSectionFragment } from '../graphql/partnersSectionFragment'
import PartnersSection from '../sections/PartnersSection'
import RichText from '../components/RichText'
import convertDatoImage from '../utils/convertDatoImage'

interface Props {
  content: PartnersSectionFragment
}

const PartnersSectionBlock = ({
  content: { text, title, content, anchor },
}: Props) => (
  <PartnersSection
    content={content.map(({ id, image }) => ({
      id,
      image: convertDatoImage(image),
    }))}
    text={<RichText text={text} />}
    title={title || undefined}
    id={anchor || undefined}
  />
)

export default PartnersSectionBlock
