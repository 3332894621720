import { resolveLinkWithText } from '../graphql/linkWithTextFragment.utils'
import ExpertisesOverviewSection from '../sections/ExpertisesOverviewSection'
import notEmpty from '../utils/notEmpty'
import { ExpertisesOverviewSectionFragment } from '../graphql/expertisesOverviewSectionFragment'
import { usePageContext } from '../utils/PageContext'

interface Props {
  content: ExpertisesOverviewSectionFragment
}

const ExpertisesOverviewSectionBlock = ({
  content: { title, disciplines, viewAll, anchor },
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()

  return (
    <ExpertisesOverviewSection
      title={title}
      expertises={disciplines
        .map((item) =>
          item.name
            ? {
                key: item.id,
                name: item.name,
                link: resolveLinkWithText(siteConfig, item.link[0]),
              }
            : undefined,
        )
        .filter(notEmpty)}
      viewAll={
        viewAll[0]?.text
          ? {
              ...resolveLinkWithText(siteConfig, viewAll[0]),
              text: viewAll[0].text,
            }
          : undefined
      }
      id={anchor || undefined}
      {...others}
    />
  )
}

export default ExpertisesOverviewSectionBlock
