import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'

import { SiteConfig } from '../graphql/siteConfig'
import ArrowLeft from '../icons/ArrowLeft.svg'
import { usePageContext } from '../utils/PageContext'
import reactStringReplace from '../utils/reactStringReplace'
import ArticleType from './ArticleType'
import Link from './Link'

const StyledLink = styled(Link)(
  () => css`
    color: inherit;

    :hover {
      text-decoration: none;
    }
  `,
)
const ResultCard = styled.article(
  ({ theme }) => css`
    padding: ${theme.spacing.x2}px;
    margin-bottom: ${theme.spacing.x3}px;
    background: ${theme.colors.neutral0};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
    transition: box-shadow ease-in-out 400ms;

    ${StyledLink}:hover & {
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x3}px;
    }
  `,
)
const PageType = styled(ArticleType)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x2}px;
  `,
)
const Title = styled.h3(
  ({ theme }) => css`
    ${theme.text.heading3(theme)}
    margin-bottom: ${theme.spacing.x2}px;
  `,
)
const Text = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x2}px;
    min-height: 60px;
  `,
)
const ReadMore = styled.div`
  font-weight: bold;
`
const ArrowRight = styled(ArrowLeft)(
  ({ theme }) => css`
    transform: rotate(180deg);
    margin-left: ${theme.spacing.x1}px;
    height: 24px;
    margin-bottom: -3px;
    transition: transform 400ms ease-out;

    ${StyledLink}:hover & {
      transform: rotate(180deg) translateX(-4px);
    }
  `,
)

const getPageType = (siteConfig: SiteConfig, url: string) => {
  if (
    siteConfig.expertsIndex?.slug &&
    url.startsWith(`/${siteConfig.expertsIndex?.slug}`) &&
    url !== `/${siteConfig.expertsIndex?.slug}` &&
    !url.includes('page')
  ) {
    return 'experts'
  }
  if (
    siteConfig.newsIndex?.slug &&
    url.startsWith(`/${siteConfig.newsIndex?.slug}`)
  ) {
    return 'news'
  }
  return 'page'
}
const removeHighlights = (string: string) =>
  string.replace(/\[h\](.+?)\[\/h\]/g, '$1')

const boldenHighlights = (string: string) => {
  const highlights: Array<string> = string.match(/\[h\](.+?)\[\/h\]/g) || []

  return reactStringReplace(
    string,
    highlights.reduce<{ [search: string]: ReactNode }>((obj, highlight) => {
      // eslint-disable-next-line no-param-reassign
      obj[highlight] = <strong>{removeHighlights(highlight)}</strong>

      return obj
    }, {}),
  )
}

interface Props {
  href: string
  title: string
  text: string
  expertsLabel?: string
  newsLabel: string
  pageLabel: string
}

const SearchResultCard = ({
  href,
  title,
  text,
  expertsLabel,
  newsLabel,
  pageLabel,
}: Props) => {
  const { siteConfig } = usePageContext()
  const type = getPageType(siteConfig, href)

  return (
    <StyledLink href={href}>
      <ResultCard>
        {type === 'experts' && (
          <PageType color="lightgray">{expertsLabel}</PageType>
        )}
        {type === 'news' && <PageType color="blue">{newsLabel}</PageType>}
        {type === 'page' && <PageType color="darkgray">{pageLabel}</PageType>}
        <Title>{title && removeHighlights(title)}</Title>
        <Text>{boldenHighlights(text)}</Text>
        <ReadMore>
          {siteConfig.articleReadMore}
          <ArrowRight />
        </ReadMore>
      </ResultCard>
    </StyledLink>
  )
}

export default SearchResultCard
