import styled from '@emotion/styled'
import { ReactNode } from 'react'
import AccordeonGroup from './AccordeonGroup'

const Container = styled.div``

interface Props {
  groups: Array<{
    key: string
    title: string
    text: ReactNode
  }>
}

const Accordeon = ({ groups, ...others }: Props) => (
  <Container {...others}>
    {groups.map(({ key, title, text }) => (
      <AccordeonGroup key={key} title={title}>
        {text}
      </AccordeonGroup>
    ))}
  </Container>
)

export default Accordeon
