import { stringify, parse } from 'querystring'

const remakeUrl = (
  url: string,
  appendQueryString: Parameters<typeof stringify>[0],
) => {
  const [baseUrl, baseQueryString] = url.split('?', 2)

  const newQueryString = {
    ...parse(baseQueryString),
    ...appendQueryString,
  }

  return `${baseUrl}?${stringify(newQueryString)}`
}

export default remakeUrl
