import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'

import Link from './Link'
import ChevronRight from '../icons/ChevronRight.svg'

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    color: ${theme.colors.primaryPurpleDark};
    gap: ${theme.spacing.x2}px;
  `,
)
const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`
const Page = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => [
  css`
    display: inline-block;
    margin-right: 4px;
    text-align: center;
    width: 40px;
    height: 40px;
    padding: ${theme.spacing.x1}px;
    font-weight: 500;

    :hover {
      background: ${theme.colors.neutral2};
    }
  `,
  active &&
    css`
      cursor: default;
      background: ${theme.colors.primaryPurpleDark};
      color: ${theme.colors.neutral0};

      :hover {
        background: ${theme.colors.primaryPurpleDark};
      }
    `,
])
const Rest = styled.div(
  ({ theme }) => css`
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
    padding: ${theme.spacing.x1}px;
  `,
)
const PageButton = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled?: boolean }>(({ disabled, theme }) => [
  css`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
      background: ${theme.colors.neutral2};
    }
  `,
  disabled &&
    css`
      opacity: 0.2;

      :hover {
        background: none;
      }
    `,
])
const ChevronPrevious = styled(ChevronRight)`
  transform: rotate(180deg);
  height: 24px;
`
const ChevronNext = styled(ChevronRight)`
  height: 24px;
`

interface Props extends Omit<ComponentProps<typeof Container>, 'children'> {
  currentPage: number
  numPages: number
  visiblePageCount?: number
  makeUrl: (page: number) => string
  previousPageLabel: string
  nextPageLabel: string
  goToPageLabel: string
}

const Pagination = ({
  numPages,
  currentPage,
  makeUrl,
  visiblePageCount = 3,
  previousPageLabel,
  nextPageLabel,
  goToPageLabel,
  ...others
}: Props) => {
  const clampedCurrentPage = Math.max(1, Math.min(numPages, currentPage))
  const visiblePages = [...Array(visiblePageCount)]
    .map(
      (_, index) =>
        clampedCurrentPage - Math.floor((visiblePageCount - 1) / 2) + index,
    )
    .filter((page) => page >= 1 && page <= numPages)

  const renderPageLink = (page: number) => (
    <StyledLink
      key={page}
      href={makeUrl(page)}
      data-testid={`pagination.page.${page}`}
      aria-label={goToPageLabel.replace('{page}', String(page))}
    >
      <Page active={currentPage === page}>{page}</Page>
    </StyledLink>
  )

  return (
    <Container {...others}>
      {currentPage <= 1 ? (
        <PageButton disabled data-testid="pagination.previous">
          <ChevronPrevious aira-label={previousPageLabel} />
        </PageButton>
      ) : (
        <StyledLink href={makeUrl(currentPage - 1)}>
          <PageButton data-testid="pagination.previous">
            <ChevronPrevious aira-label={previousPageLabel} />
          </PageButton>
        </StyledLink>
      )}

      {visiblePages[0] > 1 && (
        <>
          {renderPageLink(1)}
          {visiblePages[0] > 2 &&
            (visiblePages[0] === 3 ? renderPageLink(2) : <Rest>…</Rest>)}
        </>
      )}

      {visiblePages.map(renderPageLink)}

      {visiblePages[visiblePages.length - 1] < numPages && (
        <>
          {visiblePages[visiblePages.length - 1] < numPages - 1 &&
            (visiblePages[visiblePages.length - 1] === numPages - 2 ? (
              renderPageLink(numPages - 1)
            ) : (
              <Rest>…</Rest>
            ))}
          {renderPageLink(numPages)}
        </>
      )}

      {currentPage >= numPages ? (
        <PageButton disabled data-testid="pagination.next">
          <ChevronNext aria-label={nextPageLabel} />
        </PageButton>
      ) : (
        <StyledLink href={makeUrl(currentPage + 1)}>
          <PageButton data-testid="pagination.next">
            <ChevronNext aria-label={nextPageLabel} />
          </PageButton>
        </StyledLink>
      )}
    </Container>
  )
}

export default Pagination
