import { Fragment, ReactNode } from 'react'

import escapeRegex from './escapeRegex'

/**
 * Replaces a part of a string with a React element.
 */
const reactStringReplace = (
  string: string,
  search: { [search: string]: ReactNode },
): ReactNode => {
  if (!string) {
    return string
  }

  const parts = string.split(
    new RegExp(`(${Object.keys(search).map(escapeRegex).join('|')})`),
  )

  return (
    <>
      {parts.map((part, index) => {
        if (search[part] !== undefined) {
          // eslint-disable-next-line react/no-array-index-key
          return <Fragment key={index}>{search[part]}</Fragment>
        }

        return part
      })}
    </>
  )
}

export default reactStringReplace
