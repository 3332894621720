import blocks from '.'
import { Block } from '../components/BlockZone'
import CarouselSection from '../sections/CarouselSection'
import { CarouselSectionFragment } from '../graphql/carouselSectionFragment'

interface Props {
  content: CarouselSectionFragment
}

const CarouselSectionBlock = ({
  content: { slides, anchor },
  ...others
}: Props) => (
  <CarouselSection
    slides={slides.map((slide) => (
      <Block key={slide.id} components={blocks} content={slide} />
    ))}
    id={anchor || undefined}
    {...others}
  />
)

export default CarouselSectionBlock
