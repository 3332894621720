import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'

import { mediumButtonCss, primaryButtonCss } from '../components/Button'
import ChevronRight from '../icons/ChevronRight.svg'
import Link from '../components/Link'

const CallToAction = styled.div(({ theme }) => [
  primaryButtonCss(theme),
  css`
    align-self: flex-start;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      align-self: center;
      ${mediumButtonCss(theme)}

      svg {
        height: 24px;
        margin-bottom: -2px;
      }
    }
  `,
])
const Container = styled(Link)(
  ({ theme }) => css`
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-flow: column;
    gap: ${theme.spacing.x3}px;
    margin: 0 auto;
    max-width: 1128px;
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;

    :hover {
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
      text-decoration: none;

      ${CallToAction} {
        background: ${theme.colors.buttonPrimaryHoverBackground};
      }
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-flow: row;
      padding: ${theme.spacing.x10}px;
    }
  `,
)
const TitleContainer = styled.div`
  flex: 1 1 auto;
`
const Title = styled.h2(({ theme }) => [
  css`
    ${theme.text.heading2(theme)}
    ${theme.text.titleLine(theme)}
    border-left: 5px solid ${theme.colors.primaryPurple};
    padding-left: ${theme.spacing.x2}px;
    font-size: 30px;
    position: relative;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 34px;
      padding-left: ${theme.spacing.x3}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: 510px;
    }
  `,
])
const Section = styled('section', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{
  variant:
    | 'whiteOnGray'
    | 'whiteOnDark'
    | 'grayOnWhite'
    | 'grayOnDark'
    | 'darkOnWhite'
    | 'darkOnGray'
}>(({ theme, variant }) => [
  css`
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;
    background: ${theme.colors.neutral1};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x10}px ${theme.spacing.x2}px;
    }
  `,
  (variant === 'whiteOnDark' || variant === 'grayOnDark') &&
    css`
      background: ${theme.colors.primaryPurpleDark};
      color: ${theme.colors.neutral0};
    `,
  (variant === 'grayOnWhite' || variant === 'darkOnWhite') &&
    css`
      background: ${theme.colors.neutral0};
    `,
  (variant === 'whiteOnGray' || variant === 'whiteOnDark') &&
    css`
      ${Container} {
        background: ${theme.colors.neutral0};
      }
    `,
  (variant === 'grayOnWhite' || variant === 'grayOnDark') &&
    css`
      ${Container} {
        background: ${theme.colors.neutral1};
      }
    `,
  (variant === 'darkOnWhite' || variant === 'darkOnGray') &&
    css`
      ${Container} {
        background: ${theme.colors.primaryPurpleDark};
      }
      ${Title} {
        color: ${theme.colors.neutral0};
      }
    `,
])

interface Props
  extends Omit<ComponentProps<typeof Section>, 'title' | 'variant'> {
  title: string
  callToAction: {
    href: string
    target?: string
    text: string
  }
  variant:
    | 'whiteOnGray'
    | 'whiteOnDark'
    | 'grayOnWhite'
    | 'grayOnDark'
    | 'darkOnWhite'
    | 'darkOnGray'
}

const CallToActionBannerSection = ({
  title,
  callToAction,
  variant,
  ...others
}: Props) => (
  <Section variant={variant} {...others}>
    <Container href={callToAction.href} target={callToAction.target}>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      <CallToAction>
        {callToAction.text} <ChevronRight />
      </CallToAction>
    </Container>
  </Section>
)

export default CallToActionBannerSection
