import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { usePageContext } from '../utils/PageContext'
import { LinkWithTextFragment } from '../graphql/linkWithTextFragment'
import { resolveLinkWithText } from '../graphql/linkWithTextFragment.utils'
import convertDatoImage, { ImageField } from '../utils/convertDatoImage'
import Image from './Image'
import Link from './Link'

type MenuItemType = {
  description?: string
  href: string
  id: string
  image?: ImageField
  link?: ({ text: string } & LinkWithTextFragment)[]
  subMenu?: MenuItemType[]
  target?: string
  text: string
} & LinkWithTextFragment

interface Props {
  close: () => void
  menu: MenuItemType
}

const Heading = styled.li(
  ({ theme }) => css`
    ${theme.text.heading3(theme)}
    margin-bottom: ${theme.spacing.x3}px;
  `,
)
const Grid = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 2fr 1fr;
    height: 460px;
    padding: ${theme.spacing.x5}px 0;
  `,
)
const List = styled.ul(css`
  list-style: none;
  margin: 0;
  padding: 0;
`)
const ListItem = styled.li(
  ({ theme }) => css`
    display: inline-block;
    margin-bottom: ${theme.spacing.x3}px;
    padding-right: ${theme.spacing.x3}px;
    vertical-align: top;
    width: 50%;
  `,
)
const Content = styled.div(
  ({ theme }) => css`
    border-left: 1px solid ${theme.colors.neutral1};
    padding-left: ${theme.spacing.x3}px;
  `,
)
const StyledImage = styled(Image)(
  css`
    height: 222px;
    width: 100%;
  `,
)
const Description = styled.p(
  ({ theme }) => css`
    margin: ${theme.spacing.x3}px 0 0;
  `,
)
const LinkTitle = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: boolean }>(
  ({ theme, variant }) =>
    variant &&
    css`
      font-weight: bold;
      margin-bottom: ${theme.spacing.x1}px;
    `,
)
const LinkDescription = styled.div()
const ListLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.colors.neutral4};

    :hover {
      color: ${theme.colors.primaryPurple};
      text-decoration: none;

      ${LinkTitle} {
        text-decoration: underline;
      }
    }
  `,
)

const GridMenu = ({
  close,
  menu: { subMenu, description, image, text },
}: Props) => {
  const { siteConfig } = usePageContext()
  return (
    <Grid>
      <List>
        <Heading>{text}</Heading>
        {subMenu?.map(({ description, id, link, text }) => {
          if (!link?.[0]) return null
          const { href, target } = resolveLinkWithText(siteConfig, link[0])
          const variant = Boolean(description?.length)
          return (
            <ListItem key={id}>
              <ListLink
                href={href}
                target={target}
                aria-haspopup="dialog"
                onClick={() => {
                  if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur()
                    close()
                  }
                }}
              >
                <LinkTitle variant={variant}>{text}</LinkTitle>
                {variant && <LinkDescription>{description}</LinkDescription>}
              </ListLink>
            </ListItem>
          )
        })}
      </List>
      <Content>
        {image && (
          <StyledImage
            alt={image?.alt || ''}
            height="222"
            src={convertDatoImage(image)}
            width="394"
          />
        )}
        <Description>{description}</Description>
      </Content>
    </Grid>
  )
}

export default GridMenu
