import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import ChevronRight from '../icons/ChevronRight.svg'
import Button, {
  invisibleButtonCss,
  primaryButtonCss,
  secondaryButtonCss,
} from './Button'
import Image from './Image'
import Link from './Link'
import MenuItem from './MenuItem'
import LanguageSelector from '../sections/LanguageSelector'
import Chevron from '../icons/Outline/Icon/Outline/Chevron/Left.svg'
import Cross from '../icons/Cross.svg'
import { isModalOpenVar } from './QuickHelpModal'

const Header = styled.div(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.neutral1};
    display: flex;
    gap: ${theme.spacing.x2}px;
    position: relative;
  `,
)
const LogoLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'noLanguages',
})<{ noLanguages: boolean }>(
  ({ noLanguages, theme }) => css`
    flex: 1 1 auto;
    place-self: center;
    text-align: center;
    padding: ${theme.spacing.x2}px 0;
    margin-left: ${noLanguages ? '100px' : 0};
  `,
)
const LogoImage = styled(Image)(
  () => css`
    height: 33px;
    width: auto;
  `,
)
const CloseHamburgerMenuButton = styled(Button)(({ theme }) => [
  invisibleButtonCss,
  css`
    cursor: pointer;
    padding: ${theme.spacing.x0 * 5}px ${theme.spacing.x2}px;
    min-width: 100px;
    text-align: right;
    place-self: center;
  `,
])
const StyledCross = styled(Cross)`
  height: 24px;
  vertical-align: middle;
`
const BackButton = styled.button(
  ({ theme }) => css`
    border: none;
    cursor: pointer;
    flex: 1;
    font-size: 16px;
    font-weight: bold;
    padding: 22px ${theme.spacing.x1}px;
    text-align: left;
  `,
)
const ChevronIcon = styled(Chevron)(css``)
const Section = styled('aside', {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>(
  ({ isOpen, theme }) => css`
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    transform: ${isOpen ? 'translateX(0)' : 'translateX(-100%)'};
    transition: transform 300ms ease;
    transition-delay: ${isOpen ? '0ms' : '300ms'};
    width: 100%;
    z-index: ${theme.zIndex.modal};
    overflow: auto;

    ::before {
      background: rgba(0, 0, 0, 0.5);
      bottom: 0;
      content: '';
      cursor: pointer;
      left: 0;
      right: 0;
      top: 0;
      transition: opacity 300ms ease;
      transition-delay: ${isOpen ? '300ms' : '0ms'};
      opacity: ${isOpen ? 1 : 0};
      position: absolute;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: none;
    }
  `,
)
const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>(
  ({ isOpen, theme }) => css`
    background: ${theme.colors.neutral0};
    height: 100%;
    overflow: auto;
    position: relative;
    transform: ${isOpen ? 'translateX(0)' : 'translateX(-100%)'};
    transition: transform 300ms ease;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      max-width: 466px;
    }
  `,
)
const Content = styled('div', {
  shouldForwardProp: (prop) => prop !== 'depth',
})<{ depth: number }>(
  ({ depth, theme }) => css`
    padding: ${theme.spacing.x3}px 0;
    transform: ${depth ? `translate(-${depth * 100}%)` : `translate(0)`};
    transition: transform 400ms ease-out;
  `,
)
const MainMenu = styled.menu(
  ({ theme }) =>
    css`
      list-style: none;
      margin: 0 0 ${theme.spacing.x5}px;
      padding: 0;
    `,
)
const ButtonLink = styled(Link)<{ variant: 'primary' | 'secondary' }>(
  ({ theme, variant }) => [
    variant === 'secondary'
      ? [
          secondaryButtonCss(theme),
          css`
            :hover {
              background: ${theme.colors.neutral2};
              color: ${theme.colors.primaryPurpleDark};
            }
          `,
        ]
      : primaryButtonCss(theme),
    css`
      display: block;
      margin: ${theme.spacing.x1}px ${theme.spacing.x2}px;
    `,
  ],
)
const StyledChevronRight = styled(ChevronRight)`
  width: 20px;
  height: 20px;
  vertical-align: middle;
`
const IconLink = styled(Link)(
  ({ theme }) => css`
    display: block;
    margin: ${theme.spacing.x4}px ${theme.spacing.x2}px;
    color: inherit;
  `,
)
const IconImage = styled(Image)(
  ({ theme }) => css`
    margin-right: ${theme.spacing.x1}px;
  `,
)
const FooterLinks = styled.ul(
  ({ theme }) => css`
    list-style: none;
    margin: ${theme.spacing.x2}px;
    padding: 0;
    display: flex;
    gap: ${theme.spacing.x2}px;
    font-weight: 400;
  `,
)
const FooterLinkItem = styled.li(
  ({ theme }) => css`
    :not(:last-of-type) {
      ::after {
        content: '·';
        margin-left: ${theme.spacing.x2}px;
      }
    }
  `,
)
const FooterLink = styled(Link)`
  color: inherit;
`

type LinkType = {
  id: string
  href: string
  text: string
  target?: string
}

interface Props {
  links: Array<LinkType>
  buttonLinks: Array<LinkType & { variant: 'primary' | 'secondary' }>
  close: () => void
  iconLinks: Array<LinkType & { icon: ImageInfo }>
  footerLinks: Array<LinkType>
  isOpen: boolean
  locales: ComponentProps<typeof LanguageSelector>['options']
  logo?: ImageInfo
}

const initialState = {
  depth: 0,
  path: [{ id: 'root', name: 'root' }],
}

const HamburgerMenu = ({
  buttonLinks,
  close,
  iconLinks,
  footerLinks,
  links,
  isOpen,
  locales,
  logo,
}: Props) => {
  const [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = useState(false)
  const [activeMenu, setActiveMenu] = useState<{
    depth: number
    path: { id: string; name: string }[]
  }>(initialState)
  const { events } = useRouter()

  const handleMenuChange = (depth: number, id: string, name: string) =>
    setActiveMenu((state) => ({
      depth,
      path: state.path[depth]
        ? state.path.map((item, index) =>
            index === depth ? { id, name } : item,
          )
        : [...state.path, { id, name }],
    }))

  const closeMenu = useCallback(() => {
    isModalOpenVar(false)
    setActiveMenu(initialState)
    setIsLanguageSelectorOpen(false)
    close()
  }, [close])

  useEffect(() => {
    events.on('routeChangeStart', closeMenu)

    return () => {
      events.off('routeChangeStart', closeMenu)
    }
  }, [events, closeMenu])

  return (
    <Section isOpen={isOpen} onClick={closeMenu}>
      <Container isOpen={isOpen} onClick={(e) => e.stopPropagation()}>
        <Header>
          {activeMenu.depth === 0 && (
            <LanguageSelector
              isOpen={isLanguageSelectorOpen}
              options={locales}
              toggle={setIsLanguageSelectorOpen}
            />
          )}
          {activeMenu.depth > 0 && (
            <BackButton
              onClick={() =>
                setActiveMenu((state) => ({ ...state, depth: state.depth - 1 }))
              }
              type="button"
            >
              <ChevronIcon /> {activeMenu.path[activeMenu.depth].name}
            </BackButton>
          )}
          {logo && activeMenu.depth === 0 && (
            <LogoLink href="/" noLanguages={locales.length === 1}>
              <LogoImage
                src={logo}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                alt={logo.alt!}
                priority
                sizes={
                  logo.src.endsWith('.svg')
                    ? undefined
                    : '(min-width: 768px) 120px, 27px'
                }
              />
            </LogoLink>
          )}
          <CloseHamburgerMenuButton onClick={closeMenu}>
            <StyledCross aria-label="Close" />
          </CloseHamburgerMenuButton>
        </Header>
        <Content depth={activeMenu.depth}>
          <MainMenu>
            {links.map((link) => (
              <MenuItem
                active={activeMenu.path.map(({ id }) => id)}
                depth={0}
                item={link}
                key={link.id}
                onClick={handleMenuChange}
              />
            ))}
          </MainMenu>
          {buttonLinks.map(({ id, href, target, text, variant }) => (
            <ButtonLink key={id} href={href} target={target} variant={variant}>
              {text} <StyledChevronRight />
            </ButtonLink>
          ))}
          {iconLinks.map(({ id, href, target, text, icon }) => (
            <IconLink key={id} href={href} target={target}>
              <IconImage src={icon} alt="" />
              {text}
            </IconLink>
          ))}
          <FooterLinks>
            {footerLinks.map(({ id, href, target, text }) => (
              <FooterLinkItem key={id}>
                <FooterLink href={href} target={target}>
                  {text}
                </FooterLink>
              </FooterLinkItem>
            ))}
          </FooterLinks>
        </Content>
      </Container>
    </Section>
  )
}

export default HamburgerMenu
