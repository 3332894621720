import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode, useState } from 'react'
import AnimateHeight from 'react-animate-height'

import Button, { invisibleButtonCss } from '../components/Button'
import Warning from '../icons/Warning.svg'
import ChevronRight from '../icons/ChevronRight.svg'
import { useUserCountry } from '../utils/userCountry'

const Bar = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.primaryPurple};
    color: ${theme.colors.neutral0};
  `,
)
const Container = styled.div(
  ({ theme }) => css`
    max-width: ${theme.spacing.containerMaxWidth}px;
    max-width: 1280px;
    margin: 0 auto;
  `,
)
const ExpandButton = styled(Button)(({ theme }) => [
  invisibleButtonCss,
  css`
    cursor: pointer;
    display: flex;
    width: 100%;
    gap: ${theme.spacing.x2}px;
    padding: ${theme.spacing.x2}px;
    text-align: left;
    align-items: center;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x3}px ${theme.spacing.x2}px;
    }
  `,
])
const WarningIcon = styled(Warning)`
  flex: 0 0 auto;
  height: 20px;
`
const Text = styled.div(
  ({ theme }) =>
    css`
      ${theme.text.headings(theme)}
      color: inherit;
      flex: 1 1 auto;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: normal;

      svg {
        margin-right: 8px;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        font-size: 20px;
      }
    `,
)
const ChevronDown = styled(ChevronRight, {
  shouldForwardProp: (prop) => prop !== 'expand' && prop !== 'hasDetails',
})<{ expand?: boolean; hasDetails?: boolean }>(
  ({ theme, expand, hasDetails }) => [
    css`
      flex: 0 0 auto;
      transform: rotate(90deg);
      transition: transform 300ms ease-in-out;
      height: 30px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        height: 40px;
      }
    `,
    expand &&
      css`
        transform: rotate(270deg);
      `,
    !hasDetails &&
      css`
        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          display: none;
        }
      `,
  ],
)
const StyledAnimateHeight = styled(AnimateHeight)(
  ({ theme }) => css`
    margin-top: -${theme.spacing.x1}px;
    padding-bottom: ${theme.spacing.x1}px;
  `,
)
const Details = styled.div(
  ({ theme }) => css`
    padding: 0 ${theme.spacing.x2}px ${theme.spacing.x1}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x2}px ${theme.spacing.x2}px;
    }
  `,
)

interface Props {
  children: ReactNode
  countries?: string[]
  details?: ReactNode
}

const AlertBar = ({ children, details, countries }: Props) => {
  const [expand, setExpand] = useState(false)
  const userCountry = useUserCountry()

  if (countries && countries.length > 0) {
    if (!userCountry || !countries.includes(userCountry)) {
      return null
    }
  }

  return (
    <Bar data-datocms-noindex>
      <Container>
        <ExpandButton
          aria-label={expand ? 'Collapse' : 'Expand'}
          onClick={() => setExpand(!expand)}
        >
          <WarningIcon />
          <Text>{children}</Text>
          <ChevronDown expand={expand} hasDetails={details !== undefined} />
        </ExpandButton>
        {details && (
          <StyledAnimateHeight height={expand ? 'auto' : 0} duration={300}>
            <Details>{details}</Details>
          </StyledAnimateHeight>
        )}
      </Container>
    </Bar>
  )
}

export default AlertBar
