import { ComponentProps, ReactNode } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

import Image from '../components/Image'

const Section = styled.section(
  ({ theme }) => css`
    max-width: ${theme.spacing.gridMaxWidth}px;
    margin: 0 auto;
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x10}px ${theme.spacing.x2}px;
      text-align: center;
    }
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    font-size: 30px;
    margin-bottom: ${theme.spacing.x3}px;
  `,
)
const Description = styled.div(
  ({ theme }) =>
    css`
      line-height: 1.57;
      margin-bottom: ${theme.spacing.x3}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        display: inline-block;
        margin-bottom: ${theme.spacing.x5}px;
        width: 552px;
      }
    `,
)
const List = styled.ul(
  () =>
    css`
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style: none;
      padding: 0;
      margin: 0;
    `,
)

const ListItem = styled.li(
  ({ theme }) =>
    css`
      align-items: center;
      display: flex;
      flex-basis: 50%;
      filter: grayscale(1);
      height: 92px;
      justify-content: center;

      :hover {
        filter: none;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        flex-basis: 33.333%;
        height: 148px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        flex-basis: 25%;
      }
    `,
)

type Content = Array<{
  id: string
  image?: ImageInfo
}>
interface Props extends Omit<ComponentProps<typeof Section>, 'title'> {
  content: Content
  text: ReactNode
  title?: string
}

const MAX_DISPLAYED_PARTNERS = 8

const PartnersSection = ({ content, text, title, ...others }: Props) => {
  if (!title) {
    return null
  }

  const generateListOfRandomPartners = (partners: Content) => {
    const copiedArray = [...partners]
    const randomizedArray: Content = []
    partners.forEach(() => {
      const randomNumber = Math.floor(Math.random() * copiedArray.length)
      // Remove the item the item we are adding to the randomized array
      const splicedItem = copiedArray.splice(randomNumber, 1)[0]
      randomizedArray.push(splicedItem)
    })
    return randomizedArray
  }

  // We only want to show 8 random partner logo's
  const randomizedPartners = generateListOfRandomPartners(content).splice(
    0,
    MAX_DISPLAYED_PARTNERS,
  )

  return (
    <Section {...others}>
      <Title>{title}</Title>
      <Description>{text}</Description>
      <List>
        {randomizedPartners.map(({ id, image }) => {
          if (!image) return null
          return (
            <ListItem key={id}>
              <Image src={image} alt={image.alt || ''} width={153} />
            </ListItem>
          )
        })}
      </List>
    </Section>
  )
}

export default PartnersSection
