import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useState, ReactNode } from 'react'

import LanguageSelector from './LanguageSelector'
import { usePageContext } from '../utils/PageContext'
import { currentSite, Site } from '../sites'

const Bar = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.primaryPurpleDark};
    color: ${theme.colors.neutral0};

    display: none;
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: block;
    }
  `,
)
const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spacing.x2}px ${theme.spacing.x6}px;
    max-width: ${theme.spacing.containerMaxWidth}px;
    margin: 0 auto;
    font-size: 14px;
  `,
)
const Text = styled.div(
  ({ theme }) => css`
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    padding-left: ${theme.spacing.x2}px;

    a {
      color: inherit;
      font-weight: bold;
    }
  `,
)
const Links = styled.div(
  ({ theme }) => css`
    text-align: right;
    display: flex;
    gap: ${theme.spacing.x1}px;

    a {
      display: inline-block;
      padding: ${theme.spacing.x2}px;
      color: inherit;
    }
  `,
)

interface Props {
  text: ReactNode
  blocks: ReactNode
}

const TopBar = ({ text, blocks }: Props) => {
  const {
    siteConfig: { locales },
  } = usePageContext()
  const [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = useState(false)
  return (
    <Bar data-datocms-noindex>
      <Container>
        <Text>{text}</Text>
        <Links>
          <LanguageSelector
            isOpen={isLanguageSelectorOpen}
            options={locales}
            toggle={setIsLanguageSelectorOpen}
          />
          {[Site.CED, Site.SOS].includes(currentSite) && <div />}
          {blocks}
        </Links>
      </Container>
    </Bar>
  )
}

export default TopBar
