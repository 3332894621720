import { FunctionComponent } from 'react'
import dynamic from 'next/dynamic'

/* Normal Components */
import TextWithImageHeader from './TextWithImageHeader'
import TextWithImageSection from './TextWithImageSection'
import ExpertisesOverviewSection from './ExpertisesOverviewSection'
import QuickImageLinksSection from './QuickImageLinksSection'
import NewsFeedSection from './NewsFeedSection'
import CarouselSection from './CarouselSection'
import TopBar from './TopBar'
import NavigationBar from './NavigationBar'
import AlertBar from './AlertBar'
import Footer from './Footer'
import TextSection from './TextSection'
import CallToActionBannerSection from './CallToActionBannerSection'
import CardsSection from './CardsSection'
import { SiteLocale } from '../graphql/datoSchema.generated'
import NewsListingSection from './NewsListingSection'
import ExpertListingSection from './ExpertListingSection'
import SearchSection from './SearchSection'
import SplitImageSection from './SplitImageSection'
import PartnersSection from './PartnersSection'
import ContactTableSection from './ContactTableSection'
import LinksGridSection from './LinksGridSection'
import HighlightedServicesSection from './HighlightedServicesSection'
import FrequentlyAskedQuestionsSection from './FrequentlyAskedQuestionsSection'
import SosIntroductionSection from './SosIntroductionSection'

/* Dynamic Components */

/* eslint-disable @typescript-eslint/no-explicit-any */
const FormSection = dynamic<any>(() => import('./FormSection')) as AnyComponent
type AnyComponent = FunctionComponent<any>
/* eslint-enable @typescript-eslint/no-explicit-any */

const blocks: Record<string, AnyComponent> = {
  TextWithImageHeader,
  TextWithImageSection,
  ExpertisesOverviewSection,
  QuickImageLinksSection,
  NewsFeedSection,
  CarouselSection,
  TopBar,
  NavigationBar,
  AlertBar,
  Footer,
  TextSection,
  CallToActionBannerSection,
  CardsSection,
  NewsListingSection,
  ExpertListingSection,
  SearchSection,
  SplitImageSection,
  PartnersSection,
  ContactTableSection,
  FormSection,
  LinksGridSection,
  HighlightedServicesSection,
  FrequentlyAskedQuestionsSection,
  SosIntroductionSection,
}

export default blocks

export interface BlockGetStaticPropsContext<Data> {
  siteId: string
  locale: SiteLocale
  block: Data
}
