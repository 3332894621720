/* eslint-disable camelcase */
import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'
import { StructuredText } from 'react-datocms/structured-text'

import { AccordeonFragment } from '../graphql/accordeonFragment.generated'
import {
  ArticleRecord,
  ExpertRecord,
  PageRecord,
  QuickHelpButtonRecord,
} from '../graphql/datoSchema.generated'
import { ImageFragment } from '../graphql/imageFragment.generated'
import { LinkWithTextFragment } from '../graphql/linkWithTextFragment'
import { resolveLinkWithText } from '../graphql/linkWithTextFragment.utils'

import {
  LinkWithTextFragment_LinkWithTextExternalRecord_,
  LinkWithTextFragment_LinkWithTextInternalRecord_,
} from '../graphql/linkWithTextFragment.generated'
import convertDatoImage from '../utils/convertDatoImage'
import { IconWithTextFragment } from '../graphql/iconWithTextFragment'
import { usePageContext } from '../utils/PageContext'
import resolveUrlForRecord from '../utils/resolveUrlForRecord'
import Accordeon from './Accordeon'
import ButtonLink from './ButtonLink'
import Image from './Image'
import Link from './Link'
import IconWithText from './IconWithText'
import MissingBlockComponent from './MissingBlockComponent'
import { ButtonLinkFragment } from '../graphql/buttonLinkFragment'
import { QuickHelpButtonFragment } from '../graphql/quickHelpButtonFragment.generated'
import QuickHelpButton from './QuickHelpButton'

// Will be a BC API to be used to override certain styles (e.g. remove margin
// from last p)
export const richTextClassName = 'rich-text'

// Adding a "variant" prop to the RichText component would be weird since
// the RichText component is only used in the "blocks" and the blocks don't
// do any styling and are supposed to be unaware of where or how its content is
// used. This css classes gives the section full control instead.
export const darkBackgroundVariantCss = (theme: Theme) => css`
  .${richTextClassName} {
    color: ${theme.colors.neutral0};

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    li::marker {
      color: ${theme.colors.neutral0};
    }
  }
`

const Container = styled.div(({ theme }) => [
  css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:not(:first-child) {
        margin-top: ${theme.spacing.x5}px;
      }

      & + p {
        margin-top: 0;
      }
    }
    h1 {
      ${theme.text.heading1(theme)}
    }
    h2 {
      ${theme.text.heading2(theme)}
    }
    h3 {
      ${theme.text.heading3(theme)}
    }
    h4 {
      ${theme.text.heading4(theme)}
    }
    h5 {
      ${theme.text.heading5(theme)}
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: ${theme.spacing.x1}px;
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x2}px;
      }
    }
    ul,
    ol {
      margin: ${theme.spacing.x5}px 0;
      padding-left: ${theme.spacing.x4}px;
      li {
        padding-left: ${theme.spacing.x1}px;

        ::marker {
          color: ${theme.colors.primaryPurple};
        }
      }
      li > p:first-child {
        margin-top: 0;
      }
      li > p:last-child {
        margin-bottom: 0;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }
  `,
])

type BlockRecords = {
  id: string
  __typename: string
} & (
  | LinkWithTextFragment
  | AccordeonFragment
  | {
      image: ImageFragment
    }
  | IconWithTextFragment
  | ButtonLinkFragment
  | QuickHelpButtonFragment
)

type LinkRecords = {
  id: string
  __typename: string
} & (
  | Pick<PageRecord, '__typename' | 'slug'>
  | Pick<ArticleRecord, '__typename' | 'slug'>
  | Pick<ExpertRecord, '__typename' | 'name'>
)

interface Props extends Omit<ComponentProps<typeof Container>, 'children'> {
  text: ComponentProps<typeof StructuredText<BlockRecords, LinkRecords>>['data']
}

const RichText = ({ text, ...others }: Props) => {
  const { siteConfig } = usePageContext()

  return (
    <Container className={richTextClassName} {...others}>
      <StructuredText<BlockRecords, LinkRecords>
        data={text}
        renderBlock={({ record }) => {
          switch (record.__typename) {
            case 'IconWithTextRecord': {
              const { title, icon } = record as IconWithTextFragment
              return (
                <IconWithText
                  icon={convertDatoImage(icon)}
                  title={title}
                  size={20}
                />
              )
            }
            case 'LinkWithTextInternalRecord':
            case 'LinkWithTextExternalRecord': {
              const typedRecord = record as
                | LinkWithTextFragment_LinkWithTextExternalRecord_
                | LinkWithTextFragment_LinkWithTextInternalRecord_

              return (
                <ButtonLink
                  key={record.id}
                  {...resolveLinkWithText(siteConfig, typedRecord)}
                  variant="textual"
                >
                  {typedRecord.text}
                </ButtonLink>
              )
            }
            case 'AccordeonRecord': {
              const typedRecord = record as AccordeonFragment
              return (
                <Accordeon
                  groups={typedRecord.groups.map(({ id, title, text }) => ({
                    key: id,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    title: title!,
                    text: <RichText text={text} />,
                  }))}
                />
              )
            }
            case 'ImageRecord': {
              const typedRecord = record as {
                image: ImageFragment
              }

              return (
                <div>
                  <Image
                    src={convertDatoImage(typedRecord.image)}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    alt={typedRecord.image.alt!}
                  />
                </div>
              )
            }
            case 'ButtonLinkRecord': {
              const typedRecord = record as ButtonLinkFragment
              return (
                <ButtonLink
                  {...resolveLinkWithText(siteConfig, typedRecord.link[0])}
                  variant={
                    typedRecord.variant as 'primary' | 'secondary' | 'inverted'
                  }
                >
                  {typedRecord.link[0].text}
                </ButtonLink>
              )
            }
            case 'QuickHelpButtonRecord': {
              const typedRecord = record as QuickHelpButtonRecord
              return <QuickHelpButton>{typedRecord.title}</QuickHelpButton>
            }
            default:
              return (
                <MissingBlockComponent name={record.__typename as string} />
              )
          }
        }}
        renderLinkToRecord={({ record, children }) => (
          <Link href={resolveUrlForRecord(siteConfig, record)}>{children}</Link>
        )}
        // We can't disable this in DatoCMS but we can't really make it do
        // anything useful either.
        renderInlineRecord={() => null}
      />
    </Container>
  )
}

export default RichText
