import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, MouseEvent, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import Button, { invisibleButtonCss } from '../components/Button'
import Image from '../components/Image'
import Link from '../components/Link'
import Hamburger from '../icons/Hamburger.svg'
import CloseIcon from '../icons/Cross.svg'
import SearchIcon from '../icons/Search.svg'
import convertDatoImage from '../utils/convertDatoImage'
import { usePageContext } from '../utils/PageContext'
import SearchBar from '../components/SearchBar'
import LanguageSelector from './LanguageSelector'
import HamburgerMenu from '../components/HamburgerMenu'
import Menu from '../components/Menu'
import QuickHelpButton from '../components/QuickHelpButton'
import QuickHelpModal from '../components/QuickHelpModal'
import { makeVar, useReactiveVar } from '../utils/reactiveVar'

export const isSearchOpenVar = makeVar(false)

const StyledSearchIcon = styled(SearchIcon)(
  ({ theme }) => css`
    height: 24px;
    vertical-align: middle;
    place-self: center;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      min-width: auto;
    }
  `,
)
const StyledClose = styled(CloseIcon)(
  ({ theme }) => css`
    height: 24px;
    vertical-align: middle;
    place-self: center;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      min-width: auto;
    }
  `,
)
const Bar = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.neutral0};
    border-bottom: 1px solid ${theme.colors.neutral1};
    position: relative;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      position: static;
      padding: 0 ${theme.spacing.x2}px;
    }
  `,
)
const Container = styled.div(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing.x2}px;
    max-width: ${theme.spacing.containerMaxWidth}px;
    margin: 0 auto;
    padding: 0;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: 16px;
    }
  `,
)
const HamburgerMenuButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isMobileMenuOpen',
})<{ isMobileMenuOpen?: boolean }>(({ theme, isMobileMenuOpen }) => [
  invisibleButtonCss,
  css`
    cursor: pointer;
    padding-left: ${theme.spacing.x2}px;
    min-width: 100px;
    text-align: left;
    transition: opacity 400ms ease-in-out;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: none;
    }
  `,
  isMobileMenuOpen &&
    css`
      opacity: 0;
    `,
])
const StyledHamburger = styled(Hamburger)(
  ({ theme }) => css`
    width: 24px;
    height: 24px;
    margin-right: ${theme.spacing.x1}px;
    vertical-align: middle;
    margin-top: -2px;
  `,
)
const LogoContainer = styled.div(
  ({ theme }) => css`
    flex: 1 1 auto;
    place-self: center;
    text-align: center;
    padding: ${theme.spacing.x2}px 0;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      text-align: left;
      padding: ${theme.spacing.x4}px ${theme.spacing.x2}px ${theme.spacing.x4}px
        0;
    }
  `,
)
const LogoImage = styled(Image)(
  ({ theme }) => css`
    height: 33px;
    width: auto;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      height: 40px;
    }
  `,
)
const MenuLink = styled(Link)(
  ({ theme }) => css`
    display: block;
    padding: ${theme.spacing.x2}px;
    color: inherit;
  `,
)
const MobileSearch = styled(MenuLink, {
  shouldForwardProp: (prop) => prop !== 'isHidden',
})<{ isHidden?: boolean }>(({ theme, isHidden }) => [
  css`
    min-width: 100px;
    text-align: right;
    place-self: center;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: none;
    }
  `,
  isHidden &&
    css`
      pointer-events: none;
      z-index: ${theme.zIndex.behindSiblings1};
    `,
])
const StyledSearchBar = styled(SearchBar)(
  ({ theme }) => css`
    padding: 0 ${theme.spacing.x2}px;
    position: absolute;
    width: 100%;
    z-index: ${theme.zIndex.behindSiblings1};

    &.slide-in-enter {
      transform: translateY(-100%);
    }
    &.slide-in-enter-active {
      transform: translateY(0%);
      transition: transform 400ms ease-in-out;
    }
    &.slide-in-exit {
      transform: translateY(0%);
    }
    &.slide-in-exit-active {
      transform: translateY(-100%);
      transition: transform 400ms ease-in-out;
    }
  `,
)
const Backdrop = styled.div(
  ({ theme }) => css`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: ${theme.zIndex.behindSiblings2};
    background: rgba(0, 0, 0, 0.5);

    &.fade-in-enter {
      opacity: 0;
    }
    &.fade-in-enter-active {
      opacity: 1;
      transition: opacity 400ms ease-in-out;
    }
    &.fade-in-exit {
      opacity: 1;
    }
    &.fade-in-exit-active {
      opacity: 0;
      transition: opacity 400ms ease-in-out;
    }
  `,
)
const MobileHelpButton = styled.div(
  ({ theme }) =>
    css`
      button {
        width: 100%;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        display: none;
      }
    `,
)
const DesktopHelpButton = styled.div(
  ({ theme }) =>
    css`
      display: none;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        display: inline-block;
      }
    `,
)

interface Props {
  buttonLinks: ComponentProps<typeof HamburgerMenu>['buttonLinks']
  iconLinks: ComponentProps<typeof HamburgerMenu>['iconLinks']
  footerLinks: ComponentProps<typeof HamburgerMenu>['footerLinks']
  links: ComponentProps<typeof HamburgerMenu>['links']
  locales: ComponentProps<typeof LanguageSelector>['options']
  quickHelpButton?: ComponentProps<typeof QuickHelpButton>['children']
  quickHelpModal?: {
    title: ComponentProps<typeof QuickHelpModal>['title']
    text: ComponentProps<typeof QuickHelpModal>['text']
  }
}

const NavigationBar = ({
  buttonLinks,
  iconLinks,
  footerLinks,
  links,
  locales,
  quickHelpButton,
  quickHelpModal,
}: Props) => {
  const { siteConfig, hideSearchMobile } = usePageContext()
  const logo = convertDatoImage(siteConfig.logo)

  const isSearchOpen = useReactiveVar(isSearchOpenVar)
  const handleSearchClick = (e: MouseEvent) => {
    e.preventDefault()

    isSearchOpenVar(!isSearchOpen)
  }
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  return (
    <>
      <Bar data-datocms-noindex>
        {quickHelpButton && (
          <MobileHelpButton>
            <QuickHelpButton>{quickHelpButton}</QuickHelpButton>
          </MobileHelpButton>
        )}
        <Container>
          <HamburgerMenuButton
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            isMobileMenuOpen={isMobileMenuOpen}
          >
            <StyledHamburger />
            Menu
          </HamburgerMenuButton>
          {logo && (
            <LogoContainer>
              <Link href="/">
                <LogoImage
                  src={logo}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  alt={logo.alt!}
                  priority
                  sizes={
                    logo.src.endsWith('.svg')
                      ? undefined
                      : '(min-width: 768px) 120px, 27px'
                  }
                />
              </Link>
            </LogoContainer>
          )}
          <MobileSearch
            href="/search"
            onClick={handleSearchClick}
            isHidden={hideSearchMobile}
          >
            {isSearchOpen ? (
              <StyledClose aria-label="Search close" />
            ) : (
              <StyledSearchIcon aria-label="Search" />
            )}
          </MobileSearch>
          <Menu links={links} handleSearchClick={handleSearchClick} />
          {quickHelpButton && (
            <DesktopHelpButton>
              <QuickHelpButton>{quickHelpButton}</QuickHelpButton>
            </DesktopHelpButton>
          )}
          {quickHelpModal && <QuickHelpModal {...quickHelpModal} />}
        </Container>
      </Bar>

      <TransitionGroup component={null}>
        {isSearchOpen && (
          <CSSTransition timeout={400} classNames="slide-in">
            <StyledSearchBar close={() => isSearchOpenVar(false)} />
          </CSSTransition>
        )}
        {isSearchOpen && (
          <CSSTransition timeout={400} classNames="fade-in">
            <Backdrop onClick={() => isSearchOpenVar(false)} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <HamburgerMenu
        buttonLinks={buttonLinks}
        close={() => setIsMobileMenuOpen(false)}
        iconLinks={iconLinks}
        footerLinks={footerLinks}
        links={links}
        isOpen={isMobileMenuOpen}
        locales={locales}
        logo={logo}
      />
    </>
  )
}

export default NavigationBar
