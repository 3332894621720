import { ComponentProps } from 'react'

import RichText from '../components/RichText'
import HighlightedServicesSection from '../sections/HighlightedServicesSection'
import { HighlightedServicesSectionFragment } from '../graphql/highlightedServicesSectionFragment'
import { resolveLinkWithText } from '../graphql/linkWithTextFragment.utils'
import { usePageContext } from '../utils/PageContext'
import convertDatoImage from '../utils/convertDatoImage'

interface Props {
  content: HighlightedServicesSectionFragment
}

const HighlightedServicesSectionBlock = ({
  content: { image, links, text, title, viewAll, viewAllDisplayPosition },
}: Props) => {
  const { siteConfig } = usePageContext()
  return (
    <HighlightedServicesSection
      image={convertDatoImage(image)}
      links={links.map((item) =>
        item.__typename === 'ServicesMenuRecord'
          ? {
              id: item.id,
              links: item.links.map((link) => ({
                id: link.id,
                ...resolveLinkWithText(siteConfig, link),
                text: link.text,
              })),
              image: convertDatoImage(item.image),
              title: item.title,
            }
          : {
              id: item.id,
              ...resolveLinkWithText(siteConfig, item),
              text: item.text,
            },
      )}
      title={title}
      text={<RichText text={text} />}
      viewAll={
        viewAll.length > 0
          ? {
              id: viewAll[0].id,
              ...resolveLinkWithText(siteConfig, viewAll[0]),
              text: viewAll[0].text,
            }
          : undefined
      }
      viewAllDisplayPosition={
        viewAllDisplayPosition as ComponentProps<
          typeof HighlightedServicesSection
        >['viewAllDisplayPosition']
      }
    />
  )
}

export default HighlightedServicesSectionBlock
