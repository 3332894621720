import { css } from '@emotion/react'
import styled from '@emotion/styled'

const ArticleType = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color: string }>(({ theme, color }) => [
  css`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    padding: ${theme.spacing.x1}px ${theme.spacing.x0 + theme.spacing.x1}px;
    color: ${theme.colors.neutral0};
    background: ${theme.colors.primaryPurple};
    display: inline-block;
  `,
  color === 'green' &&
    css`
      background: ${theme.colors.green};
    `,
  color === 'blue' &&
    css`
      background: ${theme.colors.blue};
    `,
  color === 'lightgray' &&
    css`
      background: ${theme.colors.neutral1};
      color: ${theme.colors.neutral4};
    `,
  color === 'darkgray' &&
    css`
      background: ${theme.colors.neutral3};
    `,
])

export default ArticleType
