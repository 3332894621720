import resolveUrlForRecord from '../utils/resolveUrlForRecord'
import { LinkWithTextAndImageFragment } from './linkWithTextAndImageFragment.generated'
import { SiteConfig } from './siteConfig'

const fallback = {
  href: '',
}
export const resolveLinkWithTextAndImage = (
  siteConfig: SiteConfig,
  link: LinkWithTextAndImageFragment,
) => {
  switch (link.__typename) {
    case 'LinkWithTextAndImageExternalRecord':
      return {
        href: link.url || '',
        target: link.openInNewTab ? ('_blank' as const) : undefined,
      }
    case 'LinkWithTextAndImageInternalRecord': {
      const path = link.page
        ? resolveUrlForRecord(siteConfig, link.page)
        : undefined

      return path
        ? {
            href: link.sectionAnchor ? `${path}#${link.sectionAnchor}` : path,
          }
        : fallback
    }
    default:
      return fallback
  }
}
