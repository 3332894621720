/* eslint-disable jsx-a11y/label-has-associated-control */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Fragment, ReactNode, useState } from 'react'

import Link from '../components/Link'
import ChevronRight from '../icons/ChevronRight.svg'
import Location from '../icons/Location.svg'
import Mail from '../icons/Mail.svg'
import Phone from '../icons/Phone.svg'

const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    margin-bottom: ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x3}px;
    }
  `,
)
const Text = styled.div(
  ({ theme }) => css`
    line-height: 1.57;
    margin-bottom: ${theme.spacing.x5}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      line-height: 1.5;
    }
  `,
)
const Section = styled.section(
  ({ theme }) => css`
    background: ${theme.colors.neutral1};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x2}px;
    }
  `,
)
const Input = styled.input(
  css`
    display: none;
  `,
)
const ToggleButton = styled.label(
  ({ theme }) =>
    css`
      background: transparent;
      border: 1px solid ${theme.colors.primaryPurple};
      color: ${theme.colors.primaryPurple};
      cursor: pointer;
      display: none;
      margin-bottom: ${theme.spacing.x5}px;
      font-weight: bold;
      line-height: 1.5;
      padding: ${theme.spacing.x2}px;
      transition: background 200ms ease-in;

      :hover {
        background: ${theme.colors.primaryPurple};
        color: ${theme.colors.neutral0};
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        display: inline-block;
      }
    `,
)
const TableHeaders = styled.ul(
  ({ theme }) => css`
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: table-row;
    }
  `,
)
const TableHeader = styled.li(
  ({ theme }) => css`
    background: ${theme.colors.primaryPurple};
    border: 1px solid ${theme.colors.neutral0};
    color: ${theme.colors.neutral0};
    font-weight: bold;
    display: table-cell;
    padding: ${theme.spacing.x2}px;
    vertical-align: middle;
    white-space: nowrap;
  `,
)
const TableRow = styled('ul', {
  shouldForwardProp: (prop) => prop !== 'hasHeader',
})<{ hasHeader?: boolean }>(
  ({ hasHeader, theme }) => css`
    list-style: none;
    margin: ${hasHeader ? '40px 0 0' : 0};
    overflow: hidden;
    padding: 0;
    max-height: ${hasHeader ? 99 : 54}px;
    transition: all 400ms ease-in-out;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: table-row;
      margin: 0;
    }
  `,
)
const Icon = styled.span(
  ({ theme }) => css`
    margin-right: ${theme.spacing.x1}px;
  `,
)
const TableCell = styled.li(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.neutral2};
    display: flex;
    line-height: 1.57;
    &:not(:nth-of-type(2)) {
      background: ${theme.colors.neutral0};
      border: 0;
      padding: ${theme.spacing.x1}px ${theme.spacing.x2}px;
    }
    &:nth-of-type(3) {
      margin-top: ${theme.spacing.x2}px;
      padding: ${theme.spacing.x2}px ${theme.spacing.x2}px ${theme.spacing.x1}px;
    }
    &:nth-of-type(5) {
      margin-bottom: ${theme.spacing.x2}px;
      padding: ${theme.spacing.x1}px ${theme.spacing.x2}px ${theme.spacing.x2}px;
    }
    &:first-of-type {
      background: transparent;
      border: 0;
      padding: 0;
    }
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      background: ${theme.colors.neutral0};
      border-bottom: 0;
      display: table-cell;
      padding: ${theme.spacing.x2}px;
      vertical-align: middle;
      &:first-of-type {
        background: ${theme.colors.primaryPurpleExtraLight};
        border: 1px solid ${theme.colors.neutral0};
        padding: ${theme.spacing.x2}px;
      }
      &:not(:nth-of-type(2)),
      &:nth-of-type(3),
      &:nth-of-type(5),
      &:first-of-type {
        padding: ${theme.spacing.x2}px;
        line-height: 1.5;

        ${Icon} {
          display: none;
        }
      }
    }
  `,
)
const RowHeader = styled.div(
  ({ theme }) => css`
    ${theme.text.heading3(theme)}
    margin-bottom: ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      color: ${theme.colors.neutral4};
      font-size: 16px;
      letter-spacing: normal;
      margin-bottom: 0;
    }
  `,
)
const Label = styled.label(
  ({ theme }) => css`
    ${theme.text.heading4(theme)}
    align-items: center;
    color: ${theme.colors.neutral4};
    cursor: pointer;
    display: flex;
    padding: ${theme.spacing.x1}px 0;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      cursor: inherit;
      font-family: inherit;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      letter-spacing: normal;
      padding: 0;
    }
  `,
)
const LabelText = styled.div(
  ({ theme }) => css`
    flex: 1 1 auto;
    padding: ${theme.spacing.x0}px 0;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0;
    }
  `,
)
const ArrowIcon = styled(ChevronRight)(
  ({ theme }) => css`
    height: ${theme.spacing.x4}px;
    transform: rotate(90deg);
    transition: transform 400ms ease-in-out 0s;
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: none;
    }
  `,
)
const TableWrapper = styled.div(
  ({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      max-height: 0;
      opacity: 0;
      transition: opacity 200ms ease-in-out, max-height 200ms ease-in-out;
    }
  `,
)
const Table = styled.div(
  ({ theme }) => css`
    ${Input}:checked + ${TableRow},
    ${TableRow}:focus-within {
      max-height: 500px;

      ${ArrowIcon} {
        transform: rotate(270deg);
      }
    }

    ${TableRow}:focus-within {
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: table;
      width: 100%;
    }
  `,
)
const Container = styled.div(
  ({ theme }) => css`
    max-width: ${theme.spacing.gridMaxWidth}px;
    margin: 0 auto;
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x10}px 0;
      ${Input}:checked + ${TableWrapper} {
        max-height: 9999px;
        opacity: 1;
      }
    }
  `,
)
const StyledLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.colors.neutral4};
    &:hover {
      text-decoration: none;
    }
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      color: ${theme.colors.primaryPurple};

      &:hover {
        text-decoration: underline;
      }
    }
  `,
)

interface Props {
  headers: {
    address?: string | null
    email?: string | null
    office?: string | null
    phone?: string | null
    service?: string | null
  }
  services: {
    name?: string | null
    offices: {
      id: string
      name?: string | null
      address?: string | null
      phone?: string | null
      email?: string | null
    }[]
  }[]
  text: ReactNode
  title?: string | null
  toggleText?: string | null
}

const ContactTableSection = ({
  headers,
  services,
  text,
  title,
  toggleText,
}: Props) => {
  const [activeAccordion, setActiveAccordion] = useState<string | undefined>()
  return (
    <Section>
      <Container>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <ToggleButton htmlFor="toggle-contact-table">{toggleText}</ToggleButton>
        <Input type="checkbox" id="toggle-contact-table" />
        <TableWrapper>
          <Table>
            <TableHeaders>
              <TableHeader>{headers.service}</TableHeader>
              <TableHeader>{headers.office}</TableHeader>
              <TableHeader>
                <Icon>
                  <Phone aria-label="telephone" />
                </Icon>
                {headers.phone}
              </TableHeader>
              <TableHeader>
                <Icon>
                  <Mail aria-label="email" />
                </Icon>
                {headers.email}
              </TableHeader>
              <TableHeader>
                <Icon>
                  <Location aria-label="address" />
                </Icon>
                {headers.address}
              </TableHeader>
            </TableHeaders>
            {services.map((service) =>
              service.offices.map(
                ({ id, name, address, phone, email }, index) => (
                  <Fragment key={id}>
                    <Input
                      type="radio"
                      id={id}
                      name="office"
                      value={id}
                      checked={activeAccordion === id}
                      onClick={() => {
                        if (activeAccordion === id)
                          setActiveAccordion(undefined)
                      }}
                      onChange={() => setActiveAccordion(id)}
                    />
                    <TableRow hasHeader={index === 0}>
                      <TableCell>
                        {index === 0 && <RowHeader>{service.name}</RowHeader>}
                      </TableCell>
                      <TableCell>
                        <Label htmlFor={id}>
                          <LabelText>{name}</LabelText> <ArrowIcon />
                        </Label>
                      </TableCell>
                      <TableCell>
                        <Icon>
                          <Phone aria-label="telephone" />
                        </Icon>
                        <StyledLink href={`tel:${phone}`}>{phone}</StyledLink>
                      </TableCell>
                      <TableCell>
                        <Icon>
                          <Mail aria-label="email" />
                        </Icon>
                        <StyledLink href={`mailto:${email}`}>
                          {email}
                        </StyledLink>
                      </TableCell>
                      <TableCell>
                        <Icon>
                          <Location aria-label="address" />
                        </Icon>
                        {address}
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ),
              ),
            )}
          </Table>
        </TableWrapper>
      </Container>
    </Section>
  )
}

export default ContactTableSection
