import { ComponentProps } from 'react'

import RichText from '../components/RichText'
import { resolveLinkWithText } from '../graphql/linkWithTextFragment.utils'
import { FrequentlyAskedQuestionsSectionFragment } from '../graphql/frequentlyAskedQuestionsSectionFragment'
import { usePageContext } from '../utils/PageContext'
import FrequentlyAskedQuestionsSection from '../sections/FrequentlyAskedQuestionsSection'
import ButtonLink from '../components/ButtonLink'

interface Props {
  content: FrequentlyAskedQuestionsSectionFragment
}

const FrequentlyAskedQuestionsSectionBlock = ({
  content: { anchor, backgroundColor, title, text, buttonLink, questions },
}: Props) => {
  const { siteConfig } = usePageContext()
  return (
    <FrequentlyAskedQuestionsSection
      backgroundColor={
        backgroundColor as ComponentProps<
          typeof FrequentlyAskedQuestionsSection
        >['backgroundColor']
      }
      id={anchor || undefined}
      link={
        buttonLink.length > 0
          ? {
              ...resolveLinkWithText(siteConfig, buttonLink[0]),
              text: buttonLink[0].link[0].text || '',
              variant: (buttonLink[0].variant || 'primary') as ComponentProps<
                typeof ButtonLink
              >['variant'],
            }
          : undefined
      }
      questions={questions.map(({ id, title, text }) => {
        return {
          key: id,
          title: title || '',
          text: <RichText text={text} />,
        }
      })}
      text={<RichText text={text} />}
      title={title}
    />
  )
}

export default FrequentlyAskedQuestionsSectionBlock
