import { css } from '@emotion/react'
import styled from '@emotion/styled'

import Button, { invisibleButtonCss } from './Button'

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spacing.x2}px;
  `,
)
const PageButton = styled(Button)(({ theme }) => [
  invisibleButtonCss,
  css`
    border: 1px solid ${theme.colors.primaryPurpleDark};
    border-radius: 50%;
    cursor: pointer;
    width: ${theme.spacing.x2}px;
    height: ${theme.spacing.x2}px;
    overflow: hidden;
    position: relative;

    ::before {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      background: ${theme.colors.primaryPurpleDark};
      border-radius: 50%;
      transform: translateX(
        calc(var(--index) * -100% + var(--slide-percentage) * 100%)
      );
    }
  `,
])

interface Props {
  pages: number
  slidePercentage: number
  onClickPage?: (page: number) => void
}

const CarouselPager = ({
  pages,
  slidePercentage,
  onClickPage,
  ...others
}: Props) => (
  <Container
    style={{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ['--slide-percentage' as any]: slidePercentage,
    }}
    {...others}
  >
    {[...Array(pages)].map((_, index) => (
      <PageButton
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        style={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ['--index' as any]: index,
        }}
        onClick={() => onClickPage?.(index)}
        role={onClickPage ? 'button' : undefined}
        data-testid={`carouselPager.page.${index}`}
        aria-label={`Go to slide ${index}`}
      >
        {' '}
      </PageButton>
    ))}
  </Container>
)

export default CarouselPager
