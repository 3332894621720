import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'
import Link from './Link'
import MenuItem from './MenuItem'

const MenuLink = styled(Link)(
  ({ theme }) => css`
    color: inherit;
    display: block;
    font-size: 16px;
    font-weight: bold;
    padding: ${theme.spacing.x2}px ${theme.spacing.x2}px;
    padding-right: ${theme.spacing.x4}px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      color: ${theme.colors.primaryPurple};
      text-decoration: none;
    }
  `,
)
const List = styled.ul(
  ({ theme }) => css`
    background: ${theme.colors.neutral0};
    height: calc(100vh - 66px);
    left: 100%;
    list-style: none;
    margin: 0;
    padding: ${theme.spacing.x3}px 0;
    position: absolute;
    top: 0;
    width: 100%;
  `,
)

interface Props {
  active: ComponentProps<typeof MenuItem>['active']
  depth: ComponentProps<typeof MenuItem>['depth']
  items: Array<ComponentProps<typeof MenuItem>['item']>
  onClick: ComponentProps<typeof MenuItem>['onClick']
  rootLink?: {
    href: string
    target?: string
    text: string
  }
}

const SubMenu = ({ active, items, depth, onClick, rootLink }: Props) => {
  return (
    <List>
      {depth === 1 && rootLink && (
        <li>
          <MenuLink href={rootLink.href} target={rootLink.target}>
            {rootLink.text}
          </MenuLink>
        </li>
      )}
      {items.map((item) => (
        <MenuItem
          active={active}
          key={item.id}
          depth={depth}
          item={item}
          onClick={onClick}
        />
      ))}
    </List>
  )
}

export default SubMenu
