import RichText from '../components/RichText'
import CardsSection from '../sections/CardsSection'
import convertDatoImage from '../utils/convertDatoImage'
import notEmpty from '../utils/notEmpty'
import reactStringReplace from '../utils/reactStringReplace'
import { CardsSectionFragment } from '../graphql/cardsSectionFragment'
import { usePageContext } from '../utils/PageContext'
import { resolveLinkWithText } from '../graphql/linkWithTextFragment.utils'

interface Props {
  content: CardsSectionFragment
}

const CardsSectionBlock = ({
  content: {
    alignment,
    coloredBackground,
    description,
    title,
    cards,
    columnsPerRow,
    carouselOnMobile,
    anchor,
  },
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()

  return (
    <CardsSection
      alignment={alignment || undefined}
      coloredBackground={coloredBackground}
      description={description && <RichText text={description} />}
      title={title}
      cards={cards
        .map((card) => {
          if (!card.title) {
            return undefined
          }
          return card.__typename === 'ContactCardRecord'
            ? {
                __typename: card.__typename,
                key: card.id,
                title: card.title,
                text: card.text && <RichText text={card.text} />,
                phone: card.phone || undefined,
                email: card.email || undefined,
                address: card.address
                  ? reactStringReplace(card.address, {
                      '\n': <br />,
                    })
                  : undefined,
                highlight: card.highlight,
                image: card.image ? convertDatoImage(card.image) : undefined,
              }
            : {
                __typename: card.__typename,
                key: card.id,
                title: card.title,
                text: card.text && <RichText text={card.text} />,
                highlight: card.highlight,
                link: {
                  ...resolveLinkWithText(siteConfig, card.link[0]),
                  text: card.link[0].text || undefined,
                  type: card.link[0].__typename,
                },
                countries: card.countries
                  .map(({ countryCode }) => countryCode)
                  .filter(notEmpty),
              }
        })
        .filter(notEmpty)}
      columnsPerRow={columnsPerRow}
      carouselOnMobile={carouselOnMobile || undefined}
      id={anchor || undefined}
      {...others}
    />
  )
}

export default CardsSectionBlock
