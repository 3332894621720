import RichText from '../components/RichText'
import { SosIntroductionSectionFragment } from '../graphql/sosIntroductionSectionFragment.generated'
import SosIntroductionSection from '../sections/SosIntroductionSection'
import convertDatoImage from '../utils/convertDatoImage'

interface Props {
  content: SosIntroductionSectionFragment
}

const SosIntroductionSectionBlock = ({
  content: {
    coloredBackground,
    mainColumnDesktop,
    mainColumnMobile,
    sideColumnDesktop,
    sideColumnMobile,
    sideColumnImage,
  },
}: Props) => {
  if (
    !mainColumnDesktop ||
    !sideColumnDesktop ||
    !mainColumnMobile ||
    !sideColumnMobile
  ) {
    return null
  }

  return (
    <SosIntroductionSection
      coloredBackground={coloredBackground}
      mainColumnDesktop={<RichText text={mainColumnDesktop} />}
      mainColumnMobile={<RichText text={mainColumnMobile} />}
      sideColumnDesktop={<RichText text={sideColumnDesktop} />}
      sideColumnMobile={<RichText text={sideColumnMobile} />}
      sideColumnImage={convertDatoImage(sideColumnImage)}
    />
  )
}

export default SosIntroductionSectionBlock
