import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

import { darkBackgroundVariantCss } from '../components/RichText'
import { Section as ArticleMetadataSection } from './ArticleMetadataSection'

export const Section = styled('section', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: 'light' | 'dark' }>(({ theme, variant }) => [
  css`
    background: ${theme.colors.neutral1};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x2}px;
    }
  `,
  variant === 'dark' &&
    css`
      background: ${theme.colors.primaryPurpleDark};
      ${darkBackgroundVariantCss(theme)}
    `,
])
const Container = styled.div(
  ({ theme }) => css`
    max-width: ${theme.spacing.gridMaxWidth}px;
    margin: 0 auto;
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x10}px 0;
    }

    ${ArticleMetadataSection} + ${Section} & {
      padding-top: ${theme.spacing.x1}px;
    }
  `,
)
const COLUMN_WIDTH = 72
const getContainerWidth = (theme: Theme, columns: number) =>
  columns * COLUMN_WIDTH + (columns - 1) * theme.spacing.x3
const getOffsetWidth = (theme: Theme, offset: number) =>
  offset * COLUMN_WIDTH + Math.max(0, offset - 1) * theme.spacing.x3
const Contents = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'columns' && prop !== 'offset' && prop !== 'textAlignment',
})<{
  columns: number
  offset: number
  textAlignment: 'left' | 'center' | 'right'
}>(({ theme, columns, offset, textAlignment }) => [
  css`
    width: 100%;
    max-width: ${getContainerWidth(theme, columns)}px;
    margin: 0 auto;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-left: ${getOffsetWidth(theme, offset)}px;
    }

    > p:first-child {
      margin-top: 0;
    }
    > p:last-child {
      margin-bottom: 0;
    }
  `,
  textAlignment === 'center' &&
    css`
      text-align: center;
    `,
  textAlignment === 'right' &&
    css`
      text-align: right;
    `,
])

interface Props extends Omit<ComponentProps<typeof Section>, 'variant'> {
  children: ReactNode
  columns: number
  offset: number
  textAlignment?: 'left' | 'center' | 'right'
  variant?: 'light' | 'dark'
}

const TextSection = ({
  children,
  columns,
  offset,
  textAlignment = 'left',
  variant = 'light',
  ...others
}: Props) => (
  <Section variant={variant} {...others}>
    <Container>
      <Contents columns={columns} offset={offset} textAlignment={textAlignment}>
        {children}
      </Contents>
    </Container>
  </Section>
)

export default TextSection
