import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Pagination from '../../components/Pagination'
import MultiSelect from '../../components/MultiSelect'
import { invisibleButtonCss } from '../../components/Button'
import CrossSvg from '../../icons/Cross.svg'
import Link from '../../components/Link'

const Section = styled.section(
  ({ theme }) => css`
    background: ${theme.colors.neutral1};
    padding: 0 ${theme.spacing.x2}px;
  `,
)
const Container = styled.div(
  ({ theme }) => css`
    margin: 0 auto;
    max-width: ${theme.spacing.gridMaxWidth}px;
    padding: ${theme.spacing.x5}px 0;
  `,
)
const StyledMultiSelect = styled(MultiSelect)(
  ({ theme }) => css`
    margin-right: ${theme.spacing.x1}px;
    margin-bottom: ${theme.spacing.x1}px;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      width: auto;
    }
  `,
)
const ActiveFilters = styled.div(
  ({ theme }) => css`
    margin: ${theme.spacing.x5}px 0;
    display: flex;
    gap: ${theme.spacing.x1}px;
    flex-flow: column;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-flow: row;
    }
  `,
)
const ActiveFiltersLabel = styled.div(
  ({ theme }) => css`
    flex: 0 0 auto;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding-top: 9px; // aligned with text of labels
    }
  `,
)
const ActiveFilter = styled.button(
  ({ theme }) => css`
    ${invisibleButtonCss()}
    padding: ${theme.spacing.x1 + theme.spacing.x0}px ${theme.spacing.x3}px;
    background: ${theme.colors.primaryPurpleExtraLight};
    border-radius: 24px;
    font-weight: bold;
    margin-right: ${theme.spacing.x1}px;
    margin-bottom: ${theme.spacing.x1}px;
    cursor: pointer;
    max-width: 100%;
    display: inline-flex;
    gap: ${theme.spacing.x1}px;
  `,
)
const ActiveFilterLabel = styled.div`
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const StyledCrossSvg = styled(CrossSvg)`
  flex: 0 0 auto;
  align-self: center;
  transition: transform 200ms ease-in-out;
  transform: rotate(0deg);

  ${ActiveFilter}:hover & {
    transform: rotate(90deg);
  }
`
const RemoveFiltersLink = styled(Link)(
  ({ theme }) => css`
    text-decoration: underline;
    color: inherit;
    display: block;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: inline-block;
      margin-left: ${theme.spacing.x3}px;
    }
  `,
)
const ResultsCount = styled.div(
  ({ theme }) => css`
    margin: ${theme.spacing.x4}px 0 ${theme.spacing.x5}px;
  `,
)
const ArticlesContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    gap: ${theme.spacing.x2}px;
    grid-template-columns: 1fr;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      gap: ${theme.spacing.x3}px;
      grid-template-columns: 1fr 1fr;
    }
  `,
)
const StyledPagination = styled(Pagination)(
  ({ theme }) => css`
    margin-top: ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-top: ${theme.spacing.x10}px;
    }
  `,
)
const NoResults = styled.div(
  ({ theme }) => css`
    max-width: ${theme.spacing.containerWidth}px;
    color: ${theme.colors.neutral4};
  `,
)
const NoResultsFoundTitle = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    margin-bottom: ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x3}px;
    }
  `,
)

export {
  Section,
  Container,
  StyledMultiSelect,
  ActiveFilter,
  ActiveFilters,
  ActiveFilterLabel,
  ActiveFiltersLabel,
  StyledCrossSvg,
  RemoveFiltersLink,
  ResultsCount,
  ArticlesContainer,
  StyledPagination,
  NoResults,
  NoResultsFoundTitle,
}
