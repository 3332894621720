import { SiteLocale } from '../graphql/datoSchema.generated'
import { getDefaultLocale, getHostname } from './env'

const host = getHostname()
const defaultLocale = getDefaultLocale()
const absolutify = (relativePath: string, locale: SiteLocale) => {
  const localePart = locale !== defaultLocale ? `/${locale}` : ''

  if (relativePath === '/') {
    // We have disabled trailing slashes, so to build consistent URLs we need to
    // omit it for the homepage as well
    return `${host}${localePart}`
  }

  return `${host}${localePart}${relativePath}`
}

export default absolutify
