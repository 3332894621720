import { useEffect, useState } from 'react'
import { getCookie } from './cookies'

const cookieName = 'Country'
// Example value: nl
const getUserCountry = () =>
  getCookie<string | undefined>(cookieName, undefined)

export const useUserCountry = () => {
  const [userCountry, setUserCountry] = useState<string | undefined>(undefined)

  // This is an unnecessary useEffect to workaround the hydration error that
  // would otherwise occur. It's an ugly solution from https://nextjs.org/docs/messages/react-hydration-error
  useEffect(() => {
    setUserCountry(getUserCountry)
  }, [])

  return userCountry
}
