import RichText from '../components/RichText'
import TextSection from '../sections/TextSection'
import { TextSectionFragment } from '../graphql/textSectionFragment'

interface Props {
  content: TextSectionFragment
}

const TextSectionBlock = ({
  content: {
    text,
    columnsWidth,
    columnsOffset,
    textAlignment,
    variant,
    anchor,
  },
  ...others
}: Props) => {
  if (
    !text ||
    !columnsWidth ||
    columnsOffset === null ||
    columnsOffset === undefined ||
    !textAlignment ||
    !variant
  ) {
    return null
  }

  return (
    <TextSection
      columns={columnsWidth}
      offset={columnsOffset}
      textAlignment={
        (textAlignment || undefined) as 'left' | 'right' | 'center' | undefined
      }
      variant={(variant || undefined) as 'light' | 'dark' | undefined}
      id={anchor || undefined}
      {...others}
    >
      <RichText text={text} />
    </TextSection>
  )
}

export default TextSectionBlock
