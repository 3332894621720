import { useRouter } from 'next/router'
import { ComponentProps, useEffect, useState } from 'react'
import { ParsedUrlQueryInput } from 'querystring'

import stringifyQueryString from '../../utils/stringifyQueryString'
import ExpertCard from '../../components/ExpertCard'
import reactStringReplace from '../../utils/reactStringReplace'
import parseNextQuery from '../../utils/parseNextQuery'
import stripEmpty from '../../utils/stripEmpty'
import {
  ActiveFilter,
  ActiveFilterLabel,
  ActiveFilters,
  ActiveFiltersLabel,
  ArticlesContainer,
  Container,
  NoResults,
  NoResultsFoundTitle,
  RemoveFiltersLink,
  ResultsCount,
  Section,
  StyledCrossSvg,
  StyledMultiSelect,
  StyledPagination,
} from './styles'

export const expertsPerPage = 6

interface Props {
  disciplines: string[]
  specialisms: string[]
  experts: Array<ComponentProps<typeof ExpertCard>>
  numTotalExperts: number
  currentPage: number
  resultsLabel: string
  previousPageLabel: string
  nextPageLabel: string
  goToPageLabel: string
  pathname: string
  disciplineLabel: string
  specialismLabel: string
  filteredOn: string
  removeFilters: string
  noResultsFound: string
  noResultsDescription: string
}

const ExpertListingSection = ({
  disciplines,
  specialisms,
  experts,
  numTotalExperts,
  currentPage,
  pathname,
  resultsLabel,
  previousPageLabel,
  nextPageLabel,
  goToPageLabel,
  disciplineLabel,
  specialismLabel,
  filteredOn,
  removeFilters,
  noResultsFound,
  noResultsDescription,
}: Props) => {
  const { query, replace } = useRouter()
  const replaceQuery = (newQuery: ParsedUrlQueryInput) => {
    replace(
      {
        pathname,
        query: {
          ...query,
          ...newQuery,
        },
      },
      undefined,
      {
        scroll: false,
      },
    )
  }

  const actualDisciplines = parseNextQuery(query.disciplines)
  const actualSpecialisms = parseNextQuery(query.specialisms)

  const [optimisticDisciplines, setSelectedDisciplines] =
    useState(actualDisciplines)
  const [optimisticSpecialisms, setSelectedSpecialisms] =
    useState(actualSpecialisms)
  // Keep disciplines/specialisms state in sync with URL (so back/forward navigation works)
  useEffect(() => {
    setSelectedDisciplines(parseNextQuery(query.disciplines))
  }, [query.disciplines])
  useEffect(() => {
    setSelectedSpecialisms(parseNextQuery(query.specialisms))
  }, [query.specialisms])

  const applyDisciplinesFilter = (disciplines: string[]) => {
    setSelectedDisciplines(disciplines)
    replaceQuery({ disciplines, specialisms: optimisticSpecialisms, page: 1 })
  }
  const toggleDiscipline = (item: string) => {
    const newSelectedDisciplines = optimisticDisciplines.includes(item)
      ? optimisticDisciplines.filter((discipline) => discipline !== item)
      : [...optimisticDisciplines, item]
    applyDisciplinesFilter(newSelectedDisciplines)
  }
  const applySpecialismsFilter = (specialisms: string[]) => {
    setSelectedSpecialisms(specialisms)
    replaceQuery({ disciplines: optimisticDisciplines, specialisms, page: 1 })
  }
  const toggleSpecialism = (item: string) => {
    const newSelectedSpecialisms = optimisticSpecialisms.includes(item)
      ? optimisticSpecialisms.filter((specialism) => specialism !== item)
      : [...optimisticSpecialisms, item]
    applySpecialismsFilter(newSelectedSpecialisms)
  }

  return (
    <Section>
      <Container>
        <StyledMultiSelect
          label={disciplineLabel}
          onSelect={applyDisciplinesFilter}
          active={optimisticDisciplines}
        >
          {disciplines.sort()}
        </StyledMultiSelect>
        <StyledMultiSelect
          label={specialismLabel}
          onSelect={applySpecialismsFilter}
          active={optimisticSpecialisms}
        >
          {specialisms.sort()}
        </StyledMultiSelect>

        {(actualDisciplines.length > 0 || actualSpecialisms.length > 0) && (
          <ActiveFilters>
            <ActiveFiltersLabel>{filteredOn}:</ActiveFiltersLabel>
            <div>
              {actualDisciplines.map((discipline) => (
                <ActiveFilter
                  key={discipline}
                  onClick={() => toggleDiscipline(discipline)}
                >
                  <ActiveFilterLabel>{discipline}</ActiveFilterLabel>{' '}
                  <StyledCrossSvg />
                </ActiveFilter>
              ))}
              {actualSpecialisms.map((specialism) => (
                <ActiveFilter
                  key={specialism}
                  onClick={() => toggleSpecialism(specialism)}
                >
                  <ActiveFilterLabel>{specialism}</ActiveFilterLabel>{' '}
                  <StyledCrossSvg />
                </ActiveFilter>
              ))}
              <RemoveFiltersLink
                href={pathname}
                scroll={false}
                onClick={() => {
                  // Optimistic update
                  setSelectedDisciplines([])
                  setSelectedSpecialisms([])
                }}
              >
                {removeFilters}
              </RemoveFiltersLink>
            </div>
          </ActiveFilters>
        )}

        <ResultsCount>
          {reactStringReplace(resultsLabel, {
            '{count}': <strong>{numTotalExperts}</strong>,
          })}
        </ResultsCount>
        {experts.length === 0 ? (
          <NoResults>
            <NoResultsFoundTitle>{noResultsFound}</NoResultsFoundTitle>
            <div>{noResultsDescription}</div>
          </NoResults>
        ) : (
          <>
            <ArticlesContainer>
              {experts.map((expert) => (
                <ExpertCard key={expert.href} {...expert} />
              ))}
            </ArticlesContainer>
            <StyledPagination
              numPages={Math.ceil(numTotalExperts / expertsPerPage)}
              currentPage={currentPage}
              makeUrl={(page) =>
                `${pathname}?${stringifyQueryString(
                  stripEmpty({
                    disciplines: actualDisciplines,
                    specialisms: actualSpecialisms,
                    page,
                  }),
                )}`
              }
              previousPageLabel={previousPageLabel}
              nextPageLabel={nextPageLabel}
              goToPageLabel={goToPageLabel}
            />
          </>
        )}
      </Container>
    </Section>
  )
}

export default ExpertListingSection
