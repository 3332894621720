import RichText from '../components/RichText'
import { SearchSectionFragment } from '../graphql/searchSectionFragment'
import { SearchResult } from '../pages/api/search'
import SearchSection from '../sections/SearchSection'

interface Props {
  content: SearchSectionFragment
  data: {
    results: SearchResult[]
    count: number
    query: string
  }
}

const SearchSectionBlock = ({
  content: {
    title,
    resultsCount,
    expertsTitle,
    viewAllExperts,
    allResultsTitle,
    newsLabel,
    pageLabel,
    notFoundTitle,
    notFoundDescription,
  },
  data: { results, count, query },
}: Props) => {
  if (
    !title ||
    !resultsCount ||
    !allResultsTitle ||
    !notFoundTitle ||
    !results ||
    count === undefined ||
    !newsLabel ||
    !pageLabel
  ) {
    return null
  }

  return (
    <SearchSection
      query={query}
      results={results}
      count={count}
      noResultsTitle={notFoundTitle}
      noResultsDescription={<RichText text={notFoundDescription} />}
      titleLabel={title}
      resultsCountLabel={resultsCount}
      expertsTitleLabel={expertsTitle || undefined}
      viewAllExpertsLabel={viewAllExperts || undefined}
      allResultsTitleLabel={allResultsTitle}
      newsLabel={newsLabel}
      pageLabel={pageLabel}
    />
  )
}

export default SearchSectionBlock
