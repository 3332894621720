import { SplitImageSectionFragment } from '../graphql/splitImageSectionFragment'
import { resolveLinkWithText } from '../graphql/linkWithTextFragment.utils'
import { usePageContext } from '../utils/PageContext'
import SplitImageSection from '../sections/SplitImageSection'
import RichText from '../components/RichText'
import convertDatoImage from '../utils/convertDatoImage'

interface Props {
  content: SplitImageSectionFragment
}

const SplitImageSectionBlock = ({
  content: {
    fullWidth,
    image,
    imageLocation,
    link,
    showVisualLine,
    text,
    title,
    variant,
    anchor,
  },
}: Props) => {
  const { siteConfig } = usePageContext()
  return (
    <SplitImageSection
      fullWidth={fullWidth}
      image={convertDatoImage(image)}
      imageLocation={imageLocation || undefined}
      showVisualLine={showVisualLine}
      link={
        link.length > 0
          ? {
              ...resolveLinkWithText(siteConfig, link[0]),
              text: link[0].text || undefined,
            }
          : undefined
      }
      text={<RichText text={text} />}
      title={title || undefined}
      variant={variant || undefined}
      id={anchor || undefined}
    />
  )
}

export default SplitImageSectionBlock
