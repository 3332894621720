import RichText from '../components/RichText'
import { resolveLinkWithText } from '../graphql/linkWithTextFragment.utils'
import TextWithImageSection from '../sections/TextWithImageSection'
import convertDatoImage from '../utils/convertDatoImage'
import { TextWithImageSectionFragment } from '../graphql/textWithImageSectionFragment'
import { usePageContext } from '../utils/PageContext'
import stringifyQueryString from '../utils/stringifyQueryString'
import stripUndefined from '../utils/stripUndefined'

interface Props {
  content: TextWithImageSectionFragment
}

const TextWithImageSectionBlock = ({
  content: {
    title,
    text,
    media,
    imageSize,
    imageLocation,
    callToAction,
    variant,
    anchor,
  },
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()

  if (!title) {
    return null
  }

  return (
    <TextWithImageSection
      title={title}
      text={text && <RichText text={text} />}
      image={media && !media.video ? convertDatoImage(media) : undefined}
      video={
        media?.video?.mp4Url
          ? {
              src: media.video.mp4Url,
              thumbnailSrc: `${media.video.thumbnailUrl}?${stringifyQueryString(
                stripUndefined({
                  time: !Number.isNaN(Number(media.customData.thumbnailTime))
                    ? media.customData.thumbnailTime
                    : undefined,
                }),
              )}`,
              width: media.width,
              height: media.height,
            }
          : undefined
      }
      imageSize={
        (imageSize || undefined) as 'landscape' | 'portrait' | undefined
      }
      mediaLocation={
        (imageLocation || undefined) as 'right' | 'left' | undefined
      }
      callToAction={
        callToAction[0]?.text
          ? {
              ...resolveLinkWithText(siteConfig, callToAction[0]),
              text: callToAction[0].text,
            }
          : undefined
      }
      variant={
        (variant || undefined) as 'header' | 'light' | 'dark' | undefined
      }
      id={anchor || undefined}
      {...others}
    />
  )
}

export default TextWithImageSectionBlock
