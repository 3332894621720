import { css } from '@emotion/react'
import styled from '@emotion/styled'

import Image from './Image'

const Container = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x2}px;
  `,
)
const Icon = styled(Image)(
  ({ theme }) => css`
    margin-right: ${theme.spacing.x1}px;
  `,
)
const Text = styled.div`
  display: inline-block;
  vertical-align: text-top;
`

interface Props {
  icon?: ImageInfo | null
  title?: string | null
  size: number
}

const IconWithText = ({ icon, size, title }: Props) => {
  if (!icon || !title) return null
  return (
    <Container>
      <Icon
        src={icon}
        alt={icon.alt || ''}
        priority
        width={size}
        height={size}
      />
      <Text>{title}</Text>
    </Container>
  )
}

export default IconWithText
