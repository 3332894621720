import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

import Image from '../components/Image'
import Link from '../components/Link'
import ArrowLeft from '../icons/ArrowLeft.svg'

const Section = styled.section(
  ({ theme }) => css`
    background: ${theme.colors.neutral1};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x2}px;
    }
  `,
)
const Container = styled.div(
  ({ theme }) => css`
    max-width: ${theme.spacing.gridMaxWidth}px;
    margin: 0 auto;
    padding: 0 ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0;
    }
  `,
)
const List = styled.ul(
  ({ theme }) => css`
    padding: 0;
    margin: 0;
    list-style: none;
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: row;
    gap: ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      grid-auto-flow: column;
      gap: ${theme.spacing.x3}px;
    }
  `,
)
const Item = styled.li(
  ({ theme }) => css`
    font-size: 16px;
    font-weight: 700;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 20px;
    }
  `,
)
const ItemLink = styled(Link)``
const ImageAspectRatio = styled.div(({ theme }) => [
  css`
    width: 100%;
    padding-top: ${(328 / 343) * 100}%;
    position: relative;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding-top: ${(428 / 360) * 100}%;
    }
  `,
])
const Label = styled.div(
  ({ theme }) =>
    css`
      ${theme.text.headings(theme)}
      background: ${theme.colors.neutral0};
      color: ${theme.colors.primaryPurpleDark};
      padding: ${theme.spacing.x2}px;
    `,
)
const ArrowRight = styled(ArrowLeft)(
  ({ theme }) => css`
    transform: rotate(180deg);
    margin-left: ${theme.spacing.x1}px;
    height: 21px;
    transition: transform 400ms ease-out;
    vertical-align: text-top;

    ${ItemLink}:hover & {
      transform: rotate(180deg) translateX(-4px);
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      height: 27px;
    }
  `,
)

interface Props extends Omit<ComponentProps<typeof Section>, 'title'> {
  title?: ReactNode
  links: Array<{
    key: string
    href: string
    target?: string
    text: string
    image: ImageInfo
  }>
}

const QuickImageLinksSection = ({ title, links, ...others }: Props) => (
  <Section {...others}>
    <Container>
      {title && <h2>{title}</h2>}
      <List>
        {links.map(({ key, href, target, text, image }) => (
          <Item key={key}>
            <ItemLink href={href} target={target}>
              <ImageAspectRatio>
                <Image
                  src={image}
                  alt=""
                  sizes="(min-width: 768px) 360px, 100vw"
                  fill
                  aspectRatio="360:428"
                />
              </ImageAspectRatio>
              <Label>
                {text}
                <ArrowRight />
              </Label>
            </ItemLink>
          </Item>
        ))}
      </List>
    </Container>
  </Section>
)

export default QuickImageLinksSection
