import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactElement, ReactNode } from 'react'

import Accordeon from '../components/Accordeon'
import ButtonLink from '../components/ButtonLink'

const Section = styled.section<{ backgroundColor: Props['backgroundColor'] }>(
  ({ backgroundColor, theme }) => css`
    background: ${backgroundColor === 'white'
      ? theme.colors.neutral0
      : theme.colors.neutral1};
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x10}px ${theme.spacing.x2}px;
    }
  `,
)
const Container = styled.div(
  ({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0 auto;
      max-width: ${theme.spacing.gridMaxWidth}px;
    }
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    margin-bottom: ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x5}px;
      max-width: ${theme.spacing.containerWidth}px;
    }
  `,
)
const Text = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x5}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      max-width: ${theme.spacing.containerWidth}px;
    }
  `,
)
const StyledButtonLink = styled(ButtonLink)(
  ({ theme }) => css`
    margin-top: ${theme.spacing.x5}px;
  `,
)
const StyledAccordeon = styled(Accordeon)(
  ({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      > div > div {
        max-width: ${theme.spacing.containerWidth}px;
      }
    }
  `,
)

interface Props {
  backgroundColor: 'white' | 'grey'
  id?: string
  link?: {
    href: string
    target?: string
    text: string
    variant: ComponentProps<typeof ButtonLink>['variant']
  }
  questions: {
    key: string
    title: string
    text: ReactElement
  }[]
  text: ReactNode
  title?: string | null
}

const FrequentlyAskedQuestionsSection = ({
  backgroundColor,
  id,
  link,
  questions,
  text,
  title,
}: Props) => {
  return (
    <Section id={id} backgroundColor={backgroundColor}>
      <Container>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <StyledAccordeon groups={questions} />
        {link && (
          <StyledButtonLink
            href={link.href}
            target={link.target}
            variant={link.variant}
          >
            {link.text}
          </StyledButtonLink>
        )}
      </Container>
    </Section>
  )
}

export default FrequentlyAskedQuestionsSection
