import { NewsFeedSectionFragment } from '../graphql/newFeedsSectionFragment'
import { resolveLinkWithText } from '../graphql/linkWithTextFragment.utils'
import NewsFeedSection from '../sections/NewsFeedSection'
import { BlockGetStaticPropsContext } from '.'
import notEmpty from '../utils/notEmpty'
import { BaseArticleFragment } from '../graphql/articleList.generated'
import { usePageContext } from '../utils/PageContext'

interface Props {
  content: NewsFeedSectionFragment
  data: {
    articles: BaseArticleFragment[]
  }
}

const NewsFeedSectionBlock = ({
  content: { title, viewAll, anchor },
  data,
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()
  if (!siteConfig.articleReadMore || !siteConfig.newsIndex?.slug) {
    return null
  }

  return (
    <NewsFeedSection
      title={title}
      viewAll={
        viewAll[0]?.text
          ? {
              ...resolveLinkWithText(siteConfig, viewAll[0]),
              text: viewAll[0].text,
            }
          : undefined
      }
      articles={data.articles
        .map(({ title, articleType, brief, slug }) => {
          if (
            !title ||
            !articleType ||
            !brief ||
            !articleType.title ||
            !articleType.color ||
            !slug
          ) {
            return undefined
          }

          return {
            type: articleType.title,
            typeColor: articleType.color,
            title,
            brief,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            href: `/${siteConfig.newsIndex!.slug}/${slug}`,
          }
        })
        .filter(notEmpty)}
      readMore={siteConfig.articleReadMore}
      id={anchor || undefined}
      {...others}
    />
  )
}

export default NewsFeedSectionBlock

/**
 * This function allows loading additional data during SSG so this block can be
 * statically generated as well (this avoids lazy loading data via AJAX).
 */
export const getStaticProps = async ({
  siteId,
  locale,
}: BlockGetStaticPropsContext<NewsFeedSectionFragment>) => ({
  articles: (
    await (
      await import('../graphql/articleList')
    ).getArticleList(siteId, locale, undefined, undefined, 3)
  ).articles,
})
