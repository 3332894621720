import { resolveLinkWithText } from '../graphql/linkWithTextFragment.utils'
import NavigationBar from '../sections/NavigationBar'
import convertDatoImage from '../utils/convertDatoImage'
import { NavigationBarFragment } from '../graphql/navigationBarFragment'
import { usePageContext } from '../utils/PageContext'
import RichText from '../components/RichText'

interface Props {
  content: NavigationBarFragment
}

const NavigationBarBlock = ({
  content: {
    links: datoLinks,
    hamburgerMenuButtonLinks,
    hamburgerMenuLinks,
    hamburgerMenuFooterLinks,
    quickHelpCallToAction,
    quickHelpModal,
  },
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()
  const links = datoLinks.map((link) => ({
    ...link,
    ...resolveLinkWithText(siteConfig, link),
    text: link.text || '',
  }))

  return (
    <NavigationBar
      buttonLinks={hamburgerMenuButtonLinks
        .filter((link) => link.link[0]?.text && link.variant)
        .map((link) => ({
          id: link.id,
          ...resolveLinkWithText(siteConfig, link.link[0]),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          text: link.link[0].text!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          variant: link.variant! as 'primary' | 'secondary',
        }))}
      iconLinks={hamburgerMenuLinks
        .filter((link) => link.link[0]?.text && link.icon)
        .map((link) => ({
          id: link.id,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          icon: convertDatoImage(link.icon!),
          ...resolveLinkWithText(siteConfig, link.link[0]),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          text: link.link[0].text!,
        }))}
      footerLinks={hamburgerMenuFooterLinks
        .filter((link) => link.text)
        .map((link) => ({
          id: link.id,
          ...resolveLinkWithText(siteConfig, link),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          text: link.text!,
        }))}
      locales={siteConfig.locales}
      links={links}
      quickHelpButton={
        quickHelpCallToAction.length > 0
          ? quickHelpCallToAction[0].title
          : undefined
      }
      quickHelpModal={
        quickHelpModal.length > 0
          ? {
              text: <RichText text={quickHelpModal[0].text} />,
              title: quickHelpModal[0].title,
            }
          : undefined
      }
      {...others}
    />
  )
}

export default NavigationBarBlock
