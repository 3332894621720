import ContactTableSection from '../sections/ContactTableSection'
import { ContactTableSectionFragment } from '../graphql/contactTableSectionFragment'
import RichText from '../components/RichText'

interface Props {
  content: ContactTableSectionFragment
}

const ContactTableSectionBlock = ({
  content: {
    address,
    email,
    office,
    phone,
    service,
    services,
    title,
    text,
    toggleText,
  },
}: Props) => (
  <ContactTableSection
    headers={{
      address,
      email,
      office,
      phone,
      service,
    }}
    services={services}
    text={<RichText text={text} />}
    title={title}
    toggleText={toggleText}
  />
)

export default ContactTableSectionBlock
