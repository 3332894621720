import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

import ContactCard from '../components/ContactCard'
import LinkCard from '../components/LinkCard'
import { useUserCountry } from '../utils/userCountry'

const Section = styled.section<{ coloredBackground?: boolean }>(
  ({ coloredBackground, theme }) => css`
    background: ${coloredBackground
      ? `linear-gradient(180deg,${theme.colors.neutral0} 50%, ${theme.colors.neutral1} 50%)`
      : theme.colors.neutral1};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x2}px;
    }
  `,
)
const Container = styled.div(
  ({ theme }) => css`
    max-width: ${theme.spacing.gridMaxWidth}px;
    margin: 0 auto;
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x10}px 0;
    }

    ${Section} + ${Section} & {
      padding-top: 0;
    }
  `,
)
const Title = styled('h2', {
  shouldForwardProp: (prop) => prop !== 'alignment',
})<{ alignment?: string }>(({ theme, alignment }) => [
  css`
    ${theme.text.heading2(theme)}
    margin-bottom: ${theme.spacing.x2 + theme.spacing.x0}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      max-width: 552px;
      text-align: ${alignment};
    }
  `,
  alignment === 'center' &&
    css`
      margin: 0 auto;
    `,
])
const Description = styled('div', {
  shouldForwardProp: (prop) => prop !== 'alignment',
})<{ alignment?: string }>(({ theme, alignment }) => [
  css`
    font-size: 16px;
    line-height: 1.5;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
      max-width: 552px;
      margin-bottom: ${theme.spacing.x5}px;
      text-align: ${alignment};
    }
  `,
  alignment === 'center' &&
    css`
      margin: 0 auto ${theme.spacing.x5}px;
    `,
])
const List = styled.ul<{ carouselOnMobile?: boolean; columnsPerRow: number }>(
  ({ theme, carouselOnMobile, columnsPerRow }) => [
    css`
      padding: 0;
      margin: 0;
      list-style: none;
      display: grid;
      gap: ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        gap: ${theme.spacing.x3}px;
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(
          ${columnsPerRow === 4 ? 2 : columnsPerRow},
          1fr
        );
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        grid-template-columns: repeat(${columnsPerRow}, 1fr);
      }
    `,
    carouselOnMobile &&
      css`
        @media screen and (max-width: ${theme.breakpoints.mobileMax}px) {
          grid-template-columns: 300px;
          grid-auto-flow: column;
          scroll-snap-type: x mandatory;
          scroll-behavior: smooth;
          overflow-x: auto;
          margin: 0 -${theme.spacing.x2}px;
          scroll-padding-left: ${theme.spacing.x2}px;
          padding: 0 ${theme.spacing.x2}px;

          > li {
            width: 300px;
            scroll-snap-align: start;
          }
        }
      `,
  ],
)

const Item = styled.li<{ hasLinkCard: boolean; highlight?: boolean }>(
  ({ hasLinkCard, highlight, theme }) => [
    css`
      background: ${theme.colors.neutral0};
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
      padding: ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.mobileMax}px) {
        padding: ${theme.spacing.x3}px;
      }
    `,
    hasLinkCard &&
      css`
        :hover {
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
        }
      `,
    highlight &&
      css`
        background: ${theme.colors.primaryPurpleDark};
        color: ${theme.colors.neutral0};
      `,
  ],
)

interface Props extends Omit<ComponentProps<typeof Section>, 'title'> {
  alignment?: string
  title?: ReactNode
  description?: ReactNode
  cards: Array<
    {
      key: string
      highlight?: boolean
    } & (
      | {
          __typename: 'ContactCardRecord'
          title: string
          text?: ReactNode
          phone?: string
          email?: string
          address?: ReactNode
          image?: ImageInfo
        }
      | {
          __typename: 'LinkCardRecord'
          title: string
          text?: ReactNode
          link: {
            text?: string
            type: 'LinkWithTextExternalRecord' | 'LinkWithTextInternalRecord'
            href: string
            target?: string
          }
          countries: string[]
        }
    )
  >
  columnsPerRow: number
  carouselOnMobile: boolean
}

const CardsSection = ({
  alignment,
  coloredBackground,
  description,
  title,
  cards,
  columnsPerRow,
  carouselOnMobile,
  ...others
}: Props) => {
  const userCountry = useUserCountry()

  return (
    <Section coloredBackground={coloredBackground} {...others}>
      <Container>
        {title && <Title alignment={alignment}>{title}</Title>}
        {description && (
          <Description alignment={alignment}>{description}</Description>
        )}
        <List carouselOnMobile={carouselOnMobile} columnsPerRow={columnsPerRow}>
          {cards
            // Apply LinkCards country filter
            .filter((card) => {
              if (card.__typename !== 'LinkCardRecord') {
                return true
              }

              if (
                card.countries.length > 0 &&
                (!userCountry || !card.countries.includes(userCountry))
              ) {
                return false
              }

              return true
            })
            .map((card) => (
              <Item
                key={card.key}
                highlight={card.highlight}
                hasLinkCard={card.__typename === 'LinkCardRecord'}
              >
                {card.__typename === 'ContactCardRecord' && (
                  <ContactCard
                    address={card.address}
                    email={card.email}
                    highlight={card.highlight}
                    image={card.image}
                    phone={card.phone}
                    text={card.text}
                    title={card.title}
                  />
                )}
                {card.__typename === 'LinkCardRecord' && (
                  <LinkCard
                    link={card.link}
                    highlight={card.highlight}
                    text={card.text}
                    title={card.title}
                  />
                )}
              </Item>
            ))}
        </List>
      </Container>
    </Section>
  )
}

export default CardsSection
