import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'

import ArrowLeft from '../icons/ArrowLeft.svg'
import { usePageContext } from '../utils/PageContext'
import Link from './Link'

const MobileGoBackLink = styled(Link)(
  ({ theme }) => css`
    color: inherit;
    font-size: 12px;
    font-weight: bold;
    display: inline-block;
    margin-bottom: ${theme.spacing.x3}px;

    svg {
      width: 24px;
      height: 24px;
      margin-right: ${theme.spacing.x1}px;
      vertical-align: middle;
      margin-top: -3px;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: none;
    }
  `,
)
const DesktopBreadcrumbs = styled.ul(
  ({ theme }) => css`
    margin: 0 0 ${theme.spacing.x3}px;
    padding: 0;
    font-size: 12px;

    display: none;
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
    }
  `,
)
const chevronRightEncoded = `"data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_571_33153)'%3E%3Cpath d='M9.99997 6L8.58997 7.41L13.17 12L8.58997 16.59L9.99997 18L16 12L9.99997 6Z' fill='currentColor' /%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_571_33153'%3E%3Crect width='24' height='24' fill='white' /%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E"`
const breadcrumbLinkCss = (theme: Theme) => {
  // currentColor does not work when the svg is set as content, so we need to
  // manually overwrite it.
  const coloredChevronRight = chevronRightEncoded.replace(
    'currentColor',
    encodeURIComponent(theme.colors.neutral4),
  )

  return css`
    max-width: 200px;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;

    :last-child {
      font-weight: bold;
      color: ${theme.colors.primaryPurple};
    }
    :not(:last-child) {
      color: inherit;

      ::after {
        content: url(${coloredChevronRight});
        display: inline-block;
        width: 18px;
        height: 18px;
        vertical-align: bottom;
        margin: 0 ${theme.spacing.x0}px;
      }
    }
  `
}
const BreadcrumbLink = styled(Link)(({ theme }) => breadcrumbLinkCss(theme))
const BreadcrumbText = styled.div(({ theme }) => breadcrumbLinkCss(theme))

const Breadcrumbs = () => {
  const { breadcrumbs, siteConfig } = usePageContext()

  if (!breadcrumbs || breadcrumbs.length === 1) {
    return null
  }

  return (
    <>
      <DesktopBreadcrumbs
        // Important not to index this since it includes the parent page's
        // name, which may cause irrelevant results
        data-datocms-noindex
      >
        {breadcrumbs.map(({ key, href, title }, index) => {
          if (index === breadcrumbs.length - 1) {
            return <BreadcrumbText key={key}>{title}</BreadcrumbText>
          }

          return (
            <BreadcrumbLink key={key} href={href}>
              {title}
            </BreadcrumbLink>
          )
        })}
      </DesktopBreadcrumbs>
      <MobileGoBackLink
        href={breadcrumbs[breadcrumbs.length - 2]?.href || '/'}
        data-datocms-noindex
      >
        <ArrowLeft />
        {siteConfig.goBack} {breadcrumbs[breadcrumbs.length - 2]?.title}
      </MobileGoBackLink>
    </>
  )
}

export default Breadcrumbs
