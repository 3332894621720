import { useRouter } from 'next/router'
import { ComponentProps, useEffect, useState } from 'react'
import { ParsedUrlQueryInput } from 'querystring'

import stringifyQueryString from '../../utils/stringifyQueryString'
import ExpertCard from '../../components/ExpertCard'
import reactStringReplace from '../../utils/reactStringReplace'
import parseNextQuery from '../../utils/parseNextQuery'
import stripEmpty from '../../utils/stripEmpty'
import {
  ActiveFilter,
  ActiveFilterLabel,
  ActiveFilters,
  ActiveFiltersLabel,
  ArticlesContainer,
  Container,
  NoResults,
  NoResultsFoundTitle,
  RemoveFiltersLink,
  ResultsCount,
  Section,
  StyledCrossSvg,
  StyledMultiSelect,
  StyledPagination,
} from './styles'

export const expertsPerPage = 6

interface Props {
  expertises: string[]
  nations: string[]
  experts: Array<ComponentProps<typeof ExpertCard>>
  numTotalExperts: number
  currentPage: number
  resultsLabel: string
  previousPageLabel: string
  nextPageLabel: string
  goToPageLabel: string
  pathname: string
  countryLabel: string
  expertiseLabel: string
  filteredOn: string
  removeFilters: string
  noResultsFound: string
  noResultsDescription: string
}

const ExpertListingSection = ({
  expertises,
  nations,
  experts,
  numTotalExperts,
  currentPage,
  pathname,
  resultsLabel,
  previousPageLabel,
  nextPageLabel,
  goToPageLabel,
  countryLabel,
  expertiseLabel,
  filteredOn,
  removeFilters,
  noResultsFound,
  noResultsDescription,
}: Props) => {
  const { query, replace } = useRouter()
  const replaceQuery = (newQuery: ParsedUrlQueryInput) => {
    replace(
      {
        pathname,
        query: {
          ...query,
          ...newQuery,
        },
      },
      undefined,
      {
        scroll: false,
      },
    )
  }

  // The Model is nations but in the URL we want to show countries
  const actualExpertise = parseNextQuery(query.expertises)
  const actualNations = parseNextQuery(query.countries)

  const [optimisticExpertise, setSelectedExpertise] = useState(actualExpertise)
  const [optimisticNations, setSelectedNations] = useState(actualNations)
  // Keep expertises/nations state in sync with URL (so back/forward navigation works)
  useEffect(() => {
    setSelectedExpertise(parseNextQuery(query.expertises))
  }, [query.expertises])
  useEffect(() => {
    setSelectedNations(parseNextQuery(query.countries))
  }, [query.countries])

  const applyExpertiseFilter = (expertises: string[]) => {
    setSelectedExpertise(expertises)
    replaceQuery({ expertises, nations: optimisticNations, page: 1 })
  }
  const toggleExpertise = (item: string) => {
    const newSelectedExpertise = optimisticExpertise.includes(item)
      ? optimisticExpertise.filter((expertise) => expertise !== item)
      : [...optimisticExpertise, item]
    applyExpertiseFilter(newSelectedExpertise)
  }
  const applyNationsFilter = (nations: string[]) => {
    setSelectedNations(nations)
    replaceQuery({
      expertises: optimisticExpertise,
      countries: nations,
      page: 1,
    })
  }
  const toggleNation = (item: string) => {
    const newSelectedNations = optimisticNations.includes(item)
      ? optimisticNations.filter((nation) => nation !== item)
      : [...optimisticNations, item]
    applyNationsFilter(newSelectedNations)
  }

  return (
    <Section>
      <Container>
        <StyledMultiSelect
          label={expertiseLabel}
          onSelect={applyExpertiseFilter}
          active={optimisticExpertise}
        >
          {expertises.sort()}
        </StyledMultiSelect>
        <StyledMultiSelect
          label={countryLabel}
          onSelect={applyNationsFilter}
          active={optimisticNations}
        >
          {nations.sort()}
        </StyledMultiSelect>

        {(actualExpertise.length > 0 || actualNations.length > 0) && (
          <ActiveFilters>
            <ActiveFiltersLabel>{filteredOn}:</ActiveFiltersLabel>
            <div>
              {actualExpertise.map((expertise) => (
                <ActiveFilter
                  key={expertise}
                  onClick={() => toggleExpertise(expertise)}
                >
                  <ActiveFilterLabel>{expertise}</ActiveFilterLabel>{' '}
                  <StyledCrossSvg />
                </ActiveFilter>
              ))}
              {actualNations.map((nation) => (
                <ActiveFilter key={nation} onClick={() => toggleNation(nation)}>
                  <ActiveFilterLabel>{nation}</ActiveFilterLabel>{' '}
                  <StyledCrossSvg />
                </ActiveFilter>
              ))}
              <RemoveFiltersLink
                href={pathname}
                scroll={false}
                onClick={() => {
                  // Optimistic update
                  setSelectedExpertise([])
                  setSelectedNations([])
                }}
              >
                {removeFilters}
              </RemoveFiltersLink>
            </div>
          </ActiveFilters>
        )}

        <ResultsCount>
          {reactStringReplace(resultsLabel, {
            '{count}': <strong>{numTotalExperts}</strong>,
          })}
        </ResultsCount>
        {experts.length === 0 ? (
          <NoResults>
            <NoResultsFoundTitle>{noResultsFound}</NoResultsFoundTitle>
            <div>{noResultsDescription}</div>
          </NoResults>
        ) : (
          <>
            <ArticlesContainer>
              {experts.map((expert) => (
                <ExpertCard key={expert.href} {...expert} />
              ))}
            </ArticlesContainer>
            <StyledPagination
              numPages={Math.ceil(numTotalExperts / expertsPerPage)}
              currentPage={currentPage}
              makeUrl={(page) =>
                `${pathname}?${stringifyQueryString(
                  stripEmpty({
                    expertises: actualExpertise,
                    countries: actualNations,
                    page,
                  }),
                )}`
              }
              previousPageLabel={previousPageLabel}
              nextPageLabel={nextPageLabel}
              goToPageLabel={goToPageLabel}
            />
          </>
        )}
      </Container>
    </Section>
  )
}

export default ExpertListingSection
