import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode, useState } from 'react'

import { primaryButtonCss } from '../components/Button'
import Link from '../components/Link'
import ChevronRight from '../icons/ChevronRight.svg'
import ArrowLeft from '../icons/ArrowLeft.svg'
import ButtonLink from '../components/ButtonLink'
import Image from '../components/Image'

const Section = styled.section(
  ({ theme }) => css`
    background: ${theme.colors.neutral1};
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x10}px ${theme.spacing.x2}px;
    }
  `,
)
const Container = styled.div(
  ({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      background: ${theme.colors.neutral0};
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
      display: flex;
      margin: 0 auto;
      max-width: ${theme.spacing.gridMaxWidth}px;
      min-height: 462px;
      position: relative;
    }
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    margin-bottom: ${theme.spacing.x2}px;
  `,
)
const Content = styled.div(
  ({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex: 0 0 376px;
      padding: ${theme.spacing.x3}px;
    }
  `,
)
const DesktopImageContainer = styled.div(
  ({ theme }) => css`
    display: none;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
      flex: 1;
      position: relative;
    }
  `,
)
const Text = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x1}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x2}px;
    }
  `,
)
const ViewAll = styled(Link)(
  ({ theme }) =>
    css`
      ${primaryButtonCss(theme)}
      margin-top: ${theme.spacing.x5}px;
    `,
)
const StyledButtonLink = styled(ButtonLink)(
  ({ theme }) => css`
    display: flex;
    margin-bottom: ${theme.spacing.x1}px;
    padding: ${theme.spacing.x1}px 0;
    font-weight: normal;

    :hover,
    :focus {
      color: ${theme.colors.primaryPurple};
      text-decoration: none;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      border-bottom: 1px solid ${theme.colors.neutral2};
      margin-bottom: 0;
      padding: ${theme.spacing.x3}px 0;
    }
  `,
)
const LinkText = styled.div`
  flex: 1;
`
const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
const Input = styled.input`
  display: none;
`

const ArrowRight = styled(ArrowLeft)(
  ({ theme }) => css`
    display: none;
    transform: rotate(180deg);
    margin-left: ${theme.spacing.x1}px;
    height: 24px;
    margin-bottom: -3px;
    transition: transform 400ms ease-out;

    ${StyledButtonLink}:hover, ${StyledButtonLink}:focus-within & {
      transform: rotate(180deg) translateX(-4px);
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: inline;
    }
  `,
)
const Label = styled.label(
  ({ theme }) => css`
    ${theme.text.heading4(theme)}
    align-items: center;
    border-bottom: 1px solid ${theme.colors.neutral2};
    color: ${theme.colors.neutral4};
    cursor: pointer;
    display: flex;
    overflow: hidden;
    padding: ${theme.spacing.x1}px 0;
    position: relative;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      align-items: flex-start;
      font-family: ${theme.fonts.inter.style.fontFamily};
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      padding: ${theme.spacing.x3}px 0;
    }
  `,
)
const LabelText = styled.div`
  flex: 1;
`
const ArrowIcon = styled(ChevronRight)(
  ({ theme }) => css`
    height: ${theme.spacing.x4}px;
    transform: rotate(90deg);
    transition: transform 400ms ease-in-out 0s;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: none;
    }
  `,
)
const Menu = styled.div(
  ({ theme }) => css`
    max-height: 0;
    overflow: hidden;
    transition: all 400ms ease-in-out;

    :focus-within {
      max-height: 204px;
    }

    ${ViewAll} {
      display: none;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      background: ${theme.colors.neutral0};
      border-left: 1px solid ${theme.colors.neutral2};
      bottom: 0;
      display: flex;
      left: 376px;
      max-height: none;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: ${theme.zIndex.relative1};

      :focus-within {
        max-height: none;
      }

      ${ViewAll} {
        display: inline-block;
      }
    }
  `,
)
const ListItem = styled.li(
  ({ theme }) => css`
    ${Label} ~ ${Input}:checked {
      ${ArrowIcon} {
        transform: rotate(270deg);
      }
    }
    ${Input}:checked ~ {
      ${Menu} {
        max-height: 204px;
      }
      ${Label} {
        ${ArrowIcon} {
          transform: rotate(270deg);
        }
      }
    }
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0 -${theme.spacing.x5}px;
      padding: 0 ${theme.spacing.x5}px;

      ${Input}:checked ~ {
        ${Menu} {
          max-height: none;
        }
      }
      :hover,
      :focus-within {
        ${Menu} {
          opacity: 1;
          pointer-events: all;
        }
        ${Label} {
          color: ${theme.colors.primaryPurple};
        }
      }
    }
  `,
)
const MenuList = styled.ul(
  ({ theme }) => css`
    list-style: none;
    margin: 0;
    padding: ${theme.spacing.x2}px 0;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex: 1 1 50%;
      padding: ${theme.spacing.x3}px;
    }
  `,
)
const MenuListItem = styled.li``
const MenuImageContainer = styled.div(
  ({ theme }) => css`
    display: none;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex: 1 1 50%;
      display: block;
      position: relative;
    }
  `,
)

type Link = {
  id: string
  href: string
  target?: string
  text?: string | null
}

type Menu = {
  id: string
  title?: string | null
  image?: ImageInfo
  links: Link[]
}

interface Props {
  image?: ImageInfo
  links: Array<Link | Menu>
  title?: string | null
  text: ReactNode
  viewAll?: Link
  viewAllDisplayPosition: 'primary' | 'secondary' | 'both'
}

const HighlightedServicesSection = ({
  image,
  links,
  text,
  title,
  viewAll,
  viewAllDisplayPosition,
}: Props) => {
  const [activeAccordion, setActiveAccordion] = useState<string | undefined>()
  if (!image) return null
  return (
    <Section>
      <Container>
        <Content>
          <Title>{title}</Title>
          <Text>{text}</Text>
          <List>
            {links.map((item) => {
              if ('href' in item)
                return (
                  <ListItem key={item.id}>
                    <StyledButtonLink
                      href={item.href}
                      target={item.target}
                      variant="textual"
                    >
                      <LinkText>{item.text}</LinkText>
                    </StyledButtonLink>
                  </ListItem>
                )
              return (
                <ListItem key={item.id}>
                  <Input
                    type="radio"
                    id={item.id}
                    name="services"
                    value={item.id}
                    checked={activeAccordion === item.id}
                    onClick={() => {
                      if (activeAccordion === item.id)
                        setActiveAccordion(undefined)
                    }}
                    onChange={() => setActiveAccordion(item.id)}
                  />
                  <Label htmlFor={item.id}>
                    <LabelText>{item.title}</LabelText>
                    <ArrowIcon />
                    <ArrowRight />
                  </Label>
                  <Menu>
                    <MenuList>
                      {item.links.map((link) => (
                        <MenuListItem key={link.id}>
                          <StyledButtonLink
                            href={link.href}
                            target={link.target}
                            variant="textual"
                          >
                            <LinkText>{link.text}</LinkText>
                          </StyledButtonLink>
                        </MenuListItem>
                      ))}
                      {viewAll && viewAllDisplayPosition !== 'primary' && (
                        <MenuListItem>
                          <ViewAll href={viewAll.href} target={viewAll.target}>
                            {viewAll.text} <ChevronRight />
                          </ViewAll>
                        </MenuListItem>
                      )}
                    </MenuList>
                    {item.image && (
                      <MenuImageContainer>
                        <Image
                          alt={item.image.alt || ''}
                          fill
                          sizes="375px"
                          src={item.image}
                        />
                      </MenuImageContainer>
                    )}
                  </Menu>
                </ListItem>
              )
            })}
          </List>
          {viewAll && viewAllDisplayPosition !== 'secondary' && (
            <ViewAll href={viewAll.href} target={viewAll.target}>
              {viewAll.text} <ChevronRight />
            </ViewAll>
          )}
        </Content>
        <DesktopImageContainer>
          <Image alt={image.alt || ''} fill sizes="752px" src={image} />
        </DesktopImageContainer>
      </Container>
    </Section>
  )
}

export default HighlightedServicesSection
