import { usePageContext } from '../utils/PageContext'

type mode = 'join' | 'and'
interface Props {
  children: string[]
  oxfordComma?: boolean
  mode?: mode
}

const CommaSeparatedList = ({
  children,
  oxfordComma = true,
  mode = 'join',
}: Props) => {
  const { siteConfig } = usePageContext()

  if (children.length === 0) {
    return null
  }
  if (children.length === 1) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children[0]}</>
  }

  if (mode === 'and') {
    const lastPart = children[children.length - 1]
    const baseParts = children.slice(0, children.length - 1)

    return (
      <>
        {baseParts.join(', ')}
        {oxfordComma && ','} {siteConfig.and} {lastPart}
      </>
    )
  }

  return <>{children.join(', ')}</>
}

export default CommaSeparatedList
